// Made by Poukam Pierre

import { Box } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import theme from '../../utils/style/theme'

function ExamTimer({ isSubmited }: { isSubmited: boolean }) {
  const intervalId = useRef<string | number | NodeJS.Timeout | undefined>()
  const [timer, setTimer] = useState<string>('00:00:00')
  let startTime = 0
  let elapsedTime = 0

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    startTime = Date.now() - elapsedTime
    intervalId.current = setInterval(updateTime, 100)
  }, [])

  const updateTime = () => {
    elapsedTime = Date.now() - startTime
    let secs: number | string = Math.floor((elapsedTime / 1000) % 60)
    let mins: number | string = Math.floor((elapsedTime / (1000 * 60)) % 60)
    let hrs: number | string = Math.floor((elapsedTime / (1000 * 60 * 60)) % 60)

    secs = pad(secs)
    mins = pad(mins)
    hrs = pad(hrs)

    setTimer(`${hrs}:${mins}:${secs}`)

    function pad(unit: number) {
      return ('0' + unit).length > 2 ? unit : '0' + unit
    }
  }
  if (isSubmited) clearInterval(intervalId.current)
  return (
    <Box
      sx={{
        fontFamily: 'consaloas, monospace',
        color: theme.palette.primary.main,
        paddingLeft: 1,
      }}
    >
      {timer}
    </Box>
  )
}

export default ExamTimer
