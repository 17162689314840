// Made by Poukam Ngamaleu

import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined'
import LoginIcon from '@mui/icons-material/Login'
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { Link } from 'react-router-dom'
import logo from '../../asset/logo_monochrone.png'

function Draws({
  isDrawerOpen,
  setIsDrawerOpen,
}: {
  isDrawerOpen: boolean
  setIsDrawerOpen: (bool: boolean) => void
}) {
  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
    >
      <Box
        sx={{ display: { sx: 'flex', md: 'none' } }}
        p={2}
        width="250px"
        role="presentation"
      >
        <Box display="flex" justifyContent="start" paddingBottom="5px">
          <Box
            component="img"
            sx={{ maxWidth: 100 }}
            src={logo}
            alt="OnlinePreps"
          />
        </Box>

        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              to="/SignIn"
              component={Link}
              onClick={() => setIsDrawerOpen(false)}
            >
              <ListItemIcon>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary="Connecter" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              to="/SignUp"
              component={Link}
              onClick={() => setIsDrawerOpen(false)}
            >
              <ListItemIcon>
                <AppRegistrationOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Inscrire" />
            </ListItemButton>
          </ListItem>
        </List>
        <Box marginTop="50px" paddingLeft="20px">
          <Typography variant="overline"> By Poukam Tech </Typography>
        </Box>
      </Box>
    </Drawer>
  )
}

export default Draws
