// Made by Poukma Ngamaleu
import { Box, Button, Paper, styled, Typography } from '@mui/material'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import LinkedBtn from '../../components/linkedButton'
import PartnerInf from '../../components/user/partnerInformations'
import StudentInf from '../../components/user/personalInformations'
import { theme } from '../../utils/style/theme'

export const StyledLink = styled(Link)({
  color: theme.palette.primary.main,
  textDecoration: 'none',
})

function SignUp() {
  const [active, setActive] = useState<boolean>(true)

  return (
    <Box
      sx={{
        height: { xs: 'initial', sm: '95vh' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        sx={{
          width: { xs: '100%', sm: 'initial' },
          borderRadius: { xs: '0', sm: '40px' },
          backgroundColor: { xs: 'initial', sm: '#D7CBEC' },
          elevation: { xs: 0, sm: 3 },
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          gap="5px"
          sx={{ padding: { xs: '8px', sm: 2 } }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
          >
            ENREGISTREZ VOUS MAINTENANT
          </Typography>
          <Typography sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
            Avez vous déjà un compte?{' '}
            <StyledLink to="/SignIn">Connexion</StyledLink>
          </Typography>
          <Box sx={{ display: 'flex', gap: '15%' }}>
            <Button
              onClick={() => setActive(true)}
              variant="outlined"
              sx={{ borderColor: active ? theme.palette.secondary.main : null }}
            >
              <Typography
                variant="button"
                sx={{ color: active ? theme.palette.primary.main : 'black' }}
              >
                APPRENANT
              </Typography>
            </Button>
            <Button
              onClick={() => setActive(false)}
              variant="outlined"
              sx={{
                borderColor: !active ? theme.palette.secondary.main : null,
              }}
            >
              <Typography
                variant="button"
                sx={{ color: !active ? theme.palette.primary.main : 'black' }}
              >
                PARTENAIRE
              </Typography>
            </Button>
          </Box>
          {active ? <StudentInf /> : <PartnerInf />}
          <LinkedBtn />
        </Box>
      </Paper>
    </Box>
  )
}

export default SignUp
