// Made by Poukam Ngamaleu

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fade,
  FormControl,
  Modal,
  RadioGroup,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { Proposition, question } from '../../pages/testingPaper'
import PropositionComp from './propositionComp'
import { Response } from '../../pages/examHistory/historyExam'

function QuestionExam({
  questions: {
    questionBody,
    questionImg,
    propositionAnswers,
    feedback,
    questionId,
  },
  questions,
  index,
  updateAnswers,
  isSubmited,
  answers,
  correction,
}: {
  questions: question
  index: number
  updateAnswers: (question: question) => void
  isSubmited: boolean
  answers?: Response[]
  correction?: boolean
}) {
  const [selectedAnswer, setSelectedAnswer] = useState<Proposition>()
  const [open, setOpen] = useState(false)
  const [image, setImage] = useState<string | undefined>('')

  const handleClose = () => {
    setOpen(false)
  }

  const handleImage = (img: string) => {
    setImage(img)
    setOpen(true)
  }

  return (
    <>
      <Accordion expanded={isSubmited}>
        <AccordionSummary>
          <Box width="100%" display="grid" gap="8px">
            <Box display="flex">
              <Typography variant="h6" fontSize="1.1rem">
                {index + 1}.
              </Typography>
              <Typography variant="h6" fontSize="1.1rem" paddingLeft="5px">
                {questionBody}
              </Typography>
            </Box>

            <Box
              component="img"
              src={questionImg}
              alt="image relatif à la question"
              sx={{
                width: { xs: '15rem', sm: '20rem' },
                display: questionImg ? 'flex' : 'none',
                justifySelf: 'center',
              }}
              onClick={() => handleImage(questionImg as string)}
            />
            <FormControl>
              <RadioGroup>
                {propositionAnswers.map((proposition, index) => (
                  <PropositionComp
                    correction={correction}
                    key={index}
                    proposition={proposition}
                    is_selected={
                      selectedAnswer?.propositionId ===
                        proposition.propositionId ||
                      (answers !== undefined &&
                        answers.some(
                          ({ id_quest }) => id_quest === questionId
                        ) &&
                        answers.find(({ id_quest }) => id_quest === questionId)
                          ?.response === proposition.proposition)
                    }
                    isSubmited={isSubmited}
                    onSelect={(propo: Proposition) => {
                      if (!isSubmited) {
                        setSelectedAnswer(propo)
                        updateAnswers({ ...questions, answerKey: propo })
                      }
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            textAlign="justify"
            fontStyle="italic"
            sx={{ fontSize: { xs: '0.8rem', sm: '1.2rem' } }}
          >
            {feedback}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{ timeout: 600 }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Fade in={open} timeout={600} style={{ outline: 'none' }}>
          <img
            src={image}
            alt="img"
            style={{ maxHeight: '90%', maxWidth: '90%' }}
            onClick={handleClose}
          />
        </Fade>
      </Modal>
    </>
  )
}

export default QuestionExam
