// Made by Poukam Ngamaleu

import {
  Alert,
  Divider,
  Slide,
  SlideProps,
  Snackbar,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import ChangeLanguage from './changeLanguage'
import ChangePassWord from './changePassWord'
import DeletAccount from './deleteAccount'
import { useAuth } from '../../../../utils/context'
import { alertMsgInterface } from '../../../contact/SellerInformations'
import { useState } from 'react'

type TransitionProps = Omit<SlideProps, 'direction'>

function Setting() {
  const {
    userInfo: { id },
  } = useAuth()

  const [open, setOpen] = useState<boolean>(false)
  const [createdMsg, setCreatedMsg] = useState<alertMsgInterface>()

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />
  }

  return (
    <Box sx={{ padding: { xs: 1, sm: 3 } }}>
      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{ fontSize: { xs: '1.2rem', sm: '2.1rem' } }}
      >
        PARAMETRES
      </Typography>
      <Divider />
      <ChangeLanguage />
      <ChangePassWord
        id_={id as number}
        createMsg={setCreatedMsg}
        setOpen={setOpen}
      />
      <DeletAccount
        id_={id as number}
        createMsg={setCreatedMsg}
        setOpen={setOpen}
      />
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={TransitionUp}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={createdMsg?.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {createdMsg?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Setting
