// Made by Poukam Pierre

import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import Axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import QuestionExam from '../../components/questionsExam'
import theme from '../../utils/style/theme'
import { question } from '../testingPaper'

export interface Response {
  response: string
  note: boolean
  id_quest: number
}

function ResponseExam() {
  const { id_epreuve, id_composition } = useParams()
  const [questions, setQuestions] = useState<question[]>([])
  const [answers, setanswers] = useState<Response[]>([])
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/student/getExamTest/${id_epreuve}`
    )
      .then((res) => {
        if (res?.status === 200 && res.data) {
          setQuestions(res.data)
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          return
        }
      })
    Axios.get(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/student/getExamResponse/${id_composition}`
    )
      .then((res) => {
        if (res?.status === 200 && res.data) {
          setanswers(res.data)
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          return
        }
      })
  }, [])

  return (
    <Box
      component="section"
      sx={{
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        padding: { xs: '15px', sm: 3 },
      }}
    >
      <Box sx={{ maxWidth: '50rem' }}>
        <Box
          sx={{
            bgcolor: '#F5F0F0',
            borderTop: `15px solid ${theme.palette.primary.main}`,
            borderRadius: '8px',
            padding: { xs: 2, sm: 3 },
          }}
        >
          <Typography
            variant="h5"
            fontWeight="500"
            textAlign="center"
            sx={{ fontSize: { xs: '1.3rem', sm: '1.5rem' } }}
          >
            EXAMEN DU PERMIS DE CONDUIRE
          </Typography>
        </Box>
        <Box>
          {questions.map((question, index) => (
            <Box key={index} paddingTop="15px">
              <QuestionExam
                questions={question}
                key={index}
                index={index}
                updateAnswers={(question: question) => {
                  return
                }}
                isSubmited={true}
                answers={answers}
                correction={true}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default ResponseExam
