// Made by Poukam Ngamaleu

import {
  Autocomplete,
  Box,
  CircularProgress,
  Fab,
  TextField,
  Typography
} from '@mui/material'
import Axios from 'axios'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useAuth } from '../../../utils/context'
import { theme } from '../../../utils/style/theme'
import { alertMsgInterface } from '../../contact/SellerInformations'
import { generateValideSession, recordExamDate } from '../personalInformations'
import { PaymentSchema } from './paymenSchema'
const categoryArray: string[] = ['A', 'B', 'C', 'D', 'E', 'G', 'T']

function ValidationPayment({
  categoryLabel,
  souscriptionType,
  souscriptionPrice,
  setAlertMsg,
  setOpen,
}: {
  souscriptionLabel: string
  categoryLabel: string
  souscriptionType: string | undefined
  souscriptionPrice: number | undefined
  setAlertMsg: ({ message, severity }: alertMsgInterface) => void
  setOpen: (bool: boolean) => void
}) {
  const {
    userInfo: { id },
  } = useAuth()
  const { values, setFieldValue, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues: {
      souscriptionType: souscriptionType,
      categoryLabel: categoryLabel,
      souscriptionPrice: souscriptionPrice,
      session: null
    },
    onSubmit: (values) => {
      // TODO change local link to remote link
      setLoading(true)
      Axios.post(
        `${process.env.REACT_APP_URL_REMOTE_LINK}/common/souscribe/${id}`,
        values
      )
        .then((res) => {
          if (res.status === 200) {
            window.location.href = res.data.payment_url
          }
        })
        .catch((err) => {
          if (err.response.status === 500) {
            setLoading(true)
            setAlertMsg({
              message: err.response.data.message,
              severity: 'error',
            })
            setOpen(true)
          }
        })
    },
    enableReinitialize: true,
    validationSchema: PaymentSchema,
  })

  const [loading, setLoading] = useState<boolean>(false)

  return (
    <Box
      component="form"
      sx={{
        display: 'grid',
        padding: 0,
        rowGap: 2
      }}
      onSubmit={handleSubmit}
    >
      <TextField
        name="souscriptionType"
        disabled
        label="Type d'abonnement"
        value={souscriptionType}
      />
      <Autocomplete
        id="categoryLabel"
        value={values.categoryLabel}
        onChange={(_, val) => {
          setFieldValue('categoryLabel', val)
        }}
        disablePortal
        options={categoryArray}
        renderInput={(params) => (
          <TextField {...params} label="Catégorie" />
        )}
      />
      <Autocomplete
        id="session"
        onChange={(_, val) => {
          setFieldValue('session', recordExamDate[val as string])
        }}
        options={generateValideSession() as string[]}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Session d'examen"
            {...(errors.session && touched.session
              ? { error: true, helperText: errors.session }
              : '')}
          />
        )}
      />
      <TextField
        name="souscriptionPrice"
        disabled
        label="Montant à payer"
        value={souscriptionPrice}
        onChange={handleChange}
      />
      <Fab
        sx={{
          backgroundColor: theme.palette.secondary.main,
          borderRadius: '15px',
        }}
        aria-label="add"
        variant="extended"
        type="submit"
        disabled={loading ? true : false}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Typography variant="button" paddingLeft="5px">
            PAYER
          </Typography>
        )}
      </Fab>
    </Box>
  )
}

export default ValidationPayment
