// Made by Poukam Ngamaleu

import { List, ListItem, ListItemText, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { notificationInterface } from '../clientHeader'
import { Fragment } from 'react'
import theme from '../../../utils/style/theme'

function Notification({
  notifications,
}: {
  notifications: notificationInterface[]
}) {
  return (
    <Box
      sx={{
        position: 'absolute',
        backgroundColor: theme.common.notification,
        top: '50px',
        right: '0',
        width: { xs: '15rem', sm: '20rem' },
        borderRadius: '5%',
        maxHeight: '30rem',
      }}
    >
      {notifications.map(({ category, session, department }, index) => (
        <List key={index}>
          <ListItem>
            <ListItemText
              primary="Nouvelles épreuves disponibles"
              secondary={
                <Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Catégorie: {category}
                  </Typography>
                  {` — Composé dans le département du ${department}, session du ${session}`}
                </Fragment>
              }
            />
          </ListItem>
        </List>
      ))}
    </Box>
  )
}

export default Notification
