// Made by Poukam Ngamaleu

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Alert,
  Box,
  CircularProgress,
  Fab,
  IconButton,
  Slide,
  SlideProps,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import Axios from 'axios'
import { useState } from 'react'
import logo from '../../asset/logo.PNG'
import { alertMsgInterface } from '../../components/contact/SellerInformations'
import { theme } from '../../utils/style/theme'

type TransitionProps = Omit<SlideProps, 'direction'>

function ForgetPassWord({
  display,
  setDisplay,
  processing,
  setProcessing,
}: {
  display: boolean
  processing: Boolean
  setDisplay: (bool: boolean) => void
  setProcessing: (bool: boolean) => void
}) {
  const [createdMsg, setCreatedMsg] = useState<alertMsgInterface>()

  const [open, setOpen] = useState<boolean>(false)

  const [value, setValue] = useState<string>('')

  const handleResetPassword = (e: any) => {
    e.preventDefault()
    setProcessing(true)
    Axios.post(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/common/recoverPassword`,
      {
        email: value,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          setValue('')
          setCreatedMsg({
            message: res.data.message,
            severity: 'success',
          })
          setOpen(true)
          setProcessing(false)
          setTimeout(() => {
            setDisplay(false)
          }, 8000)
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setCreatedMsg({
            message: err.response.data.message,
            severity: 'info',
          })
          setOpen(true)
          setProcessing(false)
        } else if (err.response.status === 404) {
          setCreatedMsg({
            message: err.response.data.message,
            severity: 'error',
          })
          setOpen(true)
          setProcessing(false)
        } else if (err.response.status === 500) {
          setCreatedMsg({
            message: err.response.data.message,
            severity: 'error',
          })
          setOpen(true)
          setProcessing(false)
        } else {
          setCreatedMsg({
            message: 'Erreur serveur. Veuillez rééssayer plutard',
            severity: 'error',
          })
          setOpen(true)
          setProcessing(false)
        }
      })
  }

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />
  }
  return !processing ? (
    <Box
      display={display ? 'flex' : 'none'}
      justifyContent="center"
      p={2}
      flexDirection="column"
      alignItems="center"
      gap="20px"
      position="relative"
    >
      <Box
        component="img"
        sx={{ maxWidth: 100 }}
        src={logo}
        alt="OnlinePreps"
      />
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{ fontSize: { xs: '1rem', sm: '1.5rem' } }}
      >
        Réinitialiser votre mot de passe
      </Typography>
      <Box component="form" onSubmit={handleResetPassword} width="100%">
        <TextField
          id="outlined-email-input"
          label="Email"
          type="email"
          fullWidth
          required
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <Box display="flex" justifyContent="center" margin="25px 0 25px 0">
          <Fab
            sx={{ backgroundColor: theme.palette.secondary.main }}
            variant="extended"
            aria-label="add"
            type="submit"
          >
            <Typography variant="button" paddingLeft="5px">
              SOUMETTRE
            </Typography>
          </Fab>
        </Box>
      </Box>
      <IconButton
        sx={{ position: 'absolute', top: '40px', left: '10px' }}
        onClick={() => setDisplay(false)}
      >
        <ArrowBackIcon />
      </IconButton>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={TransitionUp}
        autoHideDuration={8000}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={createdMsg?.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {createdMsg?.message}
        </Alert>
      </Snackbar>
    </Box>
  ) : (
    <CircularProgress />
  )
}

export default ForgetPassWord
