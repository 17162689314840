// Made by Poukam Ngamaleu

import {
  AlertColor,
  Fab,
  FormHelperText,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material'
import { Box } from '@mui/system'
import { StylePhoneNumber } from '../user/personalInformations'
import { theme } from '../../utils/style/theme'
import { useFormik } from 'formik'
import { SellerInfSchema } from './SellerInfSchema'
import { useState } from 'react'
import Axios from 'axios'
import { testSellInterface } from '.'

export interface alertMsgInterface {
  message: string
  severity: AlertColor | undefined
}

function SellerInf({
  setInfo,
  testInfos: { pays, department, session, categoryPermis },
  setOpen,
  setCreatedMsg,
}: {
  setInfo: (info: boolean) => void
  testInfos: testSellInterface
  setOpen: (bool: boolean) => void
  setCreatedMsg: ({
    message,
    severity,
  }: {
    message: string
    severity: AlertColor | undefined
  }) => void
}) {
  const [loading, setLoading] = useState<boolean>(false)
  const {
    handleChange,
    values,
    errors,
    setFieldValue,
    touched,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      nom: '',
      email: '',
      phoneNumber: '',
      country: pays,
      depart: department,
      sess: session,
      category: categoryPermis,
    },
    onSubmit: (values) => {
      // TODO change local link to remote link
      setLoading(true)
      Axios.post(`${process.env.REACT_APP_URL_REMOTE_LINK}/info/seller`, values)
        .then((res) => {
          if (res.status === 200) {
            setCreatedMsg({
              message: res.data.message,
              severity: 'success',
            })
            setOpen(true)
            setLoading(false)
            setInfo(false)
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setCreatedMsg({
              message: 'Erreur de sauvegarde. Rééssayez plutard',
              severity: 'error',
            })
            setOpen(true)
            setLoading(false)
          }
        })
      resetForm()
    },
    validationSchema: SellerInfSchema,
    enableReinitialize: true,
  })

  return (
    <fieldset>
      <legend>
        <Typography fontSize="small">CONTACTS</Typography>
      </legend>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: 'initial', sm: '1fr 1fr' },
          gridGap: '10px',
        }}
        component="form"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          id="outlined-basic"
          label="nom"
          type="text"
          name="nom"
          onChange={handleChange}
          value={values.nom}
          variant="outlined"
          sx={{ width: '100%' }}
          {...(errors.nom && touched.nom
            ? { error: true, helperText: errors.nom }
            : '')}
        />
        <TextField
          id="outlined-basic2"
          label="email"
          type="text"
          name="email"
          value={values.email}
          onChange={handleChange}
          variant="outlined"
          sx={{ width: '100%' }}
          {...(errors.email && touched.email
            ? { error: true, helperText: errors.email }
            : '')}
        />
        <Box>
          <StylePhoneNumber
            id="phoneNumber"
            value={values.phoneNumber}
            onChange={(number: string) =>
              setFieldValue(`phoneNumber`, number?.toString())
            }
            placeholder="Numéro de téléphone"
          />
          {errors.phoneNumber && touched.phoneNumber ? (
            <FormHelperText error>{errors.phoneNumber}</FormHelperText>
          ) : (
            ''
          )}
        </Box>
        <Box display="flex" justifyContent="center" marginTop="10px">
          <Fab
            sx={{ backgroundColor: theme.palette.secondary.main }}
            variant="extended"
            aria-label="add"
            type="submit"
          >
            <Typography variant="button" paddingLeft="5px">
              ENVOYER
            </Typography>
          </Fab>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '65%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </Box>
    </fieldset>
  )
}

export default SellerInf
