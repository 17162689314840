// Made by Poukam Pierre

import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import { useNavigate } from 'react-router'
import TestInformations from '../CardTest/informationsTest'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
}

function ModalHistory({
  open,
  setOpen,
  session,
  departmentName,
  category,
  departmentCountry,
  epreuve_ref,
  date_composition,
}: {
  open: boolean
  setOpen: (bool: boolean) => void
  session: string
  departmentName: string
  category: string
  departmentCountry: string
  epreuve_ref: string
  date_composition: string
}) {
  const navigate = useNavigate()
  const handleClose = () => setOpen(false)
  const viewResponseExam = () => {
    navigate(epreuve_ref)
  }
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <TestInformations
            departmentName={departmentName}
            category={category}
            session={session}
            countryName={departmentCountry}
            date_composition={date_composition}
            setOpen={setOpen}
          />
          <Divider />
          <Box display="flex" justifyContent="center" paddingTop={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={viewResponseExam}
            >
              voir correction
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default ModalHistory
