// Made by Poukam Ngamaleu

import { IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import Draw from './drawer'
import { useState } from 'react'
import { useAuth } from '../../utils/context'
import Draws from './draws'

function Drawers() {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const {
    userInfo: { id },
  } = useAuth()

  return (
    <>
      <IconButton
        size="large"
        color="inherit"
        edge="start"
        aria-label="account of current user"
        onClick={() => setIsDrawerOpen(true)}
        sx={{ display: { md: 'none' } }}
      >
        <MenuIcon />
      </IconButton>
      {id ? (
        <Draw isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
      ) : (
        <Draws isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
      )}
    </>
  )
}

export default Drawers
