//  Made by Poukam Ngamaleu

import { DataGrid } from '@mui/x-data-grid'
import { useState, useEffect } from 'react'
import Axios from 'axios'
import { useAuth } from '../../../../utils/context'
import { alertMsgInterface } from '../../../contact/SellerInformations'

const columns: {
  field: string
  headerName: string
  width: number
  renderCell?: any
}[] = [
  { field: 'num_payment', headerName: 'TransactionID', width: 180 },
  { field: 'montant', headerName: 'Montant (frs CFA)', width: 180 },
  {
    field: 'payment_date',
    headerName: 'Date transaction',
    width: 180,
    renderCell: (params: any) => {
      return new Date(params.row.payment_date).toLocaleDateString('en-US')
    },
  },
  {
    field: 'status',
    headerName: 'Validation',
    width: 180,
    renderCell: (params: any) => {
      return (
        <>
          {params.row.status === 'accepted' ? (
            <span
              style={{
                backgroundColor: '#D2F0F2',
                color: '#41B2BA',
                padding: '10px',
                borderRadius: '15px',
              }}
            >
              Validé
            </span>
          ) : (
            <span
              style={{
                backgroundColor: '#CAD2FF',
                color: '#626DA9',
                padding: '10px',
                borderRadius: '15px',
              }}
            >
              En attente
            </span>
          )}
        </>
      )
    },
  },
]

interface rowsInterface {
  num_payment: number
  montant: number
  payment_date: string
  status: string
}

function TransactionTable({
  setCreatedMsg,
  setOpen,
  open,
}: {
  setCreatedMsg: ({ message, severity }: alertMsgInterface) => void
  setOpen: (bool: boolean) => void
  open: boolean
}) {
  const [rows, setRows] = useState<rowsInterface[]>([])
  const {
    userInfo: { id },
  } = useAuth()
  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/partner/getTransactionPayment/${id}`
    )
      .then((res) => {
        if (res.status === 200 && res.data) setRows(res.data)
      })
      .catch((err) => {
        if (err?.response.status === 404) {
          setCreatedMsg({
            message: err.response.data.message,
            severity: 'info',
          })
          setOpen(true)
        }
      })
  }, [open])
  return (
    <DataGrid
      getRowId={(rows) => rows.num_payment}
      rows={rows}
      columns={columns}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      sx={{ maxWidth: '50rem' }}
    />
  )
}

export default TransactionTable
