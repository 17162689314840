// Made by Poukam Ngamaleu

import {
  Alert,
  Divider,
  Slide,
  SlideProps,
  Snackbar,
  Typography
} from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useState } from 'react'
import { theme } from '../../../utils/style/theme'
import { alertMsgInterface } from '../../contact/SellerInformations'
import AccountInf from './accountInf'
import ValidationPayment from './validationPaymentInfos'

type TransitionProps = Omit<SlideProps, 'direction'>

interface SouscriptionInterface {
  souscriptionLabel: string
  souscriptionType: string | undefined
  categoryLabel: string
  souscriptionPrice: number | undefined
}

function Souscription() {
  const [open, setOpen] = useState<boolean>(false)
  const [createdMsg, setCreatedMsg] = useState<alertMsgInterface>()
  const [souscriptionInfo] =
    useState<SouscriptionInterface>({
      souscriptionLabel: 'Re-Abonnement',
      souscriptionPrice: 4000,
      souscriptionType: 're-abonnement',
      categoryLabel: 'B',
    })

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />
  }
  return (
    <>
      <Box sx={{ padding: { xs: '4px 16px 16px', sm: '24px 24px 0' } }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ fontSize: '1.5rem', sm: '2.125rem' }}
        >
          Abonnement
        </Typography>
        <Divider />
      </Box>
      <AccountInf setAlertMsg={setCreatedMsg} setOpen={setOpen} />
      <Box sx={{ padding: { xs: 3, sm: '24px 24px 0' } }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ fontSize: '1.5rem', sm: '2.125rem' }}
        >
          Re-Abonnement
        </Typography>
        <Divider />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{
            padding: { xs: 1.5, sm: 3 },
            justifyContent: { xs: 'center', sm: 'space-around' },
            gap: { xs: '10px', sm: 'initial' },
          }}
        >
        </Grid>
        <Box
          sx={{
            width: { xs: '100%', md: '40rem' },
            bgcolor: theme.palette.secondary.contrastText,
            padding: '1.1rem',
          }}
        >
          <ValidationPayment
            {...souscriptionInfo}
            setAlertMsg={setCreatedMsg}
            setOpen={setOpen}
          />
        </Box>
      </Box>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={TransitionUp}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={createdMsg?.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {createdMsg?.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Souscription
