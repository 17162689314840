// Made by Poukam Ngamaleu

import { Box, Pagination } from '@mui/material'

function AppPagination({
  paginate,
  count,
  postPerPage,
}: {
  paginate: (event: React.ChangeEvent<unknown>, value: number) => void
  count: number
  postPerPage: number
}) {
  return (
    <Box
      display={Math.ceil(count / postPerPage) === 1 ? 'none' : 'flex'}
      alignItems="center"
      justifyContent="center"
      sx={{ margin: '20px 0px' }}
    >
      <Pagination
        color="secondary"
        onChange={paginate}
        count={Math.ceil(count / postPerPage)}
      />
    </Box>
  )
}

export default AppPagination
