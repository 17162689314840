// Made by Poukam Ngamaleu

import { Box, Paper, styled, Typography } from '@mui/material'
import Axios from 'axios'
import { useEffect, useState } from 'react'

const StyledWelcome = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontSize: '30px',
  lineHeight: '48px',
}))

function WelcomTopPrepsAccount({ name, code }: { name: string; code: string }) {
  const [studentPerCodePreps, setStudentPerCodePreps] = useState<number>()

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/partner/getStudentPerCodePrep/${code}`
    )
      .then((res) => {
        if (res.status === 200 && res.data) {
          const lenght = Object.keys(res.data)
          setStudentPerCodePreps(lenght.length)
        }
      })
      .catch((err) => {
        if (err?.response.status === 404) {
          setStudentPerCodePreps(0)
        }
      })
  }, [])

  return (
    <Box
      sx={{ display: { xs: 'initial', md: 'flex' } }}
      justifyContent="space-evenly"
      p={3}
    >
      <Paper
        sx={{
          padding: { xs: 1, md: 6 },
          textAlign: 'center',
          backgroundColor: 'transparent',
          marginBottom: { xs: '15px' },
        }}
        elevation={3}
      >
        <StyledWelcome
          variant="h5"
          sx={{
            fontSize: { xs: '0.8rem', sm: '1.5rem' },
            fontWeight: 400,
            marginBottom: { xs: 0, sm: '10px' },
          }}
        >
          BON RETOUR PARMI NOUS
        </StyledWelcome>
        <Typography
          variant="h4"
          sx={{ fontSize: { xs: '1rem', sm: '2rem' }, fontWeight: 'bold' }}
        >
          {name.toUpperCase()}
        </Typography>
      </Paper>
      <Paper
        sx={{
          padding: { xs: 1, md: 6 },
          textAlign: 'center',
          backgroundColor: 'transparent',
          marginBottom: { xs: '15px' },
        }}
        elevation={3}
      >
        <StyledWelcome
          variant="h4"
          marginBottom="15px"
          sx={{
            fontSize: { xs: '0.8rem', sm: '1.5rem' },
            fontWeight: 400,
            marginBottom: { xs: 0, sm: '10px' },
          }}
        >
          CODE PREPS
        </StyledWelcome>
        <Typography
          variant="h4"
          sx={{ fontSize: { xs: '1rem', sm: '2rem' }, fontWeight: 'bold' }}
        >
          {code}
        </Typography>
      </Paper>
      <Paper
        sx={{
          padding: { xs: 1, md: 6 },
          textAlign: 'center',
          backgroundColor: 'transparent',
          marginBottom: { xs: '15px' },
        }}
        elevation={3}
      >
        <StyledWelcome
          variant="h4"
          marginBottom="15px"
          sx={{
            fontSize: { xs: '0.8rem', sm: '1.5rem' },
            fontWeight: 400,
            marginBottom: { xs: 0, sm: '10px' },
          }}
        >
          NOMBRE INSCRITS
        </StyledWelcome>
        <Typography
          variant="h4"
          sx={{ fontSize: { xs: '1rem', sm: '2rem' }, fontWeight: 'bold' }}
        >
          {studentPerCodePreps}
        </Typography>
      </Paper>
    </Box>
  )
}

export default WelcomTopPrepsAccount
