//  Made by Poukam Ngamaleu

import * as yup from 'yup'

export const modalPaymentShema = yup.object().shape({
  balance: yup.number().nullable(),
  limitWithdraw: yup.number().nullable(),
  withdrawalPrice: yup
    .number()
    .min(2000, 'ne peut être inférieur à 2000 frs!')
    .max(yup.ref('limitWithdraw'), 'Ne peut excéder le plafond du retrait')
    .required('Entrez montant de retrait'),
})
