// Made by Poukam Ngamaleu

import { Outlet } from 'react-router'
import Header from '../../components/Header'

function SignSkeleton() {
  return (
    <>
      <Header />
      <Outlet></Outlet>
    </>
  )
}

export default SignSkeleton
