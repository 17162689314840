// Made by Poukam Ngamaleu

import {
  Alert,
  Box,
  Divider,
  Fab,
  Slide,
  SlideProps,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import Axios from 'axios'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { theme } from '../../../utils/style/theme'
import { alertMsgInterface } from '../../contact/SellerInformations'
import { PartnerSchema } from './partnerSchema'
import CircularProgress from '@mui/material/CircularProgress'

type TransitionProps = Omit<SlideProps, 'direction'>

function PartnerInf() {
  const navigate = useNavigate()
  const [open, setOpen] = useState<boolean>(false)
  const [createdMsg, setCreatedMsg] = useState<alertMsgInterface>()
  const [loading, setLoading] = useState<Boolean>(false)
  const { handleChange, values, errors, touched, handleSubmit, resetForm } =
    useFormik({
      initialValues: {
        nom: '',
        prenom: '',
        email: '',
        password: '',
        confirmPassword: '',
        training_center: '',
      },
      onSubmit: (values) => {
        setLoading(true)
        // TODO change local link to remote link
        Axios.post(
          `${process.env.REACT_APP_URL_REMOTE_LINK}/signup/partner`,
          values
        )
          .then((res) => {
            if (res?.status === 201) {
              setCreatedMsg({
                message: 'Enregistrement réussi.',
                severity: 'success',
              })
              setOpen(true)
              resetForm()
              setTimeout(() => navigate('/signIn'), 4000)
            }
          })
          .catch((err) => {
            if (err.response.status === 409) {
              setCreatedMsg({
                message: err.response.data.message,
                severity: 'error',
              })
              setOpen(true)
              setLoading(false)
            } else {
              setCreatedMsg({
                message: 'Erreur serveur. Rééssayez plutard.',
                severity: 'error',
              })
              setOpen(true)
              setLoading(false)
            }
          })
        resetForm()
      },
      validationSchema: PartnerSchema,
    })

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />
  }

  return (
    <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
      <Box width="100%" sx={{ padding: { xs: '0', sm: '16px 16px 0 16px' } }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
        >
          INFORMATIONS PERSONNELLES
        </Typography>
        <Divider sx={{ alignSelf: 'normal', marginBottom: '10px' }} />
        <Box
          lineHeight="4"
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: 'initial', sm: '1fr 1fr' },
            gridGap: '5px',
            padding: { xs: '0 0 16px', sm: '16px' },
          }}
        >
          <TextField
            id="outlined-basic"
            label="nom"
            type="text"
            name="nom"
            onChange={handleChange}
            value={values.nom}
            variant="outlined"
            sx={{ width: '100%' }}
            {...(errors.nom && touched.nom
              ? { error: true, helperText: errors.nom }
              : '')}
          />
          <TextField
            id="outlined-basic1"
            label="prenom"
            type="text"
            name="prenom"
            value={values.prenom}
            onChange={handleChange}
            variant="outlined"
            sx={{ width: '100%' }}
            {...(errors.prenom && touched.prenom
              ? { error: true, helperText: errors.prenom }
              : '')}
          />
          <TextField
            id="outlined-basic2"
            label="email"
            type="text"
            name="email"
            value={values.email}
            onChange={handleChange}
            variant="outlined"
            sx={{ width: '100%' }}
            {...(errors.email && touched.email
              ? { error: true, helperText: errors.email }
              : '')}
          />
          <TextField
            id="training_center"
            label="Centre de formation"
            type="text"
            name="training_center"
            value={values.training_center}
            onChange={handleChange}
            variant="outlined"
            sx={{ width: '100%' }}
            {...(errors.training_center && touched.training_center
              ? { error: true, helperText: errors.training_center }
              : '')}
          />
          <TextField
            id="password"
            label="Mot de passe"
            type="password"
            name="password"
            onChange={handleChange}
            value={values.password}
            autoComplete="current-password"
            {...(errors.password && touched.password
              ? { error: true, helperText: errors.password }
              : '')}
          />
          <TextField
            id="confirmPassword"
            label="Confirmer mot de passe"
            type="password"
            name="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChange}
            {...(errors.confirmPassword && touched.confirmPassword
              ? { error: true, helperText: errors.confirmPassword }
              : '')}
          />
        </Box>
        <Typography
          textAlign="justify"
          padding="0 0 20px 0"
          sx={{ width: { sx: '100%', md: '455px' } }}
        >
          Un <strong>code preps</strong> unique est généré après validation de
          votre inscription en tant que partenaire. Chaque apprenant inscrit via
          ce code vous permet d'encaisser jusqu'à 30% de son inscription.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          sx={{ position: 'relative' }}
        >
          <Fab
            sx={{ backgroundColor: theme.palette.secondary.main }}
            variant="extended"
            aria-label="add"
            type="submit"
            disabled={loading ? true : false}
          >
            <Typography variant="button" paddingLeft="5px">
              INSCRIPTION
            </Typography>
          </Fab>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </Box>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={TransitionUp}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={createdMsg?.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {createdMsg?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default PartnerInf
