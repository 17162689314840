// Made by Poukam pierre

import { useAuth } from '../../utils/context'
import ClientFooter from './clientFooter'
import ProspectFooter from './prospectFooter'

function Footer() {
  const {
    userInfo: { is_partner, is_student },
  } = useAuth()
  return is_student || is_partner ? <ClientFooter /> : <ProspectFooter />
}

export default Footer
