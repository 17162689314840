// Made by Poukam Ngamaleu

import { Box } from '@mui/system'
import { Alert, AppBar, Slide, SlideProps, Snackbar } from '@mui/material'
import { Link } from 'react-router-dom'
import { StyledToolbar } from '../prospectHeader'
import logo from '../../../asset/logo.PNG'
import { theme } from '../../../utils/style/theme'
import MenuSettingPartner from '../../menuSetting/menuSettingPartner'
import { useAuth } from '../../../utils/context'
import { alertMsgInterface } from '../../contact/SellerInformations'
import { io } from 'socket.io-client'
import { useEffect, useState } from 'react'

type TransitionProps = Omit<SlideProps, 'direction'>
const socket = io(process.env.REACT_APP_URL_SOCKET_LINK as string)

function PartnerHeader() {
  const [open, setOpen] = useState<boolean>(false)
  const [createdMsg, setCreatedMsg] = useState<alertMsgInterface>()

  const {
    userInfo: { id, nom, status_connected },
    authDispatch,
    accessToken,
    userInfo,
  } = useAuth()

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />
  }

  useEffect(() => {
    // TODO make greeting connection
    if (id && !status_connected) {
      // private message greeting
      setCreatedMsg({
        message: `Content de vous revoir ${
          nom.charAt(0).toUpperCase() + nom.slice(1)
        } !`,
        severity: 'success',
      })
      setOpen(true)
      authDispatch({
        accessToken: accessToken,
        userInfo: { ...userInfo, status_connected: 'connected' },
      })

      // Adding infomation to server io
      socket.emit('newUser', id)
    }
  }, [])

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: theme.common.Bgheader }}>
        <StyledToolbar>
          <Box component={Link} to="/partner">
            <Box
              component="img"
              sx={{ maxWidth: 65 }}
              src={logo}
              alt="OnlinePreps"
            />
          </Box>
          <Box display="flex">
            <MenuSettingPartner />
          </Box>
        </StyledToolbar>
      </AppBar>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={TransitionUp}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={createdMsg?.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {createdMsg?.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default PartnerHeader
