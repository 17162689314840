//  Made by Poukam Ngamaleu

import {
  Alert,
  Box,
  Divider,
  Paper,
  Slide,
  SlideProps,
  Snackbar,
  Typography,
} from '@mui/material'
import SearchTest from './SearchTestSell'
import SellerInf, { alertMsgInterface } from './SellerInformations'
import { useState } from 'react'

type TransitionProps = Omit<SlideProps, 'direction'>

export interface testSellInterface {
  pays: string
  department: string
  categoryPermis: string
  session: string
}

function Contact() {
  const [enterPersonalInfo, setEnterPersonalInfo] = useState<boolean>(false)
  const [testInfos, setTestInfos] = useState<testSellInterface>()
  const [createdMsg, setCreatedMsg] = useState<alertMsgInterface>()
  const [open, setOpen] = useState<boolean>(false)

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />
  }

  return (
    <Box sx={{ padding: { xs: 0.5, sm: 3 } }}>
      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{
          fontSize: '1.5rem',
          sm: '2.125rem',
          padding: { xs: '4px 8px', sm: 0 },
        }}
      >
        Vente d'épreuves
      </Typography>
      <Divider />
      <Box
        sx={{
          height: { xs: 'initial', sm: '88vh' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 1,
        }}
      >
        <Paper sx={{ padding: { xs: 1, sm: 4 } }}>
          <Typography
            variant="h4"
            textAlign="center"
            paddingBottom="25px"
            fontWeight="bold"
            sx={{ fontSize: { xs: '1.1rem', sm: '2.125rem' } }}
          >
            Faites un bon deal avec vos épreuves nationales du permis de
            conduire
          </Typography>
          <SearchTest
            setInfo={setEnterPersonalInfo}
            infos={enterPersonalInfo}
            setTestInfo={setTestInfos}
            setOpen={setOpen}
            setCreatedMsg={setCreatedMsg}
          />
          <Box padding="40px 0 32px 0">
            <Typography
              variant="h5"
              sx={{ fontSize: { xs: '1.1rem', sm: '1.5rem' } }}
            >
              INFORMATIONS DU VENDEUR
            </Typography>
            <Divider />
          </Box>
          {enterPersonalInfo ? (
            <SellerInf
              setInfo={setEnterPersonalInfo}
              testInfos={testInfos as testSellInterface}
              setOpen={setOpen}
              setCreatedMsg={setCreatedMsg}
            />
          ) : (
            <Typography textAlign="center">
              Nous attendons vos informations de contact après validation des
              épreuves en votre posséssion
            </Typography>
          )}
        </Paper>
      </Box>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={TransitionUp}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={createdMsg?.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {createdMsg?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Contact
