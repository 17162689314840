// Made by Poukam Ngamaleu

import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import MailRoundedIcon from '@mui/icons-material/MailRounded'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

import { Box, IconButton } from '@mui/material'

function LinkedBtn() {
  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        margin: '10px',
      }}
    >
      <IconButton
        color="inherit"
        component="a"
        href=" https://www.facebook.com/profile.php?id=100069092821755"
        target="_blank"
      >
        <FacebookRoundedIcon />
      </IconButton>
      <IconButton
        color="inherit"
        component="a"
        href=" https://wa.me/+237696841451"
        target="_blank"
      >
        <WhatsAppIcon />
      </IconButton>
      <IconButton color="inherit">
        <MailRoundedIcon />
      </IconButton>
    </Box>
  )
}

export default LinkedBtn
