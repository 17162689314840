// Made by Poukam Ngamaleu

import {
  Autocomplete,
  Box,
  Button,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { theme } from '../../../../utils/style/theme'

function ChangeLanguage() {
  return (
    <Box sx={{ padding: { xs: 1, sm: 2 } }}>
      <Paper
        sx={{
          padding: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          backgroundColor: 'transparent',
          flexDirection: { xs: 'column', sm: 'initial' },
          gap: { xs: 3, sm: 'initial' },
        }}
        elevation={3}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="15px"
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ fontSize: { xs: '1.2rem', sm: '2rem' } }}
          >
            CHANGER DE LANGUE
          </Typography>
          <Autocomplete
            options={['français', 'anglais']}
            sx={{ width: 200 }}
            renderInput={(params) => <TextField {...params} label="Langue" />}
          />
        </Box>
        <Button
          variant="outlined"
          sx={{ borderColor: theme.palette.secondary.main }}
        >
          <Typography variant="button" color="black">
            ENREGISTRER
          </Typography>
        </Button>
      </Paper>
    </Box>
  )
}

export default ChangeLanguage
