// Made by Poukam Ngamaleu

import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Avatar,
} from '@mui/material'
import { Box } from '@mui/system'
import { Link, useNavigate } from 'react-router-dom'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded'
import ContactPageRoundedIcon from '@mui/icons-material/ContactPageRounded'
import RestorePageRoundedIcon from '@mui/icons-material/RestorePageRounded'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import HandshakeIcon from '@mui/icons-material/Handshake'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import { Logout, Settings } from '@mui/icons-material'

import { useAuth } from '../../utils/context'

function Draw({
  isDrawerOpen,
  setIsDrawerOpen,
}: {
  isDrawerOpen: boolean
  setIsDrawerOpen: (bool: boolean) => void
}) {
  const {
    userInfo: { is_partner, nom },
    authDispatch,
  } = useAuth()
  const navigate = useNavigate()

  const disconnected = () => {
    authDispatch({
      accessToken: '',
      userInfo: {
        id: undefined,
        nom: '',
        prenom: '',
        email: '',
      },
    })
    navigate('/home')
  }

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
    >
      <Box
        sx={{ display: { sx: 'flex', md: 'none' } }}
        p={2}
        width="250px"
        role="presentation"
      >
        <Box marginTop="12px" paddingLeft="20px" marginBottom="10px">
          <Typography variant="h6">
            {nom.charAt(0).toUpperCase() + nom.slice(1)}
          </Typography>
          <Typography>Bon retour parmi nous </Typography>
        </Box>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              to="/"
              component={Link}
              onClick={() => setIsDrawerOpen(false)}
            >
              <ListItemIcon>
                <HomeRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Accueil" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              to="/Epreuves"
              component={Link}
              onClick={() => setIsDrawerOpen(false)}
            >
              <ListItemIcon>
                <TextSnippetRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Epreuves" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              to="/history"
              component={Link}
              onClick={() => setIsDrawerOpen(false)}
            >
              <ListItemIcon>
                <RestorePageRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Corrections" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              to="/Contact"
              component={Link}
              onClick={() => setIsDrawerOpen(false)}
            >
              <ListItemIcon>
                <ContactPageRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Vente d'épreuves" />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              to="/Dashboard"
              component={Link}
              onClick={() => setIsDrawerOpen(false)}
            >
              <ListItemIcon>
                <GridViewRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Tableau de bord" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              to="/Account"
              component={Link}
              onClick={() => setIsDrawerOpen(false)}
            >
              <ListItemIcon>
                <Avatar sx={{ width: '1.1em', height: '1.1em' }} />
              </ListItemIcon>
              <ListItemText primary="Mon compte" />
            </ListItemButton>
          </ListItem>
          {is_partner && (
            <ListItem disablePadding>
              <ListItemButton
                to="/Partner"
                component={Link}
                onClick={() => setIsDrawerOpen(false)}
              >
                <ListItemIcon>
                  <HandshakeIcon />
                </ListItemIcon>
                <ListItemText primary="Partenaire" />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton
              to="/souscription"
              component={Link}
              onClick={() => setIsDrawerOpen(false)}
            >
              <ListItemIcon>
                <CurrencyExchangeIcon />
              </ListItemIcon>
              <ListItemText primary="Abonnement" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton
              to="/Settings"
              component={Link}
              onClick={() => setIsDrawerOpen(false)}
            >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="Paramètres" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={disconnected}>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText primary="Déconnexion" />
            </ListItemButton>
          </ListItem>
        </List>
        <Box marginTop="30px" paddingLeft="20px">
          <Typography variant="overline"> By Poukam Tech </Typography>
        </Box>
      </Box>
    </Drawer>
  )
}

export default Draw
