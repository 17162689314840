// Made by Poukam Ngamaleu

import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Composition } from '.'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { useNavigate } from 'react-router'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#512DAB',
    color: theme.palette.common.white,
    fontSize: 18,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 1,
  },
}))

function TableTestDone({ examInfo }: { examInfo: Composition[] | undefined }) {
  const navigate = useNavigate()

  const handleViewCorrection = (id_epreuve: string, id_composition: number) => {
    navigate(`/exam_review/${id_epreuve}/${id_composition}`)
  }

  return (
    <Box sx={{ padding: { xs: '8px 0', sm: '0 24px' } }}>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Listes d'épreuves traitées</StyledTableCell>
              <StyledTableCell align="center">Note</StyledTableCell>
              <StyledTableCell align="center">Validation</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {examInfo?.map(
              (
                {
                  libele_cat,
                  libele_depart,
                  note,
                  date_session,
                  id_epreuve,
                  id_composition,
                },
                index
              ) => (
                <StyledTableRow
                  key={index}
                  onClick={() =>
                    handleViewCorrection(id_epreuve, id_composition)
                  }
                  sx={{ cursor: 'pointer' }}
                >
                  <StyledTableCell component="th" scope="row">
                    Catégorie {libele_cat}, Département: {libele_depart},
                    Session: {date_session}
                  </StyledTableCell>
                  <StyledTableCell align="center">{note}/40</StyledTableCell>
                  <StyledTableCell align="center" sx={{ fontWeight: 'bold' }}>
                    {note > 24 ? (
                      <TaskAltIcon color="success" />
                    ) : (
                      <HighlightOffIcon color="error" />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default TableTestDone
