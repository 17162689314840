// Made by Poukam Ngamaleu

import { Logout, Settings } from '@mui/icons-material'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../utils/context'

const StyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'none',
})

function MenuSettingPartner() {
  const [openElUser, setOpenElUser] = useState(null)
  const {
    userInfo: { is_student, nom, profil_img },
    authDispatch,
  } = useAuth()
  const navigate = useNavigate()
  const handleOpenUserMenu = (event: any) => {
    setOpenElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setOpenElUser(null)
  }

  const disconnected = () => {
    authDispatch({
      accessToken: '',
      userInfo: {
        id: undefined,
        nom: '',
        prenom: '',
        email: '',
      },
    })
    navigate('/home')
  }
  return (
    <Box>
      <IconButton onClick={handleOpenUserMenu}>
        <Avatar alt={nom} src={profil_img} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={openElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(openElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem sx={{ display: 'flex' }} onClick={handleCloseUserMenu}>
          <Avatar sx={{ width: '1em', height: '1em' }} />
          <Typography
            textAlign="center"
            component={StyledLink}
            to="/partner/account"
            paddingLeft="10px"
          >
            Compte Preps
          </Typography>
        </MenuItem>
        <Divider />
        {is_student ? (
          <Box>
            <MenuItem
              sx={{ display: 'flex' }}
              onClick={() => handleCloseUserMenu}
            >
              <LocalLibraryIcon fontSize="small" />
              <Typography
                textAlign="center"
                component={StyledLink}
                to="/"
                paddingLeft="10px"
              >
                Apprenant
              </Typography>
            </MenuItem>
            <Divider />
          </Box>
        ) : null}
        <MenuItem sx={{ display: 'flex' }} onClick={handleCloseUserMenu}>
          <Settings fontSize="small" />
          <Typography
            textAlign="center"
            component={StyledLink}
            to="/partner/settings"
            paddingLeft="10px"
          >
            Paramètres
          </Typography>
        </MenuItem>
        <MenuItem sx={{ display: 'flex' }} onClick={disconnected}>
          <Logout fontSize="small" />
          <Typography textAlign="center" paddingLeft="15px">
            Déconnexion
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default MenuSettingPartner
