// Made by Poukam Ngamaleu

import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import logo from '../../../asset/logoOnlinePreps.png'
import { theme } from '../../../utils/style/theme'
import AboutUs from './aboutUs'
import ContactUs from './contact'

function ProspectFooter() {
  return (
    <Box
      sx={{
        display: { xs: 'inherit', md: 'grid' },
        padding: { xs: '20px', sm: '96px 96px 20px' },
      }}
      gridTemplateColumns="1fr 1fr"
      justifyContent="center"
      textAlign="center"
      bgcolor={theme.palette.primary.contrastText}
      color="#D2E9F1"
    >
      <Box
        textAlign="initial"
        sx={{
          display: 'grid',
          gap: '50px',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'fit-content',
          textAlign: { xs: 'center', md: 'initial' },
          paddingBottom: { xs: '60px', md: 'initial' },
        }}
      >
        <Box>
          <List>
            <ListItem sx={{ padding: 0 }}>
              <ListItemButton
                sx={{ padding: 0, textAlign: { xs: 'center', md: 'initial' } }}
              >
                <ListItemText
                  primary="Condition d'utilisation"
                  primaryTypographyProps={{
                    fontWeight: 500,
                    fontSize: '1.25rem',
                    letterSpacing: '0.0075em',
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
          <Typography>Onlinepreps est un projet propriétaire. </Typography>
        </Box>
        <AboutUs />

        <Box>
          <Typography variant="h6">Societe propritaire</Typography>
          <Typography>Poukam Tech SARL</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gap: '150px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          component="img"
          sx={{
            width: 200,
            justifySelf: 'center',
            display: { xs: 'none', md: 'initial' },
          }}
          src={logo}
          alt="OnlinePreps"
        />
        <ContactUs />
        <Typography variant="caption" fontSize="20px" fontWeight="600">
          {`Copyright © OnLinePreps ${new Date().getFullYear()}`}. All Rights
          Reserved.
        </Typography>
      </Box>
    </Box>
  )
}

export default ProspectFooter
