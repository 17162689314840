// Made by Poukam Ngamaleu

import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'

function TestInformations({
  departmentName,
  session,
  category,
  countryName,
  date_composition,
  setOpen,
}: {
  departmentName: string
  session: string
  category: string
  countryName: string
  date_composition?: string
  setOpen: (bool: boolean) => void
}) {
  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h6"
          sx={{ fontSize: { xs: '1.2rem', sm: '1.4rem' }, paddingLeft: 2 }}
        >
          INFORMATIONS
        </Typography>
        <IconButton
          size="small"
          onClick={() => setOpen(false)}
          sx={{ display: { xs: 'initial', sm: 'none' } }}
        >
          <ClearIcon color="error" />
        </IconButton>
      </Box>
      <Divider />
      <List>
        <ListItem>
          <ListItemText
            primary="Pays :"
            primaryTypographyProps={{
              fontSize: { xs: '1rem', sm: '1.2rem' },
              fontWeight: 'bold',
            }}
          />
          <Box component="span">
            <Typography>{countryName.toLocaleUpperCase()}</Typography>
          </Box>
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Année :"
            primaryTypographyProps={{
              fontSize: { xs: '1rem', sm: '1.2rem' },
              fontWeight: 'bold',
            }}
          />
          <Box component="span">
            <Typography>{session.split('/')[2]}</Typography>
          </Box>
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Session :"
            primaryTypographyProps={{
              fontSize: { xs: '1rem', sm: '1.2rem' },
              fontWeight: 'bold',
            }}
          />
          <Box component="span">
            <Typography>{session}</Typography>
          </Box>
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Département :"
            primaryTypographyProps={{
              fontSize: { xs: '1rem', sm: '1.2rem' },
              fontWeight: 'bold',
            }}
          />
          <Box component="span">
            <Typography>{departmentName}</Typography>
          </Box>
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Catégorie permis :"
            primaryTypographyProps={{
              fontSize: { xs: '1rem', sm: '1.2rem' },
              fontWeight: 'bold',
            }}
          />
          <Box component="span">
            <Typography>{category}</Typography>
          </Box>
        </ListItem>
        {date_composition && (
          <ListItem>
            <ListItemText
              primary="Date de composition :"
              primaryTypographyProps={{
                fontSize: { xs: '1rem', sm: '1.2rem' },
                fontWeight: 'bold',
              }}
            />
            <Box component="span">
              <Typography>{date_composition}</Typography>
            </Box>
          </ListItem>
        )}
      </List>
    </Box>
  )
}

export default TestInformations
