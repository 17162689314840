// Made by Poukam Ngamaleu

import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'

function AboutUs() {
  return (
    <Box>
      <Typography variant="h6">À propos</Typography>
      <List>
        <ListItem sx={{ padding: 0 }}>
          <ListItemButton
            sx={{ padding: 0, textAlign: { xs: 'center', md: 'initial' } }}
          >
            <ListItemText
              primary="À propos de la société"
              primaryTypographyProps={{ fontSize: '18px' }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ padding: 0 }}>
          <ListItemButton
            sx={{ padding: 0, textAlign: { xs: 'center', md: 'initial' } }}
          >
            <ListItemText
              primary="Contact"
              primaryTypographyProps={{ fontSize: '18px' }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ padding: 0 }}>
          <ListItemButton
            sx={{ padding: 0, textAlign: { xs: 'center', md: 'initial' } }}
          >
            <ListItemText
              primary="Contact de vente"
              primaryTypographyProps={{ fontSize: '18px' }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ padding: 0 }}>
          <ListItemButton
            sx={{ padding: 0, textAlign: { xs: 'center', md: 'initial' } }}
          >
            <ListItemText
              primary="Confidentialité"
              primaryTypographyProps={{ fontSize: '18px' }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )
}

export default AboutUs
