//  Made by Poukam Ngamaleu

import {
  Alert,
  Box,
  Button,
  Divider,
  Slide,
  SlideProps,
  Snackbar,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../utils/context'
import { theme } from '../../../../utils/style/theme'
import { alertMsgInterface } from '../../../contact/SellerInformations'
import AmountTable from '../transactionnalAmountTable'
import WelcomTopPrepsAccount from '../welcomToPrepsAccount'
import { useState, useEffect } from 'react'
import Axios from 'axios'

type TransitionProps = Omit<SlideProps, 'direction'>

function PrepsAccount() {
  const {
    userInfo: { is_student, nom, code_preps, id },
    userInfo,
    accessToken,
    authDispatch,
  } = useAuth()

  const [open, setOpen] = useState<boolean>(false)
  const [createdMsg, setCreatedMsg] = useState<alertMsgInterface>()

  useEffect(() => {
    // TODO change local link into remote link
    if (!code_preps) {
      Axios.get(
        `${process.env.REACT_APP_URL_REMOTE_LINK}/partner/getCode_preps/${id}`
      )
        .then((res) => {
          if (res.status === 200 && res.data) {
            authDispatch({
              accessToken: accessToken,
              userInfo: { ...userInfo, code_preps: res.data },
            })
          }
        })
        .catch((err) => {
          if (err?.response.status === 400) {
            return
          }
        })
    }
  }, [])

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />
  }
  return (
    <Box sx={{ padding: { xs: 0.5, sm: 3 } }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        sx={{ flexDirection: { xs: 'column', sm: 'inherit' } }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}
        >
          COMPTE Preps
        </Typography>
        {!is_student && (
          <Button
            variant="outlined"
            sx={{
              borderColor: theme.palette.secondary.main,
              color: theme.palette.primary.contrastText,
            }}
            component={Link}
            to="/partner/souscription"
          >
            DEVENIR APPRENANT
          </Button>
        )}
      </Box>
      <Divider />
      <WelcomTopPrepsAccount name={nom} code={code_preps as string} />
      <AmountTable setCreatedMsg={setCreatedMsg} setOpenS={setOpen} />
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={TransitionUp}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={createdMsg?.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {createdMsg?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default PrepsAccount
