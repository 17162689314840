// Poukam Pierre

import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Alert,
  Box,
  Fab,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Slide,
  SlideProps,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Axios from 'axios'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { alertMsgInterface } from '../../components/contact/SellerInformations'
import LinkedBtn from '../../components/linkedButton'
import theme from '../../utils/style/theme'
import { resetPassword } from './resetPassword'

type TransitionProps = Omit<SlideProps, 'direction'>

function ResetPassword() {
  const navigate = useNavigate()
  const { resetToken } = useParams()
  const [createdMsg, setCreatedMsg] = useState<alertMsgInterface>()
  const [open, setOpen] = useState<boolean>(false)
  const [value, setValue] = useState<boolean>(false)
  const [loading, setLoading] = useState<Boolean>(false)

  const { values, handleSubmit, handleChange, errors, touched, resetForm } =
    useFormik({
      initialValues: {
        resetToken: resetToken as string,
        newPassword: '',
        confirmPassword: '',
      },
      onSubmit: (values) => {
        setLoading(true)
        //TODO change local link to remote link
        Axios.put(
          `${process.env.REACT_APP_URL_REMOTE_LINK}/common/changePassword`,
          values
        )
          .then((res) => {
            if (res?.status === 200) {
              setCreatedMsg({
                message: res.data.message,
                severity: 'success',
              })
              resetForm()
              setOpen(true)
              setLoading(false)
              setTimeout(() => {
                navigate('/signIn')
              }, 4000)
            }
          })
          .catch((err) => {
            if (err.response.status === 404) {
              setCreatedMsg({
                message: err.response.data.message,
                severity: 'warning',
              })
              setOpen(true)
              setLoading(false)
            } else {
              setCreatedMsg({
                message: err.response.data.message,
                severity: 'error',
              })
              setOpen(true)
              setLoading(false)
            }
          })
      },
      validationSchema: resetPassword,
    })

  function handleClickShowPassword() {
    setValue(!value)
  }

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />
  }

  return (
    <Box
      sx={{
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Paper
        sx={{
          width: { xs: '100%', sm: '35em' },
          borderRadius: { xs: '0', sm: '40px' },
          backgroundColor: { xs: 'initial', sm: '#D7CBEC' },
          elevation: { xs: 0, sm: 3 },
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          p={2}
          flexDirection="column"
          alignItems="center"
          gap="20px"
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ fontSize: { xs: '1.1rem', sm: '1.5rem' } }}
          >
            BON RETOUR PARMI NOUS
          </Typography>
          <Typography>Veuillez entrer le nouveau mot de passe</Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <Box sx={{ padding: { xs: 0, sm: '0 40px' }, gap: 2 }}>
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Mot de passe
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={value ? 'text' : 'password'}
                  onChange={handleChange}
                  value={values.newPassword}
                  name="newPassword"
                  {...(errors.newPassword && touched.newPassword
                    ? { error: true }
                    : '')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {value ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {errors.newPassword && touched.newPassword ? (
                  <FormHelperText error>{errors.newPassword}</FormHelperText>
                ) : (
                  ''
                )}
              </FormControl>
              <TextField
                name="confirmPassword"
                label="Reécrire nouveau mot de passe"
                type="password"
                autoComplete="retyping-new-password"
                sx={{ width: '100%', marginTop: 1 }}
                value={values.confirmPassword}
                onChange={handleChange}
                {...(errors.confirmPassword && touched.confirmPassword
                  ? { error: true, helperText: errors.confirmPassword }
                  : '')}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              margin="25px 0 25px 0"
              sx={{ position: 'relative' }}
            >
              <Fab
                sx={{ backgroundColor: theme.palette.secondary.main }}
                variant="extended"
                aria-label="add"
                type="submit"
                disabled={loading ? true : false}
              >
                <Typography variant="button" paddingLeft="5px">
                  Enregistrer
                </Typography>
              </Fab>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Box>
          <LinkedBtn />
        </Box>
      </Paper>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={TransitionUp}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={createdMsg?.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {createdMsg?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default ResetPassword
