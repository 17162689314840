// Made by Poukam Ngamaleu
import { useAuth } from '../../utils/context'
import ClientHeader from './clientHeader'
import ProspectHeader from './prospectHeader'

function Header() {
  const {
    userInfo: { is_partner, is_student },
  } = useAuth()
  return is_student || is_partner ? <ClientHeader /> : <ProspectHeader />
}

export default Header
