// Made by Poukam Pierre

import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { Box, FormControlLabel, Radio, Typography } from '@mui/material'
import { Proposition } from '../../pages/testingPaper'

function PropositionComp({
  proposition: { proposition: propo, is_answer },
  proposition,
  is_selected,
  isSubmited,
  onSelect,
  correction,
}: {
  proposition: Proposition
  is_selected: boolean
  isSubmited: boolean
  onSelect: (proposition: Proposition) => void
  correction?: boolean
}) {
  return (
    <Box
      paddingLeft="20px"
      sx={{
        bgcolor:
          is_answer && isSubmited
            ? '#a9def0'
            : is_selected && isSubmited
            ? '#ffb3b1'
            : null,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: '20px',
      }}
    >
      <FormControlLabel
        value={propo}
        control={<Radio onClick={() => onSelect(proposition)} />}
        label={<Typography>{propo}</Typography>}
        disabled={!is_selected && isSubmited ? true : false}
        checked={
          correction ? (is_selected && isSubmited ? true : false) : undefined
        }
      />
      {is_answer && isSubmited ? (
        <CheckIcon
          sx={{
            color: '#1b7a9a',
          }}
        />
      ) : is_selected && isSubmited ? (
        <ClearIcon sx={{ color: '#ff0600' }} />
      ) : null}
    </Box>
  )
}

export default PropositionComp
