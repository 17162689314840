// Made by Poukam Ngamaleu

import * as yup from 'yup'

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Entrer une email valide')
    .required('Email nécéssaire'),
  password: yup
    .string()
    .min(5, 'minimum 5 caractères')
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/, {message: '1 magiscule, 1 minuscule et 1 chiffre'})
    .required('Mot de passe obligatoire'),
  stayConnected: yup.boolean(),
})
