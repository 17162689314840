// Made by Poukam Ngamaleu

import FooterActionCall from '../../components/footer/footerCallAction'
import Tests from '../../components/tests'
import WelcomMsg from '../../components/welcomMsg'

function Home() {
  return (
    <>
      <WelcomMsg />
      <Tests />
      <FooterActionCall />
    </>
  )
}

export default Home
