// Made by Poukam Ngamaleu

import {
  Alert,
  Fab,
  Slide,
  SlideProps,
  Snackbar,
  Typography,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { Box } from '@mui/system'
import Axios from 'axios'
import { useEffect, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { useParams } from 'react-router'
import { alertMsgInterface } from '../../components/contact/SellerInformations'
import QuestionExam from '../../components/questionsExam'
import { useAuth } from '../../utils/context'
import { theme } from '../../utils/style/theme'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExamTimer from './examTimer'
import { useOutletContext } from 'react-router-dom'

export interface Proposition {
  propositionId: string
  proposition: string
  is_answer: boolean // help know the true answer before answered
}

export interface question {
  questionId: number
  questionBody: string
  propositionAnswers: Proposition[]
  questionImg?: string
  feedback: string
  answerKey?: Proposition
}

type TransitionProps = Omit<SlideProps, 'direction'>

function TestingExam() {
  const {
    userInfo: { id },
  } = useAuth()
  const { id_epreuve } = useParams()
  const [questions, setQuestions] = useState<question[]>([])
  const [isSubmited, setIsSubmited] = useState<boolean>(false)
  const [answers, setAnswers] = useState<question[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [createdMsg, setCreatedMsg] = useState<alertMsgInterface>()
  const [marks, setMarks] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingFetchExam, setloadingFetchExam] = useState<boolean>(true)
  const [showButton, handScrollToTop] =
    useOutletContext<[boolean, () => void]>()
  useEffect(() => {
    setloadingFetchExam(true)
    // change local link to remote link
    Axios.get(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/student/getExamTest/${id_epreuve}`
    )
      .then((res) => {
        if (res?.status === 200 && res.data) {
          setloadingFetchExam(false)
          setQuestions(res.data)
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          return
        }
      })
    componentDidMount()
    return () => {
      componentWillUnmount()
    }
  }, [id_epreuve])

  let answeredQuestions = 0
  let totalMarks = 0

  answers.forEach(({ answerKey }) => {
    if (answerKey !== undefined) {
      answeredQuestions++
    }
  })

  const getAnswers = () => {
    setLoading(true)
    answers.forEach(({ propositionAnswers, answerKey }) => {
      if (answerKey !== undefined) {
        if (
          propositionAnswers.find(
            ({ propositionId, is_answer }) =>
              propositionId === answerKey.propositionId && is_answer
          ) !== undefined
        )
          totalMarks++
      }
    })
    Axios.post(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/student/saveCompositionInfos/${id_epreuve}`,
      { id: id, response: answers }
    )
      .then((res) => {
        if (res?.status === 201) {
          setCreatedMsg({
            message: res.data.message,
            severity: 'success',
          })
          setIsSubmited(true)
          setMarks(totalMarks)
          setLoading(false)
          setOpen(true)
          handScrollToTop()
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          return
        }
      })
  }

  // TODO update navigating condition

  useBeforeunload(() => 'Are you sure to close this tab?')

  function componentWillUnmount(): void {
    alert(
      "Attention!!! Les informations de l'épreuve en cours d'examen seront perdues. Aucune autre manoeuvre de récupération n'est encore possible."
    )
  }

  function componentDidMount(): void {
    alert(
      "Attention!!! Les informations de l'épreuve que vous composerez doivent être enregistrer avant tout tentative de sortie de cette page. Aucune autre manoeuvre de récupération n'est encore possible."
    )
  }

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />
  }

  return (
    <Box
      component="section"
      sx={{
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        padding: { xs: '15px', sm: 3 },
      }}
    >
      {loadingFetchExam ? (
        <CircularProgress
          color="secondary"
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      ) : (
        <Box sx={{ maxWidth: '50rem' }}>
          <Box
            sx={{
              bgcolor: '#F5F0F0',
              borderTop: `15px solid ${theme.palette.primary.main}`,
              borderRadius: '8px',
              padding: { xs: 2, sm: 3 },
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            <Typography
              sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
            >
              Note:{' '}
              {isSubmited ? (
                marks + '/40'
              ) : (
                <CircularProgress size={20} sx={{ marginLeft: '10px' }} />
              )}
            </Typography>
            <Typography
              sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
            >
              Temps: <ExamTimer isSubmited={isSubmited} />
            </Typography>
          </Box>
          <Box>
            {questions.map((question, index) => (
              <Box key={index} paddingTop="15px">
                <QuestionExam
                  questions={question}
                  key={index}
                  index={index}
                  updateAnswers={(question: question) => {
                    setAnswers([
                      ...answers.filter(
                        ({ questionId }) => questionId !== question.questionId
                      ),
                      question,
                    ])
                  }}
                  isSubmited={isSubmited}
                />
              </Box>
            ))}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            p={2}
            sx={{ alignItems: { xs: 'start', sm: 'center' } }}
          >
            <Box
              alignItems="center"
              gap="10px"
              sx={{
                left: { xs: '25px', sm: 'auto' },
                bottom: { xs: '25px', sm: 'auto' },
                display: { xs: 'none', sm: 'flex' },
              }}
            >
              <Typography color="#555">nombre restant</Typography>
              <Typography color="#555">{40 - answeredQuestions}</Typography>
              <CircularProgress
                size={20}
                sx={{ display: answeredQuestions < 25 ? 'initial' : 'none' }}
              />
            </Box>
            <Box sx={{ position: 'relative' }}>
              <Fab
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                }}
                variant="extended"
                onClick={() => {
                  getAnswers()
                }}
                disabled={
                  answeredQuestions < 25 || isSubmited || loading ? true : false
                }
              >
                <Typography variant="button" paddingLeft="5px">
                  VALIDER
                </Typography>
              </Fab>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Box>
          {showButton && (
            <Fab
              size="small"
              sx={{
                position: 'fixed',
                bottom: 50,
                right: 20,
              }}
              onClick={handScrollToTop}
            >
              <ExpandLessIcon />
            </Fab>
          )}
        </Box>
      )}
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={TransitionUp}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={createdMsg?.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {createdMsg?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default TestingExam
