// Made by Poukam Ngamaleu
// This sheet present how data from API review will appear.

import coatOfArm237 from '../asset/armoirieCmr.png'
import coatOfArmGab from '../asset/armoirieGabon.png'
import panel from '../asset/paneaux_test.png'

export interface Pays {
  paysId?: number
  label: string
  code: string
  coatOfArm: string
}

export interface Department {
  departmentId?: number
  name: string
  pays: Pays
}

export interface Session {
  sessionId?: number
  session: string
}

export interface CategoryOfLicence {
  catId?: number
  label: string
}

export interface Proposition {
  propositionId?: number
  proposition_fr: string
  proposition_en?: string
  correspondance: string
  is_answer: boolean
}

export interface Question {
  questionId?: number
  questionNumber: number
  questionBody_en: string
  questionBody_fr: string
  questionImg?: string
  propositionsAnswers: Proposition[]
  responseProps_fr: string
  responseProps_en: string
}

export interface Epreuve {
  epreuveId?: number
  session: Session
  department: Department
  category: CategoryOfLicence
  questions?: Question[]
  user_created: Utilisateur
  is_in_production: boolean
  creation_date: string
  last_modification?: string
}

export interface Utilisateur {
  userId?: number
  nom: string
  prenom: string | undefined
  photo?: string
  email: string
  motDePasse: string
  is_partner: boolean
  is_admin: boolean
  montant?: number
  code_preps?: string
}

export interface Abonnement {
  abonnementId?: number
  dateDeCreation: string
  numbre_Abonnement: number
  price: number
  code_preps?: string
  typeAbonnement: TypeAbonnement
  userAbonnement: Utilisateur
}
export interface TypeAbonnement {
  typeId?: number
  label: string
  price: number
}

export interface Payment {
  paymentId?: number
  price: number
  date_payment: string
  validation: boolean | undefined
  number_payment: number
  userPayment: Utilisateur
}
export interface Composition {
  compositionId?: number
  date_composition: string
  note: number
  epreuve: Epreuve
  abonnement: Abonnement
}

export interface Cat_Abonnement {
  cat_abonnementId?: number
  category: CategoryOfLicence
  abonnement: Abonnement
}

// Déclaration des valeur connues
export const countries: readonly Pays[] = [
  { code: 'CM', label: 'Cameroun', coatOfArm: coatOfArm237 },
  { code: 'GA', label: 'Gabon', coatOfArm: coatOfArmGab },
]

export const department: Department[] = [
  { departmentId: 1, name: 'Haute-Sanaga', pays: countries[1] },
  { departmentId: 2, name: 'Lekié', pays: countries[1] },
  { departmentId: 3, name: 'Mbam-et-Inoubou', pays: countries[1] },
  { departmentId: 4, name: 'Mbam-et-Kim', pays: countries[1] },
  { departmentId: 5, name: 'Méfou-et-Afamba', pays: countries[1] },
  { departmentId: 6, name: 'Méfou-et-Akono', pays: countries[1] },
  { departmentId: 7, name: 'Mfoundi', pays: countries[1] },
  { departmentId: 8, name: 'Nyong-et-Kellé', pays: countries[1] },
  { departmentId: 9, name: 'Nyong-et-Mfoumou', pays: countries[1] },
  { departmentId: 10, name: "Nyong-et-So'o", pays: countries[1] },
  { departmentId: 11, name: 'Diamaré', pays: countries[1] },
  { departmentId: 12, name: 'Logone-et-Chari', pays: countries[1] },
  { departmentId: 13, name: 'Mayo-Danay', pays: countries[1] },
  { departmentId: 14, name: 'Mayo-Kani', pays: countries[1] },
  { departmentId: 15, name: 'Mayo-Sava', pays: countries[1] },
  { departmentId: 16, name: 'Mayo-Tsanaga', pays: countries[1] },
  { departmentId: 17, name: 'Djérem', pays: countries[1] },
  { departmentId: 18, name: 'Faro-et-Déo', pays: countries[1] },
  { departmentId: 19, name: 'Mayo-Banyo', pays: countries[1] },
  { departmentId: 20, name: 'Mbéré', pays: countries[1] },
  { departmentId: 21, name: 'Vina', pays: countries[1] },
  { departmentId: 22, name: 'Boumba-et-Ngoko', pays: countries[1] },
  { departmentId: 23, name: 'Haut-Nyong', pays: countries[1] },
  { departmentId: 24, name: 'Kadey', pays: countries[1] },
  { departmentId: 25, name: 'Lom-et-Djérem', pays: countries[1] },
  { departmentId: 26, name: 'Moungo', pays: countries[1] },
  { departmentId: 27, name: 'Nkam', pays: countries[1] },
  { departmentId: 28, name: 'Sanaga-Maritime', pays: countries[1] },
  { departmentId: 29, name: 'Wouri', pays: countries[1] },
  { departmentId: 30, name: 'Bénoué', pays: countries[1] },
  { departmentId: 31, name: 'Faro', pays: countries[1] },
  { departmentId: 32, name: 'Mayo-Louti', pays: countries[1] },
  { departmentId: 33, name: 'Mayo-rey', pays: countries[1] },
  { departmentId: 34, name: 'Boyo', pays: countries[1] },
  { departmentId: 35, name: 'Bui', pays: countries[1] },
  { departmentId: 36, name: 'Donga-Mantung', pays: countries[1] },
  { departmentId: 37, name: 'Menchum', pays: countries[1] },
  { departmentId: 38, name: 'Mezam', pays: countries[1] },
  { departmentId: 39, name: 'Momo', pays: countries[1] },
  { departmentId: 40, name: 'Ngo-Ketunjia', pays: countries[1] },
  { departmentId: 41, name: 'Bamboutos', pays: countries[1] },
  { departmentId: 42, name: 'Haut-Nkam', pays: countries[1] },
  { departmentId: 43, name: 'Hauts-Plateaux', pays: countries[1] },
  { departmentId: 44, name: 'Koung-Khi', pays: countries[1] },
  { departmentId: 45, name: 'Menoua', pays: countries[1] },
  { departmentId: 46, name: 'Mifi', pays: countries[1] },
  { departmentId: 47, name: 'Ndé', pays: countries[1] },
  { departmentId: 48, name: 'Noun', pays: countries[1] },
  { departmentId: 49, name: 'Dja-et-Lobo', pays: countries[1] },
  { departmentId: 50, name: 'Mvila', pays: countries[1] },
  { departmentId: 51, name: 'Océan', pays: countries[1] },
  { departmentId: 52, name: 'Vallée-du-Ntem', pays: countries[1] },
  { departmentId: 53, name: 'Fako', pays: countries[1] },
  { departmentId: 54, name: 'Koupé-Manengouba', pays: countries[1] },
  { departmentId: 55, name: 'Lebialem', pays: countries[1] },
  { departmentId: 56, name: 'Manyu', pays: countries[1] },
  { departmentId: 57, name: 'Mene', pays: countries[1] },
  { departmentId: 58, name: 'Ndian', pays: countries[1] },
]

export const dataCategoryOfLicence: CategoryOfLicence[] = [
  { catId: 1, label: 'A' },
  { catId: 2, label: 'B' },
  { catId: 3, label: 'C' },
  { catId: 4, label: 'D' },
  { catId: 5, label: 'E' },
  { catId: 6, label: 'G' },
]

export const dataSession: Session[] = [
  { sessionId: 1, session: '29/01/2022' },
  { sessionId: 2, session: '12/03/2022' },
  { sessionId: 3, session: '30/04/2022' },
  { sessionId: 4, session: '11/06/2022' },
  { sessionId: 5, session: '30/07/2022' },
  { sessionId: 6, session: '10/09/2022' },
  { sessionId: 7, session: '29/10/2022' },
  { sessionId: 8, session: '10/12/2022' },
]

export const dataTypeAbonnement: TypeAbonnement[] = [
  { typeId: 1, label: 'NORMAL UNIQUE', price: 10250 },
  { typeId: 2, label: 'GOLD', price: 41000 },
]

// Data des différents utilisateurs
export const dataUsers: Utilisateur[] = [
  {
    userId: 1,
    nom: 'ngamaleu poukam',
    prenom: 'pierre irénée',
    email: 'ngamaleu2011@gmail.com',
    motDePasse: 'Ghost280693',
    is_partner: false,
    is_admin: true,
  },
  {
    userId: 2,
    nom: 'moualeu',
    prenom: 'fabrice',
    email: 'moualeufa@gmail.com',
    motDePasse: 'Mou210880',
    is_partner: false, // est un apprenant
    is_admin: false,
  },
  {
    userId: 3,
    nom: 'fimeni ngaleu',
    prenom: 'christelle gillice',
    email: 'ngaleufim@hotmail.fr',
    motDePasse: 'Fim030496',
    is_partner: true, //ne possède pas de compte apprenant
    is_admin: false,
    code_preps: 'NGA235',
  },
  {
    userId: 4,
    nom: 'poukam yometcheu',
    prenom: 'marc michelle',
    email: 'poukmarc@ymail.com',
    motDePasse: '17122020',
    is_partner: true, // possède un compte apprenant
    is_admin: false,
    montant: 2000,
    code_preps: 'POU220',
  },
]

// Data des différents abonnements
export const dataAbonnement: Abonnement[] = [
  {
    abonnementId: 1,
    numbre_Abonnement: 1042022125413,
    price: 10250,
    dateDeCreation: '20/02/2022',
    typeAbonnement: dataTypeAbonnement[0],
    userAbonnement: dataUsers[1],
    code_preps: 'NGA235',
  },
  {
    abonnementId: 2,
    numbre_Abonnement: 12042021153030,
    price: 41000,
    dateDeCreation: '23/07/2022',
    typeAbonnement: dataTypeAbonnement[1],
    userAbonnement: dataUsers[3],
    code_preps: 'NGA235',
  },
]

// Data cat_Abonnement
export const dataCat_Abonnement: Cat_Abonnement[] = [
  {
    cat_abonnementId: 1,
    category: dataCategoryOfLicence[1],
    abonnement: dataAbonnement[0],
  },
  {
    cat_abonnementId: 2,
    category: dataCategoryOfLicence[5],
    abonnement: dataAbonnement[1],
  },
]

// data épreuves
export const dataTests: Epreuve[] = [
  {
    epreuveId: 1,
    session: dataSession[1],
    department: department[0],
    category: dataCategoryOfLicence[1],
    user_created: dataUsers[0],
    questions: [
      {
        questionId: 1,
        questionNumber: 1,
        questionBody_fr:
          'Le genre de type de batterie utilisée dans la plupart des voitures pour démarrer un moteur est',
        questionBody_en:
          'The type of battery that is used in most cars to start an engine is',
        propositionsAnswers: [
          {
            propositionId: 1,
            proposition_fr: '12 volts',
            proposition_en: '12 volts',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 2,
            proposition_fr: '24 volts',
            proposition_en: '24 volts',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 3,
            proposition_fr: '36 volts',
            proposition_en: '36 volts',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `Les batteries utilisées par les voitures en général ont besoin d'une tension élevée pour fonctionner. La réponse a correspond à la tension utilisée pour les battéries de voiture de tourisme `,
        responseProps_en: `Batteries used by cars in general need a high voltage to operate. Answer 'a' is the voltage used for passenger car batteries`,
      },
      {
        questionId: 2,
        questionNumber: 2,
        questionBody_fr:
          'A quelle vitesse votre véhicule optimise-t-il son (rendement énergétique)',
        questionBody_en:
          'At what speed does your vehicle optimise its (fuel efficiency)',
        propositionsAnswers: [
          {
            propositionId: 4,
            proposition_fr: 'Vitesse réduite (moin de 30km/h)',
            proposition_en: 'Reduced speed (less than 30km/h)',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 5,
            proposition_fr: 'Vitesse moyenne (40 à 60 km/h)',
            proposition_en: 'Average speed (40 to 60 km/h)',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 6,
            proposition_fr: 'Vitesse élevée (60 à 100 km/h)',
            proposition_en: 'High speed (60 to 100 km/h)',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `Plus un véhicule roule à basse vitesse, plus sa consommation est basse. Nous optons pour la réponse 'a'`,
        responseProps_en: `The lower the speed of a vehicle, the lower its consumption. We opt for answer 'a'.`,
      },
      {
        questionId: 3,
        questionNumber: 3,
        questionBody_fr: 'Le stationnement est interdit sur:',
        questionBody_en: 'Parking is prohibited on:',
        propositionsAnswers: [
          {
            propositionId: 7,
            proposition_fr: 'Une voie à sens unique',
            proposition_en: 'A one-way street',
            correspondance: 'a',
            is_answer: false,
          },
          {
            propositionId: 8,
            proposition_fr: 'Les routes secondaires',
            proposition_en: 'Secondary roads',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 9,
            proposition_fr: 'Trottoirs et les feux tricolores',
            proposition_en: 'Pavements and traffic lights',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `Il est interdit de stationner sur des espaces amenagés pour d'autres usagers de la route. Egalement à des endroits où vous pouvez être génant pour les usagers de la route. Nous opton pour la solution 'c'`,
        responseProps_en: `It is not allowed to park in spaces designated for other road users. Also in places where you can be a nuisance to other road users. We opt for solution 'c`,
      },
      {
        questionId: 4,
        questionNumber: 4,
        questionBody_fr:
          'Choisissez les éléments qui ne sont pas obligatoires dans votre véhicule',
        questionBody_en:
          'Choose the items that are not required in your vehicle',
        propositionsAnswers: [
          {
            propositionId: 10,
            proposition_fr: 'La trousse de sécours',
            proposition_en: 'The first aid kit',
            correspondance: 'a',
            is_answer: false,
          },
          {
            propositionId: 11,
            proposition_fr: 'Au moins un triangle de signalisation',
            proposition_en: 'At least one warning triangle',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 12,
            proposition_fr: 'La torche',
            proposition_en: 'The torch',
            correspondance: 'c',
            is_answer: true,
          },
          {
            propositionId: 13,
            proposition_fr: 'Aucune des réponses',
            proposition_en: 'None of the answers',
            correspondance: 'd',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est belle et bien 'c'`,
        responseProps_en: `The answer is 'c'.`,
      },
      {
        questionId: 5,
        questionNumber: 5,
        questionBody_fr:
          "En cas d'accident en campagne, pour alerter la gendarmerie j'appelle",
        questionBody_en:
          'In case of an accident in the countryside, to alert the gendarmerie I call',
        questionImg: '',
        propositionsAnswers: [
          {
            propositionId: 14,
            proposition_fr: '17/117',
            proposition_en: '17/117',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 15,
            proposition_fr: '18/118',
            proposition_en: '18/118',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 16,
            proposition_fr: '13/113',
            proposition_en: '13/113',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is 'a'.`,
      },
      {
        questionId: 6,
        questionNumber: 6,
        questionBody_fr:
          "Est-ce que le véhicule d'urgence peut-il traverser le feux rouge?",
        questionBody_en: 'Can the emergency vehicle cross the red light?',
        propositionsAnswers: [
          {
            propositionId: 17,
            proposition_fr: 'Oui',
            proposition_en: 'Yes',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 18,
            proposition_fr: 'Non',
            proposition_en: 'No',
            correspondance: 'b',
            is_answer: false,
          },
        ],
        responseProps_fr: `Les véhicules d'urgences, lorsqu'ils sont en activités (sirène et girophare activés), ne respectent plus le code de la route. Ils en est de même pour les véhicules dit prioritaires. La réponse ici est 'a'`,
        responseProps_en: `Emergency vehicles, when they are in operation (siren and flashing light activated), no longer respect the highway code. The same applies to so-called priority vehicles. The answer here is 'a'.`,
      },
      {
        questionId: 7,
        questionNumber: 7,
        questionBody_fr:
          "Si un véhicule transportant de l'essence ou combustible de chauffage ou de l'acide, est impliqué dans un accident, vous devez:",
        questionBody_en:
          'If a vehicle carrying petrol or heating fuel or acid is involved in an accident, you must:',
        propositionsAnswers: [
          {
            propositionId: 19,
            proposition_fr: "Rester à bonne distance di lieu de l'accident",
            proposition_en: 'Keep a safe distance from the accident site',
            correspondance: 'a',
            is_answer: false,
          },
          {
            propositionId: 20,
            proposition_fr: 'Avertir les autres usagers de la route du danger',
            proposition_en: 'Warn other road users of the danger',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 21,
            proposition_fr:
              "Permettre aux services d'urgence d'apporter de l'assistance",
            proposition_en: 'Enabling emergency services to provide assistance',
            correspondance: 'c',
            is_answer: false,
          },
          {
            propositionId: 22,
            proposition_fr: 'Toutes les trois',
            proposition_en: 'All three',
            correspondance: 'd',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse ici est 'd'`,
        responseProps_en: `The answer here is 'd'`,
      },
      {
        questionId: 8,
        questionNumber: 8,
        questionBody_fr: 'Les marques sur la chaussé',
        questionBody_en: 'Pavement scarring',
        propositionsAnswers: [
          {
            propositionId: 23,
            proposition_fr:
              "Sont des indications que le conducteur n'est pas tenu de respecter",
            proposition_en:
              'The following are indications that the driver is not obliged to follow',
            correspondance: 'a',
            is_answer: false,
          },
          {
            propositionId: 24,
            proposition_fr:
              'Ont force de loi seulement si elles sont accompagnées de panneaux de signalisation',
            proposition_en:
              'Only have the force of law if accompanied by signs',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 25,
            proposition_fr:
              "Font partie de la signalisation, tout conducteur doit s'y conformer",
            proposition_en:
              'The following are part of the signs and all drivers must comply with them',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `Les marques sur la chaussé entre dans la signalisation verticale. Et en tant que signalisation routière, elle fait partie intégrante des différentes règles du code de la route. Nous optons pour la réponse 'c'`,
        responseProps_en: `Road markings are part of vertical signage. And as a road marking, it is an integral part of the various rules of the road code. We opt for answer 'c'.`,
      },
      {
        questionId: 9,
        questionNumber: 9,
        questionBody_fr:
          "En cas d'évacuation après un accident, laquelle des victimes est prioritaire:",
        questionBody_en:
          'In the event of evacuation after an accident, which of the victims has priority:',
        propositionsAnswers: [
          {
            propositionId: 26,
            proposition_fr:
              "Celle qui présente les signes d'hémorragie interne",
            proposition_en: 'The one with signs of internal bleeding',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 27,
            proposition_fr: 'Celle qui a plusieurs fractures',
            proposition_en: 'The one with several fractures',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 28,
            proposition_fr: 'Celle qui a une hémorragie externe',
            proposition_en: 'The one with external bleeding',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `Les causes de mortalité en cas d'accident de la route sont pour la plus part l'asphyxie et l'hémorragie. Par contre il existe deux types d'hémorragie (externe et interne). Celui interne nécéssite une évacuation sanitaire d'urgence. La réponse 'a' est la bonne`,
        responseProps_en: `The causes of death in road accidents are mostly asphyxia and haemorrhage. However, there are two types of haemorrhage (external and internal). The internal one requires emergency medical evacuation. Answer 'a' is the correct answer`,
      },
      {
        questionId: 10,
        questionNumber: 10,
        questionBody_fr:
          'Le bouchon du radiateur type à pression selon certains fabricants, doit être enlevé uniquement :',
        questionBody_en:
          'The pressure-type radiator cap, according to some manufacturers, should only be removed:',
        propositionsAnswers: [
          {
            propositionId: 29,
            proposition_fr: 'Lorsque le moteur est chaud',
            proposition_en: 'When the engine is hot',
            correspondance: 'a',
            is_answer: false,
          },
          {
            propositionId: 30,
            proposition_fr: 'Pour refroidir un moteur surchauffé',
            proposition_en: 'To cool an overheated engine',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 31,
            proposition_fr: 'Lorsque le moteur est froid',
            proposition_en: 'When the engine is cold',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'c'`,
        responseProps_en: `The answer is 'c'`,
      },
      {
        questionId: 11,
        questionNumber: 11,
        questionBody_fr:
          'Signaler votre intention ne vous donne pas le droit de priorité',
        questionBody_en:
          'Signalling your intention does not give you the right of priority',
        propositionsAnswers: [
          {
            propositionId: 32,
            proposition_fr: 'Vrai',
            proposition_en: 'true',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 33,
            proposition_fr: 'Faux',
            proposition_en: 'false',
            correspondance: 'b',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is 'a'`,
      },
      {
        questionId: 12,
        questionNumber: 12,
        questionBody_fr: "L'amélioration des véhicules 4 x 4 est pour :",
        questionBody_en: 'The improvement of 4 x 4 vehicles is for :',
        propositionsAnswers: [
          {
            propositionId: 34,
            proposition_fr: 'Son confort',
            proposition_en: 'Its convenience',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 35,
            proposition_fr: 'Son adhésion sur la route',
            proposition_en: 'Its adherence on the road',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 36,
            proposition_fr: "Sa capacité d'arrêt",
            proposition_en: 'Its ability to stop',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `L'amélioration du'un véhicule 4 x 4 est d'avantage pour son confort car se sont des véhicules conçus pour des routes extrèmes et très enclavées. Nous optons pour la réponse 'a'`,
        responseProps_en: `The improvement of a 4 x 4 vehicle is more for its comfort as these vehicles are designed for extreme and very enclosed roads. We opt for answer 'a'.`,
      },
      {
        questionId: 13,
        questionNumber: 13,
        questionBody_fr:
          'Que doit-on faire pour combattre la fatigue au volant ?',
        questionBody_en: 'What should be done to combat driver fatigue?',
        propositionsAnswers: [
          {
            propositionId: 37,
            proposition_fr: 'Engager une conversation animée',
            proposition_en: 'Engaging in lively conversation',
            correspondance: 'a',
            is_answer: false,
          },
          {
            propositionId: 38,
            proposition_fr: "Laisser pénétrer beaucoup d'air dans la voiture",
            proposition_en: 'Let a lot of air into the car',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 39,
            proposition_fr: "S'arrêter et se reposer",
            proposition_en: 'Stop and rest',
            correspondance: 'c',
            is_answer: true,
          },
          {
            propositionId: 40,
            proposition_fr: 'Tout se qui précéde',
            proposition_en: 'All of the above',
            correspondance: 'd',
            is_answer: false,
          },
        ],
        responseProps_fr: `Le seul remède de la fatigue au volant c'est le repos. Donc la réponse est 'c'`,
        responseProps_en: `The only cure for driving fatigue is rest. So the answer is 'c'`,
      },
      {
        questionId: 14,
        questionNumber: 14,
        questionBody_fr:
          'Peut-on circuler sur une propriété privé pour éviter de se conformer à une signalisation ?',
        questionBody_en:
          'Can you drive on private property to avoid complying with a sign ?',
        propositionsAnswers: [
          {
            propositionId: 41,
            proposition_fr: 'Non, la loi le défend',
            proposition_en: 'No, the law forbids it',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 41,
            proposition_fr: 'Oui, si cela accelère la circulation',
            proposition_en: 'Yes, if it speeds up traffic',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 42,
            proposition_fr: "Non, sauf s'il y a embouteillage",
            proposition_en: 'No, unless there is a traffic jam',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est la 'a'`,
        responseProps_en: `The answer is 'a'`,
      },
      {
        questionId: 15,
        questionNumber: 15,
        questionBody_fr:
          "Si deux véhicules s'approchent d'une intersection en même temps dans un angle droit, lequel devrait ceder le droit de passage ?",
        questionBody_en:
          'If two vehicles approach an intersection at the same time at a right angle, which one should yield the right of way ?',
        propositionsAnswers: [
          {
            propositionId: 43,
            proposition_fr: 'Le véhicule qui va plus vite',
            proposition_en: 'The vehicle that goes faster',
            correspondance: 'a',
            is_answer: false,
          },
          {
            propositionId: 44,
            proposition_fr: 'Le véhicule à droite',
            proposition_en: 'The vehicle on the right',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 45,
            proposition_fr: 'Le véhicule à gauche',
            proposition_en: 'The vehicle on the left',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La priorité à droite est une règle du code la route qui dans une intersection d'égale valeur vous demande de laisser passer tous les autres conducteurs venant de votre droite. Nous opton pour la réponse 'c'`,
        responseProps_en: `The right of way is a rule in the highway code that in an intersection of equal value requires you to give way to all other drivers coming from your right. We opt for answer 'c'`,
      },
      {
        questionId: 16,
        questionNumber: 16,
        questionBody_fr: "Il est illégal qu'un véhicule routier soit muni:",
        questionBody_en:
          'It is illegal for a road vehicle to be equipped with:',
        propositionsAnswers: [
          {
            proposition_fr: "D'un avertisseur sonore",
            proposition_en: 'A horn',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: 'De feux de recul',
            proposition_en: 'Reversing lights',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: "D'un détecteur de radar de vitesse",
            proposition_en: 'A speed camera detector',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'c'`,
        responseProps_en: `The answer is 'c'`,
      },
      {
        questionId: 17,
        questionNumber: 17,
        questionBody_fr:
          "L'arbre qui actionne l'ouverture et la fermeture des soupapes s'appelle:",
        questionBody_en:
          'The shaft that operates the opening and closing of the valves is called:',
        propositionsAnswers: [
          {
            proposition_fr: 'Le vilebrequin',
            proposition_en: 'The crank shaft',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: "L'arbre de transmission",
            proposition_en: 'The drive shaft',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: "L'arbre à cames",
            proposition_en: 'The cam shaft',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'c'`,
        responseProps_en: `The answer is'c'`,
      },
      {
        questionId: 18,
        questionNumber: 18,
        questionBody_fr:
          "Quels sont les effets de l'alcool sur l'organisme et le comportement de l'homme ?",
        questionBody_en:
          'What are the effects of alcohol on the human body and behaviour?',
        propositionsAnswers: [
          {
            proposition_fr:
              "L'alcool affaiblit la vue et ralentit les reflexes",
            proposition_en: 'Alcohol impairs eyesight and slows reflexes',
            correspondance: 'a',
            is_answer: true,
          },
          {
            proposition_fr:
              "L'alcool diminue graduellement la maitrise des nerfs et déprime",
            proposition_en:
              'Alcohol gradually decreases the control of the nerves and depresses',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: 'Les deux',
            proposition_en: 'Both',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is 'a'`,
      },
      {
        questionId: 19,
        questionNumber: 19,
        questionBody_fr:
          "Si la vitesse d'un véhicule est triplée, de combien de force d'impact serait-elle supérieur advenant une collision avec un autre objet ? ",
        questionBody_en:
          'If the speed of a vehicle is tripled, how much greater would the impact force be in the event of a collision with another object?',
        propositionsAnswers: [
          {
            proposition_fr: '9 fois',
            proposition_en: '9 times',
            correspondance: 'a',
            is_answer: true,
          },
          {
            proposition_fr: '3 fois',
            proposition_en: '3 times',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: '2 fois',
            proposition_en: '2 times',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is 'a'`,
      },
      {
        questionId: 20,
        questionNumber: 20,
        questionBody_fr:
          'Est classé en troisième classe la contrevention suivante',
        questionBody_en:
          'The following countervention is classified as third class',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Ouverture dangereuses des portières',
            proposition_en: 'Unsafe opening of doors',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: "Défaut d'indicateur de vitesse",
            proposition_en: 'Speedometer failure',
            correspondance: 'b',
            is_answer: true,
          },
          {
            proposition_fr: 'Non présentation du permis de conduire',
            proposition_en: 'Failure to present a driving licence',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'b'`,
        responseProps_en: `The answer is 'b'`,
      },
      {
        questionId: 21,
        questionNumber: 21,
        questionBody_fr: 'Que signifie ce panneau ?',
        questionBody_en: 'What does this sign mean?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: "Panneau de service général d'un hôtel",
            proposition_en: 'General service sign of a hotel',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: "Panneau de service général d'un hôspital",
            proposition_en: 'General service sign of a hospital',
            correspondance: 'b',
            is_answer: true,
          },
          {
            proposition_fr: 'Panneau routier Route H',
            proposition_en: 'Road sign Route H',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'b'`,
        responseProps_en: `The answer is 'b'`,
      },
      {
        questionId: 22,
        questionNumber: 22,
        questionBody_fr:
          'Dans cette intersection, le panneau cédez passage concerne les véhicules qui sont à : ',
        questionBody_en:
          'In this intersection, the yield sign concerns vehicles that are at : ',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Droite',
            proposition_en: 'Right',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: 'Les deux',
            proposition_en: 'Both',
            correspondance: 'b',
            is_answer: true,
          },
          {
            proposition_fr: 'Gauche',
            proposition_en: 'Left',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `Selon l'image, le panneau cédez passage (J2) est mis des deux côté de la route. Nous concluons alors que la réponse 'b' est la bonne`,
        responseProps_en: `According to the picture, the yield sign (J2) is placed on both sides of the road. We conclude that answer 'b' is the correct answer`,
      },
      {
        questionId: 23,
        questionNumber: 23,
        questionBody_fr: 'Que signifie ce panneau ?',
        questionBody_en: 'What does this sign mean?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Passage à niveau',
            proposition_en: 'Level crossing',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: 'Intersection en T',
            proposition_en: 'T-intersection',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: 'Aucune',
            proposition_en: 'None',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'c'`,
        responseProps_en: `The answer is 'c'`,
      },
      {
        questionId: 24,
        questionNumber: 24,
        questionBody_fr:
          "En cas d'accident la balisage de la route permet de :",
        questionBody_en:
          'In the event of an accident, the marking of the road allows :',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Rendre la route plus libre',
            proposition_en: 'Making the road more free',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: 'Tracter les véhicules',
            proposition_en: 'Towing of vehicles',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: "Eviter qu'un autre accident se produise",
            proposition_en: 'Prevent another accident from happening',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `Lors d'un accident, il faut appliquer la règle PAS(Protéger, Alerter, Sécourir). Protéger consiste à baliser les lieu pour éviter d'autre accident causé par celui où vous intervenez. Nous optons pour la réponse 'c'`,
        responseProps_en: `In the event of an accident, the PAS (Protect, Alert, Rescue) rule must be applied. Protecting means marking out the area to avoid further accidents caused by the one you are working on. We opt for the answer 'c'`,
      },
      {
        questionId: 25,
        questionNumber: 25,
        questionBody_fr:
          'Image du panneau de danger (circulation dans les doubles sens)',
        questionBody_en: 'Image of the danger sign (two-way traffic)',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Le trafic que vous approchez est prioritaire',
            proposition_en: 'The traffic you are approaching has priority',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: 'Route a double sens de circulation',
            proposition_en: 'Two-way road',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: 'Aucune',
            proposition_en: 'None',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'c'`,
        responseProps_en: `The answer is 'c'`,
      },
      {
        questionId: 26,
        questionNumber: 26,
        questionBody_fr:
          "En sécourisme cette victime d'un accident est placé en position de choque",
        questionBody_en:
          'In First Aid this accident victim is placed in a shock position',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Vrai',
            proposition_en: 'True',
            correspondance: 'a',
            is_answer: true,
          },
          {
            proposition_fr: 'Faux',
            proposition_en: 'False',
            correspondance: 'b',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is 'a'`,
      },
      {
        questionId: 27,
        questionNumber: 27,
        questionBody_fr: 'Ce marquage sur la route apparaît juste avant',
        questionBody_en: 'This road marking appears just before',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: "Un panneau d'arrêt",
            proposition_en: 'A stop sign',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: 'Un panneau cédez passage',
            proposition_en: 'A give way sign',
            correspondance: 'b',
            is_answer: true,
          },
          {
            proposition_fr: 'Aucun panneau de signalisation',
            proposition_en: 'No road signs',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `Chaque signalisation verticale est matérialisé au sol par un marquage horizontal.Cette image correspond au marquage horizontal de la balise J2. Nous opton pour la réponse 'b'`,
        responseProps_en: `Each vertical sign is marked on the ground with a horizontal marking. This image corresponds to the horizontal marking of the J2 beacon. We opt for answer 'b'`,
      },
      {
        questionId: 28,
        questionNumber: 28,
        questionBody_fr: "Ce panonceau d'indicatoin diverse me rappelle que :",
        questionBody_en: 'This miscellaneous sign reminds me that :',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr:
              'La vitesse est limitée à 50 km/h avant le panneau uniquement',
            proposition_en: 'The speed limit is 50 km/h before the sign only',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr:
              'La vitesse est limitée à 50 km/h après le panneau uniquement',
            proposition_en: 'The speed limit is 50 km/h after the sign only',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr:
              'La vitesse est limitée à 50km/h avant et après le panneau',
            proposition_en:
              'The speed limit is 50km/h before and after the sign',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'c'`,
        responseProps_en: `The answer is 'c'`,
      },
      {
        questionId: 29,
        questionNumber: 29,
        questionBody_fr: 'Quelle est la signification de ce panneau?',
        questionBody_en: 'What is the significance of this sign?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Fin de zone de routes urbaines',
            proposition_en:
              'What is the significance of this sign for the urban road zone?',
            correspondance: 'a',
            is_answer: true,
          },
          {
            proposition_fr: 'A partir de là, il est interdit de stationner',
            proposition_en: 'From this point on, it is forbidden to park',
            correspondance: 'b',
            is_answer: true,
          },
          {
            proposition_fr: 'A partir de là, le stationnement est autorisé',
            proposition_en: 'From this point on, parking is allowed',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est  'b'`,
        responseProps_en: `The answer is 'b'`,
      },
      {
        questionId: 30,
        questionNumber: 30,
        questionBody_fr:
          'Selon ce panneau, une moto est autorisée à doubler un véhicule de passagers privé uniquement que pendant la journée',
        questionBody_en:
          'According to this sign, a motorbike is only allowed to overtake a private passenger vehicle during the day',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Non',
            proposition_en: 'No',
            correspondance: 'a',
            is_answer: true,
          },
          {
            proposition_fr: 'Oui',
            proposition_en: 'Yes',
            correspondance: 'b',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is 'a'`,
      },
      {
        questionId: 31,
        questionNumber: 31,
        questionBody_fr:
          "Quel panneau placé sur la route, informe d'un changement du nombre de voies sur la chaussée ?",
        questionBody_en:
          'Which sign on the road informs of a change in the number of lanes on the road?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Tous les quatre panneaux',
            proposition_en: 'All four panels',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: '622',
            proposition_en: '622',
            correspondance: 'b',
            is_answer: true,
          },
          {
            proposition_fr: '109',
            proposition_en: '109',
            correspondance: 'c',
            is_answer: false,
          },
          {
            proposition_fr: '145',
            proposition_en: '145',
            correspondance: 'd',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'b'`,
        responseProps_en: `The answer is 'b'`,
      },
      {
        questionId: 32,
        questionNumber: 32,
        questionBody_fr:
          'Quel panneau prévient que la chaussée où vous voulez roulez rejoint une autre chaussée ?',
        questionBody_en:
          'Which sign warns that the road where you want to drive meets another road?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: '620',
            proposition_en: '620',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: '145',
            proposition_en: '145',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: '307',
            proposition_en: '307',
            correspondance: 'c',
            is_answer: false,
          },
          {
            proposition_fr: '124',
            proposition_en: '124',
            correspondance: 'd',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'd'`,
        responseProps_en: `The answer is 'd'`,
      },
      {
        questionId: 33,
        questionNumber: 33,
        questionBody_fr:
          'Temoin indicateur de dysfonctionnement du contrôle de la traction',
        questionBody_en: 'Traction control malfunction indicator',
        questionImg: panel,
        propositionsAnswers: [
          { proposition_fr: '1', correspondance: 'a', is_answer: false },
          { proposition_fr: '2', correspondance: 'b', is_answer: false },
          { proposition_fr: '3', correspondance: 'c', is_answer: true },
          { proposition_fr: '4', correspondance: 'd', is_answer: false },
          { proposition_fr: '5', correspondance: 'e', is_answer: false },
          { proposition_fr: '6', correspondance: 'f', is_answer: false },
          { proposition_fr: '7', correspondance: 'g', is_answer: false },
          { proposition_fr: '8', correspondance: 'h', is_answer: false },
          { proposition_fr: '9', correspondance: 'i', is_answer: false },
          { proposition_fr: '10', correspondance: 'j', is_answer: false },
        ],
        responseProps_fr: `La réponse est le c`,
        responseProps_en: `The answer is c`,
      },
      {
        questionId: 34,
        questionNumber: 34,
        questionBody_fr: 'Indicateur de liquide de lave-glace',
        questionBody_en: 'Washer fluid indicator',
        questionImg: panel,
        propositionsAnswers: [
          { proposition_fr: '1', correspondance: 'a', is_answer: false },
          { proposition_fr: '2', correspondance: 'b', is_answer: false },
          { proposition_fr: '3', correspondance: 'c', is_answer: false },
          { proposition_fr: '4', correspondance: 'd', is_answer: true },
          { proposition_fr: '5', correspondance: 'e', is_answer: false },
          { proposition_fr: '6', correspondance: 'f', is_answer: false },
          { proposition_fr: '7', correspondance: 'g', is_answer: false },
          { proposition_fr: '8', correspondance: 'h', is_answer: false },
          { proposition_fr: '9', correspondance: 'i', is_answer: false },
          { proposition_fr: '10', correspondance: 'j', is_answer: false },
        ],
        responseProps_fr: `La réponse est le d`,
        responseProps_en: `The answer is d`,
      },
      {
        questionId: 35,
        questionNumber: 35,
        questionBody_fr:
          'Témoin indicateur du correcteur de site des projecteurs',
        questionBody_en: 'Headlamp levelling indicator lamp',
        questionImg: panel,
        propositionsAnswers: [
          { proposition_fr: '1', correspondance: 'a', is_answer: false },
          { proposition_fr: '2', correspondance: 'b', is_answer: false },
          { proposition_fr: '3', correspondance: 'c', is_answer: false },
          { proposition_fr: '4', correspondance: 'd', is_answer: false },
          { proposition_fr: '5', correspondance: 'e', is_answer: false },
          { proposition_fr: '6', correspondance: 'f', is_answer: true },
          { proposition_fr: '7', correspondance: 'g', is_answer: false },
          { proposition_fr: '8', correspondance: 'h', is_answer: false },
          { proposition_fr: '9', correspondance: 'i', is_answer: false },
          { proposition_fr: '10', correspondance: 'j', is_answer: false },
        ],
        responseProps_fr: `La réponse est le f`,
        responseProps_en: `The answer is  f`,
      },
      {
        questionId: 36,
        questionNumber: 36,
        questionBody_fr: 'Indicateur du système de verouillage automatique',
        questionBody_en: 'Automatic locking system indicator',
        questionImg: panel,
        propositionsAnswers: [
          { proposition_fr: '1', correspondance: 'a', is_answer: false },
          { proposition_fr: '2', correspondance: 'b', is_answer: false },
          { proposition_fr: '3', correspondance: 'c', is_answer: false },
          { proposition_fr: '4', correspondance: 'd', is_answer: false },
          { proposition_fr: '5', correspondance: 'e', is_answer: false },
          { proposition_fr: '6', correspondance: 'f', is_answer: false },
          { proposition_fr: '7', correspondance: 'g', is_answer: false },
          { proposition_fr: '8', correspondance: 'h', is_answer: false },
          { proposition_fr: '9', correspondance: 'i', is_answer: true },
          { proposition_fr: '10', correspondance: 'j', is_answer: false },
        ],
        responseProps_fr: `La réponse est le i`,
        responseProps_en: `The answer is  i`,
      },
      {
        questionId: 37,
        questionNumber: 37,
        questionBody_fr: 'Que signifie ce panneau',
        questionBody_en: 'What does this sign mean',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Route inondée',
            proposition_en: 'Flooded road',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: 'Falaise',
            proposition_en: 'Cliff',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: 'Aucune',
            proposition_en: 'None',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'c'`,
        responseProps_en: `The answer is 'c'`,
      },
      {
        questionId: 38,
        questionNumber: 38,
        questionBody_fr: 'Où aprecevriez-vous ce marquage routier ?',
        questionBody_en: 'Where would you get this road marking?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: "Au niveau d'une intersection",
            proposition_en: 'At an intersection',
            correspondance: 'a',
            is_answer: true,
          },
          {
            proposition_fr: "Sur une bretelle d'autoroute",
            proposition_en: 'On a motorway slip road',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: 'Sur une partie de la route surélevée',
            proposition_en: 'On a part of the elevated road',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is  'a'`,
      },
      {
        questionId: 39,
        questionNumber: 39,
        questionBody_fr:
          'Vous êtes à une intersection en T. Un véhicule vient de a gauche avec son clignotant droit activé. Que devrez vous faire?',
        questionBody_en:
          'You are at a T-junction. A vehicle is coming from the left with its right-hand indicator on. What should you do?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Sortir rapidement',
            proposition_en: 'Get out quickly',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr:
              "Attendre jusqu'à ce que le véhicule commence à tourner",
            proposition_en: 'Wait until the vehicle starts to turn',
            correspondance: 'b',
            is_answer: true,
          },
          {
            proposition_fr: 'Aucune',
            proposition_en: 'None',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'b'`,
        responseProps_en: `The answer is 'b'`,
      },
      {
        questionId: 40,
        questionNumber: 40,
        questionBody_fr:
          "Quelle est la forme du panneau d'arrêt à une intersection?",
        questionBody_en:
          'What is the shape of the stop sign at an intersection?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Forme A',
            proposition_en: 'Form A',
            correspondance: 'a',
            is_answer: true,
          },
          {
            proposition_fr: 'Forme B',
            proposition_en: 'Form A',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: 'Forme C',
            proposition_en: 'Form A',
            correspondance: 'c',
            is_answer: false,
          },
          {
            proposition_fr: 'Forme D',
            proposition_en: 'Form A',
            correspondance: 'd',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is  'a'`,
      },
    ],
    creation_date: '10/03/2021',
    is_in_production: true,
  },
  {
    epreuveId: 2,
    session: dataSession[1],
    department: department[0],
    category: dataCategoryOfLicence[1],
    user_created: dataUsers[0],
    questions: [
      {
        questionId: 41,
        questionNumber: 1,
        questionBody_fr:
          'Le genre de type de batterie utilisée dans la plupart des voitures pour démarrer un moteur est',
        questionBody_en:
          'The type of battery that is used in most cars to start an engine is',
        propositionsAnswers: [
          {
            propositionId: 1,
            proposition_fr: '12 volts',
            proposition_en: '12 volts',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 2,
            proposition_fr: '24 volts',
            proposition_en: '24 volts',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 3,
            proposition_fr: '36 volts',
            proposition_en: '36 volts',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `Les batteries utilisées par les voitures en général ont besoin d'une tension élevée pour fonctionner. La réponse a correspond à la tension utilisée pour les battéries de voiture de tourisme `,
        responseProps_en: `Batteries used by cars in general need a high voltage to operate. Answer 'a' is the voltage used for passenger car batteries`,
      },
      {
        questionId: 42,
        questionNumber: 2,
        questionBody_fr:
          'A quelle vitesse votre véhicule optimise-t-il son (rendement énergétique)',
        questionBody_en:
          'At what speed does your vehicle optimise its (fuel efficiency)',
        propositionsAnswers: [
          {
            propositionId: 4,
            proposition_fr: 'Vitesse réduite (moin de 30km/h)',
            proposition_en: 'Reduced speed (less than 30km/h)',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 5,
            proposition_fr: 'Vitesse moyenne (40 à 60 km/h)',
            proposition_en: 'Average speed (40 to 60 km/h)',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 6,
            proposition_fr: 'Vitesse élevée (60 à 100 km/h)',
            proposition_en: 'High speed (60 to 100 km/h)',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `Plus un véhicule roule à basse vitesse, plus sa consommation est basse. Nous optons pour la réponse 'a'`,
        responseProps_en: `The lower the speed of a vehicle, the lower its consumption. We opt for answer 'a'.`,
      },
      {
        questionId: 43,
        questionNumber: 3,
        questionBody_fr: 'Le stationnement est interdit sur:',
        questionBody_en: 'Parking is prohibited on:',
        propositionsAnswers: [
          {
            propositionId: 7,
            proposition_fr: 'Une voie à sens unique',
            proposition_en: 'A one-way street',
            correspondance: 'a',
            is_answer: false,
          },
          {
            propositionId: 8,
            proposition_fr: 'Les routes secondaires',
            proposition_en: 'Secondary roads',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 9,
            proposition_fr: 'Trottoirs et les feux tricolores',
            proposition_en: 'Pavements and traffic lights',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `Il est interdit de stationner sur des espaces amenagés pour d'autres usagers de la route. Egalement à des endroits où vous pouvez être génant pour les usagers de la route. Nous opton pour la solution 'c'`,
        responseProps_en: `It is not allowed to park in spaces designated for other road users. Also in places where you can be a nuisance to other road users. We opt for solution 'c`,
      },
      {
        questionId: 44,
        questionNumber: 4,
        questionBody_fr:
          'Choisissez les éléments qui ne sont pas obligatoires dans votre véhicule',
        questionBody_en:
          'Choose the items that are not required in your vehicle',
        propositionsAnswers: [
          {
            propositionId: 10,
            proposition_fr: 'La trousse de sécours',
            proposition_en: 'The first aid kit',
            correspondance: 'a',
            is_answer: false,
          },
          {
            propositionId: 11,
            proposition_fr: 'Au moins un triangle de signalisation',
            proposition_en: 'At least one warning triangle',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 12,
            proposition_fr: 'La torche',
            proposition_en: 'The torch',
            correspondance: 'c',
            is_answer: true,
          },
          {
            propositionId: 13,
            proposition_fr: 'Aucune des réponses',
            proposition_en: 'None of the answers',
            correspondance: 'd',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est belle et bien 'c'`,
        responseProps_en: `The answer is 'c'.`,
      },
      {
        questionId: 45,
        questionNumber: 5,
        questionBody_fr:
          "En cas d'accident en campagne, pour alerter la gendarmerie j'appelle",
        questionBody_en:
          'In case of an accident in the countryside, to alert the gendarmerie I call',
        questionImg: '',
        propositionsAnswers: [
          {
            propositionId: 14,
            proposition_fr: '17/117',
            proposition_en: '17/117',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 15,
            proposition_fr: '18/118',
            proposition_en: '18/118',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 16,
            proposition_fr: '13/113',
            proposition_en: '13/113',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is 'a'.`,
      },
      {
        questionId: 46,
        questionNumber: 6,
        questionBody_fr:
          "Est-ce que le véhicule d'urgence peut-il traverser le feux rouge?",
        questionBody_en: 'Can the emergency vehicle cross the red light?',
        propositionsAnswers: [
          {
            propositionId: 17,
            proposition_fr: 'Oui',
            proposition_en: 'Yes',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 18,
            proposition_fr: 'Non',
            proposition_en: 'No',
            correspondance: 'b',
            is_answer: false,
          },
        ],
        responseProps_fr: `Les véhicules d'urgences, lorsqu'ils sont en activités (sirène et girophare activés), ne respectent plus le code de la route. Ils en est de même pour les véhicules dit prioritaires. La réponse ici est 'a'`,
        responseProps_en: `Emergency vehicles, when they are in operation (siren and flashing light activated), no longer respect the highway code. The same applies to so-called priority vehicles. The answer here is 'a'.`,
      },
      {
        questionId: 47,
        questionNumber: 7,
        questionBody_fr:
          "Si un véhicule transportant de l'essence ou combustible de chauffage ou de l'acide, est impliqué dans un accident, vous devez:",
        questionBody_en:
          'If a vehicle carrying petrol or heating fuel or acid is involved in an accident, you must:',
        propositionsAnswers: [
          {
            propositionId: 19,
            proposition_fr: "Rester à bonne distance di lieu de l'accident",
            proposition_en: 'Keep a safe distance from the accident site',
            correspondance: 'a',
            is_answer: false,
          },
          {
            propositionId: 20,
            proposition_fr: 'Avertir les autres usagers de la route du danger',
            proposition_en: 'Warn other road users of the danger',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 21,
            proposition_fr:
              "Permettre aux services d'urgence d'apporter de l'assistance",
            proposition_en: 'Enabling emergency services to provide assistance',
            correspondance: 'c',
            is_answer: false,
          },
          {
            propositionId: 22,
            proposition_fr: 'Toutes les trois',
            proposition_en: 'All three',
            correspondance: 'd',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse ici est 'd'`,
        responseProps_en: `The answer here is 'd'`,
      },
      {
        questionId: 48,
        questionNumber: 8,
        questionBody_fr: 'Les marques sur la chaussé',
        questionBody_en: 'Pavement scarring',
        propositionsAnswers: [
          {
            propositionId: 23,
            proposition_fr:
              "Sont des indications que le conducteur n'est pas tenu de respecter",
            proposition_en:
              'The following are indications that the driver is not obliged to follow',
            correspondance: 'a',
            is_answer: false,
          },
          {
            propositionId: 24,
            proposition_fr:
              'Ont force de loi seulement si elles sont accompagnées de panneaux de signalisation',
            proposition_en:
              'Only have the force of law if accompanied by signs',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 25,
            proposition_fr:
              "Font partie de la signalisation, tout conducteur doit s'y conformer",
            proposition_en:
              'The following are part of the signs and all drivers must comply with them',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `Les marques sur la chaussé entre dans la signalisation verticale. Et en tant que signalisation routière, elle fait partie intégrante des différentes règles du code de la route. Nous optons pour la réponse 'c'`,
        responseProps_en: `Road markings are part of vertical signage. And as a road marking, it is an integral part of the various rules of the road code. We opt for answer 'c'.`,
      },
      {
        questionId: 49,
        questionNumber: 9,
        questionBody_fr:
          "En cas d'évacuation après un accident, laquelle des victimes est prioritaire:",
        questionBody_en:
          'In the event of evacuation after an accident, which of the victims has priority:',
        propositionsAnswers: [
          {
            propositionId: 26,
            proposition_fr:
              "Celle qui présente les signes d'hémorragie interne",
            proposition_en: 'The one with signs of internal bleeding',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 27,
            proposition_fr: 'Celle qui a plusieurs fractures',
            proposition_en: 'The one with several fractures',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 28,
            proposition_fr: 'Celle qui a une hémorragie externe',
            proposition_en: 'The one with external bleeding',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `Les causes de mortalité en cas d'accident de la route sont pour la plus part l'asphyxie et l'hémorragie. Par contre il existe deux types d'hémorragie (externe et interne). Celui interne nécéssite une évacuation sanitaire d'urgence. La réponse 'a' est la bonne`,
        responseProps_en: `The causes of death in road accidents are mostly asphyxia and haemorrhage. However, there are two types of haemorrhage (external and internal). The internal one requires emergency medical evacuation. Answer 'a' is the correct answer`,
      },
      {
        questionId: 50,
        questionNumber: 10,
        questionBody_fr:
          'Le bouchon du radiateur type à pression selon certains fabricants, doit être enlevé uniquement :',
        questionBody_en:
          'The pressure-type radiator cap, according to some manufacturers, should only be removed:',
        propositionsAnswers: [
          {
            propositionId: 29,
            proposition_fr: 'Lorsque le moteur est chaud',
            proposition_en: 'When the engine is hot',
            correspondance: 'a',
            is_answer: false,
          },
          {
            propositionId: 30,
            proposition_fr: 'Pour refroidir un moteur surchauffé',
            proposition_en: 'To cool an overheated engine',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 31,
            proposition_fr: 'Lorsque le moteur est froid',
            proposition_en: 'When the engine is cold',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'c'`,
        responseProps_en: `The answer is 'c'`,
      },
      {
        questionId: 51,
        questionNumber: 11,
        questionBody_fr:
          'Signaler votre intention ne vous donne pas le droit de priorité',
        questionBody_en:
          'Signalling your intention does not give you the right of priority',
        propositionsAnswers: [
          {
            propositionId: 32,
            proposition_fr: 'Vrai',
            proposition_en: 'true',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 33,
            proposition_fr: 'Faux',
            proposition_en: 'false',
            correspondance: 'b',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is 'a'`,
      },
      {
        questionId: 52,
        questionNumber: 12,
        questionBody_fr: "L'amélioration des véhicules 4 x 4 est pour :",
        questionBody_en: 'The improvement of 4 x 4 vehicles is for :',
        propositionsAnswers: [
          {
            propositionId: 34,
            proposition_fr: 'Son confort',
            proposition_en: 'Its convenience',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 35,
            proposition_fr: 'Son adhésion sur la route',
            proposition_en: 'Its adherence on the road',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 36,
            proposition_fr: "Sa capacité d'arrêt",
            proposition_en: 'Its ability to stop',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `L'amélioration du'un véhicule 4 x 4 est d'avantage pour son confort car se sont des véhicules conçus pour des routes extrèmes et très enclavées. Nous optons pour la réponse 'a'`,
        responseProps_en: `The improvement of a 4 x 4 vehicle is more for its comfort as these vehicles are designed for extreme and very enclosed roads. We opt for answer 'a'.`,
      },
      {
        questionId: 13,
        questionNumber: 53,
        questionBody_fr:
          'Que doit-on faire pour combattre la fatigue au volant ?',
        questionBody_en: 'What should be done to combat driver fatigue?',
        propositionsAnswers: [
          {
            propositionId: 37,
            proposition_fr: 'Engager une conversation animée',
            proposition_en: 'Engaging in lively conversation',
            correspondance: 'a',
            is_answer: false,
          },
          {
            propositionId: 38,
            proposition_fr: "Laisser pénétrer beaucoup d'air dans la voiture",
            proposition_en: 'Let a lot of air into the car',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 39,
            proposition_fr: "S'arrêter et se reposer",
            proposition_en: 'Stop and rest',
            correspondance: 'c',
            is_answer: true,
          },
          {
            propositionId: 40,
            proposition_fr: 'Tout se qui précéde',
            proposition_en: 'All of the above',
            correspondance: 'd',
            is_answer: false,
          },
        ],
        responseProps_fr: `Le seul remède de la fatigue au volant c'est le repos. Donc la réponse est 'c'`,
        responseProps_en: `The only cure for driving fatigue is rest. So the answer is 'c'`,
      },
      {
        questionId: 54,
        questionNumber: 14,
        questionBody_fr:
          'Peut-on circuler sur une propriété privé pour éviter de se conformer à une signalisation ?',
        questionBody_en:
          'Can you drive on private property to avoid complying with a sign ?',
        propositionsAnswers: [
          {
            propositionId: 41,
            proposition_fr: 'Non, la loi le défend',
            proposition_en: 'No, the law forbids it',
            correspondance: 'a',
            is_answer: true,
          },
          {
            propositionId: 41,
            proposition_fr: 'Oui, si cela accelère la circulation',
            proposition_en: 'Yes, if it speeds up traffic',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 42,
            proposition_fr: "Non, sauf s'il y a embouteillage",
            proposition_en: 'No, unless there is a traffic jam',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est la 'a'`,
        responseProps_en: `The answer is 'a'`,
      },
      {
        questionId: 55,
        questionNumber: 15,
        questionBody_fr:
          "Si deux véhicules s'approchent d'une intersection en même temps dans un angle droit, lequel devrait ceder le droit de passage ?",
        questionBody_en:
          'If two vehicles approach an intersection at the same time at a right angle, which one should yield the right of way ?',
        propositionsAnswers: [
          {
            propositionId: 43,
            proposition_fr: 'Le véhicule qui va plus vite',
            proposition_en: 'The vehicle that goes faster',
            correspondance: 'a',
            is_answer: false,
          },
          {
            propositionId: 44,
            proposition_fr: 'Le véhicule à droite',
            proposition_en: 'The vehicle on the right',
            correspondance: 'b',
            is_answer: false,
          },
          {
            propositionId: 45,
            proposition_fr: 'Le véhicule à gauche',
            proposition_en: 'The vehicle on the left',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La priorité à droite est une règle du code la route qui dans une intersection d'égale valeur vous demande de laisser passer tous les autres conducteurs venant de votre droite. Nous opton pour la réponse 'c'`,
        responseProps_en: `The right of way is a rule in the highway code that in an intersection of equal value requires you to give way to all other drivers coming from your right. We opt for answer 'c'`,
      },
      {
        questionId: 56,
        questionNumber: 16,
        questionBody_fr: "Il est illégal qu'un véhicule routier soit muni:",
        questionBody_en:
          'It is illegal for a road vehicle to be equipped with:',
        propositionsAnswers: [
          {
            proposition_fr: "D'un avertisseur sonore",
            proposition_en: 'A horn',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: 'De feux de recul',
            proposition_en: 'Reversing lights',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: "D'un détecteur de radar de vitesse",
            proposition_en: 'A speed camera detector',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'c'`,
        responseProps_en: `The answer is 'c'`,
      },
      {
        questionId: 57,
        questionNumber: 17,
        questionBody_fr:
          "L'arbre qui actionne l'ouverture et la fermeture des soupapes s'appelle:",
        questionBody_en:
          'The shaft that operates the opening and closing of the valves is called:',
        propositionsAnswers: [
          {
            proposition_fr: 'Le vilebrequin',
            proposition_en: 'The crank shaft',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: "L'arbre de transmission",
            proposition_en: 'The drive shaft',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: "L'arbre à cames",
            proposition_en: 'The cam shaft',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'c'`,
        responseProps_en: `The answer is'c'`,
      },
      {
        questionId: 58,
        questionNumber: 18,
        questionBody_fr:
          "Quels sont les effets de l'alcool sur l'organisme et le comportement de l'homme ?",
        questionBody_en:
          'What are the effects of alcohol on the human body and behaviour?',
        propositionsAnswers: [
          {
            proposition_fr:
              "L'alcool affaiblit la vue et ralentit les reflexes",
            proposition_en: 'Alcohol impairs eyesight and slows reflexes',
            correspondance: 'a',
            is_answer: true,
          },
          {
            proposition_fr:
              "L'alcool diminue graduellement la maitrise des nerfs et déprime",
            proposition_en:
              'Alcohol gradually decreases the control of the nerves and depresses',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: 'Les deux',
            proposition_en: 'Both',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is 'a'`,
      },
      {
        questionId: 59,
        questionNumber: 19,
        questionBody_fr:
          "Si la vitesse d'un véhicule est triplée, de combien de force d'impact serait-elle supérieur advenant une collision avec un autre objet ? ",
        questionBody_en:
          'If the speed of a vehicle is tripled, how much greater would the impact force be in the event of a collision with another object?',
        propositionsAnswers: [
          {
            proposition_fr: '9 fois',
            proposition_en: '9 times',
            correspondance: 'a',
            is_answer: true,
          },
          {
            proposition_fr: '3 fois',
            proposition_en: '3 times',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: '2 fois',
            proposition_en: '2 times',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is 'a'`,
      },
      {
        questionId: 60,
        questionNumber: 20,
        questionBody_fr:
          'Est classé en troisième classe la contrevention suivante',
        questionBody_en:
          'The following countervention is classified as third class',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Ouverture dangereuses des portières',
            proposition_en: 'Unsafe opening of doors',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: "Défaut d'indicateur de vitesse",
            proposition_en: 'Speedometer failure',
            correspondance: 'b',
            is_answer: true,
          },
          {
            proposition_fr: 'Non présentation du permis de conduire',
            proposition_en: 'Failure to present a driving licence',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'b'`,
        responseProps_en: `The answer is 'b'`,
      },
      {
        questionId: 61,
        questionNumber: 21,
        questionBody_fr: 'Que signifie ce panneau ?',
        questionBody_en: 'What does this sign mean?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: "Panneau de service général d'un hôtel",
            proposition_en: 'General service sign of a hotel',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: "Panneau de service général d'un hôspital",
            proposition_en: 'General service sign of a hospital',
            correspondance: 'b',
            is_answer: true,
          },
          {
            proposition_fr: 'Panneau routier Route H',
            proposition_en: 'Road sign Route H',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'b'`,
        responseProps_en: `The answer is 'b'`,
      },
      {
        questionId: 62,
        questionNumber: 22,
        questionBody_fr:
          'Dans cette intersection, le panneau cédez passage concerne les véhicules qui sont à : ',
        questionBody_en:
          'In this intersection, the yield sign concerns vehicles that are at : ',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Droite',
            proposition_en: 'Right',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: 'Les deux',
            proposition_en: 'Both',
            correspondance: 'b',
            is_answer: true,
          },
          {
            proposition_fr: 'Gauche',
            proposition_en: 'Left',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `Selon l'image, le panneau cédez passage (J2) est mis des deux côté de la route. Nous concluons alors que la réponse 'b' est la bonne`,
        responseProps_en: `According to the picture, the yield sign (J2) is placed on both sides of the road. We conclude that answer 'b' is the correct answer`,
      },
      {
        questionId: 63,
        questionNumber: 23,
        questionBody_fr: 'Que signifie ce panneau ?',
        questionBody_en: 'What does this sign mean?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Passage à niveau',
            proposition_en: 'Level crossing',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: 'Intersection en T',
            proposition_en: 'T-intersection',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: 'Aucune',
            proposition_en: 'None',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'c'`,
        responseProps_en: `The answer is 'c'`,
      },
      {
        questionId: 64,
        questionNumber: 24,
        questionBody_fr:
          "En cas d'accident la balisage de la route permet de :",
        questionBody_en:
          'In the event of an accident, the marking of the road allows :',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Rendre la route plus libre',
            proposition_en: 'Making the road more free',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: 'Tracter les véhicules',
            proposition_en: 'Towing of vehicles',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: "Eviter qu'un autre accident se produise",
            proposition_en: 'Prevent another accident from happening',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `Lors d'un accident, il faut appliquer la règle PAS(Protéger, Alerter, Sécourir). Protéger consiste à baliser les lieu pour éviter d'autre accident causé par celui où vous intervenez. Nous optons pour la réponse 'c'`,
        responseProps_en: `In the event of an accident, the PAS (Protect, Alert, Rescue) rule must be applied. Protecting means marking out the area to avoid further accidents caused by the one you are working on. We opt for the answer 'c'`,
      },
      {
        questionId: 65,
        questionNumber: 25,
        questionBody_fr:
          'Image du panneau de danger (circulation dans les doubles sens)',
        questionBody_en: 'Image of the danger sign (two-way traffic)',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Le trafic que vous approchez est prioritaire',
            proposition_en: 'The traffic you are approaching has priority',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: 'Route a double sens de circulation',
            proposition_en: 'Two-way road',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: 'Aucune',
            proposition_en: 'None',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'c'`,
        responseProps_en: `The answer is 'c'`,
      },
      {
        questionId: 66,
        questionNumber: 26,
        questionBody_fr:
          "En sécourisme cette victime d'un accident est placé en position de choque",
        questionBody_en:
          'In First Aid this accident victim is placed in a shock position',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Vrai',
            proposition_en: 'True',
            correspondance: 'a',
            is_answer: true,
          },
          {
            proposition_fr: 'Faux',
            proposition_en: 'False',
            correspondance: 'b',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is 'a'`,
      },
      {
        questionId: 67,
        questionNumber: 27,
        questionBody_fr: 'Ce marquage sur la route apparaît juste avant',
        questionBody_en: 'This road marking appears just before',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: "Un panneau d'arrêt",
            proposition_en: 'A stop sign',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: 'Un panneau cédez passage',
            proposition_en: 'A give way sign',
            correspondance: 'b',
            is_answer: true,
          },
          {
            proposition_fr: 'Aucun panneau de signalisation',
            proposition_en: 'No road signs',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `Chaque signalisation verticale est matérialisé au sol par un marquage horizontal.Cette image correspond au marquage horizontal de la balise J2. Nous opton pour la réponse 'b'`,
        responseProps_en: `Each vertical sign is marked on the ground with a horizontal marking. This image corresponds to the horizontal marking of the J2 beacon. We opt for answer 'b'`,
      },
      {
        questionId: 68,
        questionNumber: 28,
        questionBody_fr: "Ce panonceau d'indicatoin diverse me rappelle que :",
        questionBody_en: 'This miscellaneous sign reminds me that :',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr:
              'La vitesse est limitée à 50 km/h avant le panneau uniquement',
            proposition_en: 'The speed limit is 50 km/h before the sign only',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr:
              'La vitesse est limitée à 50 km/h après le panneau uniquement',
            proposition_en: 'The speed limit is 50 km/h after the sign only',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr:
              'La vitesse est limitée à 50km/h avant et après le panneau',
            proposition_en:
              'The speed limit is 50km/h before and after the sign',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'c'`,
        responseProps_en: `The answer is 'c'`,
      },
      {
        questionId: 69,
        questionNumber: 29,
        questionBody_fr: 'Quelle est la signification de ce panneau?',
        questionBody_en: 'What is the significance of this sign?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Fin de zone de routes urbaines',
            proposition_en:
              'What is the significance of this sign for the urban road zone?',
            correspondance: 'a',
            is_answer: true,
          },
          {
            proposition_fr: 'A partir de là, il est interdit de stationner',
            proposition_en: 'From this point on, it is forbidden to park',
            correspondance: 'b',
            is_answer: true,
          },
          {
            proposition_fr: 'A partir de là, le stationnement est autorisé',
            proposition_en: 'From this point on, parking is allowed',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est  'b'`,
        responseProps_en: `The answer is 'b'`,
      },
      {
        questionId: 70,
        questionNumber: 30,
        questionBody_fr:
          'Selon ce panneau, une moto est autorisée à doubler un véhicule de passagers privé uniquement que pendant la journée',
        questionBody_en:
          'According to this sign, a motorbike is only allowed to overtake a private passenger vehicle during the day',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Non',
            proposition_en: 'No',
            correspondance: 'a',
            is_answer: true,
          },
          {
            proposition_fr: 'Oui',
            proposition_en: 'Yes',
            correspondance: 'b',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is 'a'`,
      },
      {
        questionId: 71,
        questionNumber: 31,
        questionBody_fr:
          "Quel panneau placé sur la route, informe d'un changement du nombre de voies sur la chaussée ?",
        questionBody_en:
          'Which sign on the road informs of a change in the number of lanes on the road?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Tous les quatre panneaux',
            proposition_en: 'All four panels',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: '622',
            proposition_en: '622',
            correspondance: 'b',
            is_answer: true,
          },
          {
            proposition_fr: '109',
            proposition_en: '109',
            correspondance: 'c',
            is_answer: false,
          },
          {
            proposition_fr: '145',
            proposition_en: '145',
            correspondance: 'd',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'b'`,
        responseProps_en: `The answer is 'b'`,
      },
      {
        questionId: 72,
        questionNumber: 32,
        questionBody_fr:
          'Quel panneau prévient que la chaussée où vous voulez roulez rejoint une autre chaussée ?',
        questionBody_en:
          'Which sign warns that the road where you want to drive meets another road?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: '620',
            proposition_en: '620',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: '145',
            proposition_en: '145',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: '307',
            proposition_en: '307',
            correspondance: 'c',
            is_answer: false,
          },
          {
            proposition_fr: '124',
            proposition_en: '124',
            correspondance: 'd',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'd'`,
        responseProps_en: `The answer is 'd'`,
      },
      {
        questionId: 73,
        questionNumber: 33,
        questionBody_fr:
          'Temoin indicateur de dysfonctionnement du contrôle de la traction',
        questionBody_en: 'Traction control malfunction indicator',
        questionImg: panel,
        propositionsAnswers: [
          { proposition_fr: '1', correspondance: 'a', is_answer: false },
          { proposition_fr: '2', correspondance: 'b', is_answer: false },
          { proposition_fr: '3', correspondance: 'c', is_answer: true },
          { proposition_fr: '4', correspondance: 'd', is_answer: false },
          { proposition_fr: '5', correspondance: 'e', is_answer: false },
          { proposition_fr: '6', correspondance: 'f', is_answer: false },
          { proposition_fr: '7', correspondance: 'g', is_answer: false },
          { proposition_fr: '8', correspondance: 'h', is_answer: false },
          { proposition_fr: '9', correspondance: 'i', is_answer: false },
          { proposition_fr: '10', correspondance: 'j', is_answer: false },
        ],
        responseProps_fr: `La réponse est le c`,
        responseProps_en: `The answer is c`,
      },
      {
        questionId: 74,
        questionNumber: 34,
        questionBody_fr: 'Indicateur de liquide de lave-glace',
        questionBody_en: 'Washer fluid indicator',
        questionImg: panel,
        propositionsAnswers: [
          { proposition_fr: '1', correspondance: 'a', is_answer: false },
          { proposition_fr: '2', correspondance: 'b', is_answer: false },
          { proposition_fr: '3', correspondance: 'c', is_answer: false },
          { proposition_fr: '4', correspondance: 'd', is_answer: true },
          { proposition_fr: '5', correspondance: 'e', is_answer: false },
          { proposition_fr: '6', correspondance: 'f', is_answer: false },
          { proposition_fr: '7', correspondance: 'g', is_answer: false },
          { proposition_fr: '8', correspondance: 'h', is_answer: false },
          { proposition_fr: '9', correspondance: 'i', is_answer: false },
          { proposition_fr: '10', correspondance: 'j', is_answer: false },
        ],
        responseProps_fr: `La réponse est le d`,
        responseProps_en: `The answer is d`,
      },
      {
        questionId: 75,
        questionNumber: 35,
        questionBody_fr:
          'Témoin indicateur du correcteur de site des projecteurs',
        questionBody_en: 'Headlamp levelling indicator lamp',
        questionImg: panel,
        propositionsAnswers: [
          { proposition_fr: '1', correspondance: 'a', is_answer: false },
          { proposition_fr: '2', correspondance: 'b', is_answer: false },
          { proposition_fr: '3', correspondance: 'c', is_answer: false },
          { proposition_fr: '4', correspondance: 'd', is_answer: false },
          { proposition_fr: '5', correspondance: 'e', is_answer: false },
          { proposition_fr: '6', correspondance: 'f', is_answer: true },
          { proposition_fr: '7', correspondance: 'g', is_answer: false },
          { proposition_fr: '8', correspondance: 'h', is_answer: false },
          { proposition_fr: '9', correspondance: 'i', is_answer: false },
          { proposition_fr: '10', correspondance: 'j', is_answer: false },
        ],
        responseProps_fr: `La réponse est le f`,
        responseProps_en: `The answer is  f`,
      },
      {
        questionId: 76,
        questionNumber: 36,
        questionBody_fr: 'Indicateur du système de verouillage automatique',
        questionBody_en: 'Automatic locking system indicator',
        questionImg: panel,
        propositionsAnswers: [
          { proposition_fr: '1', correspondance: 'a', is_answer: false },
          { proposition_fr: '2', correspondance: 'b', is_answer: false },
          { proposition_fr: '3', correspondance: 'c', is_answer: false },
          { proposition_fr: '4', correspondance: 'd', is_answer: false },
          { proposition_fr: '5', correspondance: 'e', is_answer: false },
          { proposition_fr: '6', correspondance: 'f', is_answer: false },
          { proposition_fr: '7', correspondance: 'g', is_answer: false },
          { proposition_fr: '8', correspondance: 'h', is_answer: false },
          { proposition_fr: '9', correspondance: 'i', is_answer: true },
          { proposition_fr: '10', correspondance: 'j', is_answer: false },
        ],
        responseProps_fr: `La réponse est le i`,
        responseProps_en: `The answer is  i`,
      },
      {
        questionId: 77,
        questionNumber: 37,
        questionBody_fr: 'Que signifie ce panneau',
        questionBody_en: 'What does this sign mean',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Route inondée',
            proposition_en: 'Flooded road',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr: 'Falaise',
            proposition_en: 'Cliff',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: 'Aucune',
            proposition_en: 'None',
            correspondance: 'c',
            is_answer: true,
          },
        ],
        responseProps_fr: `La réponse est 'c'`,
        responseProps_en: `The answer is 'c'`,
      },
      {
        questionId: 78,
        questionNumber: 38,
        questionBody_fr: 'Où aprecevriez-vous ce marquage routier ?',
        questionBody_en: 'Where would you get this road marking?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: "Au niveau d'une intersection",
            proposition_en: 'At an intersection',
            correspondance: 'a',
            is_answer: true,
          },
          {
            proposition_fr: "Sur une bretelle d'autoroute",
            proposition_en: 'On a motorway slip road',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: 'Sur une partie de la route surélevée',
            proposition_en: 'On a part of the elevated road',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is  'a'`,
      },
      {
        questionId: 79,
        questionNumber: 39,
        questionBody_fr:
          'Vous êtes à une intersection en T. Un véhicule vient de a gauche avec son clignotant droit activé. Que devrez vous faire?',
        questionBody_en:
          'You are at a T-junction. A vehicle is coming from the left with its right-hand indicator on. What should you do?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Sortir rapidement',
            proposition_en: 'Get out quickly',
            correspondance: 'a',
            is_answer: false,
          },
          {
            proposition_fr:
              "Attendre jusqu'à ce que le véhicule commence à tourner",
            proposition_en: 'Wait until the vehicle starts to turn',
            correspondance: 'b',
            is_answer: true,
          },
          {
            proposition_fr: 'Aucune',
            proposition_en: 'None',
            correspondance: 'c',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'b'`,
        responseProps_en: `The answer is 'b'`,
      },
      {
        questionId: 80,
        questionNumber: 40,
        questionBody_fr:
          "Quelle est la forme du panneau d'arrêt à une intersection?",
        questionBody_en:
          'What is the shape of the stop sign at an intersection?',
        questionImg: panel,
        propositionsAnswers: [
          {
            proposition_fr: 'Forme A',
            proposition_en: 'Form A',
            correspondance: 'a',
            is_answer: true,
          },
          {
            proposition_fr: 'Forme B',
            proposition_en: 'Form A',
            correspondance: 'b',
            is_answer: false,
          },
          {
            proposition_fr: 'Forme C',
            proposition_en: 'Form A',
            correspondance: 'c',
            is_answer: false,
          },
          {
            proposition_fr: 'Forme D',
            proposition_en: 'Form A',
            correspondance: 'd',
            is_answer: false,
          },
        ],
        responseProps_fr: `La réponse est 'a'`,
        responseProps_en: `The answer is  'a'`,
      },
    ],
    creation_date: '20/03/2021',
    is_in_production: true,
  },
]

// data payment
export const dataPayment: Payment[] = [
  {
    paymentId: 1,
    userPayment: dataUsers[3],
    price: 2000,
    date_payment: '20/08/2022',
    validation: true,
    number_payment: 1405202265541,
  },
  {
    paymentId: 1,
    userPayment: dataUsers[3],
    price: 5000,
    date_payment: '21/08/2022',
    validation: undefined,
    number_payment: 1405202125344,
  },
  {
    paymentId: 1,
    userPayment: dataUsers[3],
    price: 10000,
    date_payment: '22/08/2022',
    validation: false,
    number_payment: 14052020223355,
  },
]

// data exam
export const composition: Composition[] = [
  {
    compositionId: 1,
    date_composition: '19/07/2022',
    note: 21,
    epreuve: dataTests[0],
    abonnement: dataAbonnement[0],
  },
  {
    compositionId: 2,
    date_composition: '2/08/2022',
    note: 21,
    epreuve: dataTests[1],
    abonnement: dataAbonnement[0],
  },
  {
    compositionId: 3,
    date_composition: '10/04/2022',
    note: 21,
    epreuve: dataTests[1],
    abonnement: dataAbonnement[1],
  },
  {
    compositionId: 4,
    date_composition: '22/08/2022',
    note: 21,
    epreuve: dataTests[0],
    abonnement: dataAbonnement[1],
  },
]

export const dataTest1 = {
  dataTestInfos: {
    langueType: 'fr',
    epreuveId: 'cgdho-21548-gdiys',
    session: Date(),
    department: 'Haute-Sanaga',
    category: 'B',
    user_created: 'agent | Mifi',
    creation_date: '10/03/2021',
    is_in_production: true,
  },
  questions: [
    {
      questionId: 1,
      questionBody:
        'Le genre de type de batterie utilisée dans la plupart des voitures pour démarrer un moteur est',
      propositionAnswers: [
        {
          propositionId: 'hui5keh',
          proposition: '12 volts',
          is_answer: true,
        },
        {
          propositionId: 'huisweh',
          proposition: '24 volts',
          is_answer: false,
        },
        {
          propositionId: '4uiskeh',
          proposition: '36 volts',
          is_answer: false,
        },
      ],
      feedback:
        "Les batteries utilisées par les voitures en général ont besoin d'une tension élevée pour fonctionner. La réponse a correspond à la tension utilisée pour les battéries de voiture de tourisme. ",
    },
    {
      questionId: 2,
      questionBody:
        'A quelle vitesse votre véhicule optimise-t-il son (rendement énergétique)',
      propositionAnswers: [
        {
          propositionId: 'huiskrh',
          proposition: 'Vitesse réduite (moin de 30km/h)',
          is_answer: true,
        },
        {
          propositionId: 'hufskeh',
          proposition: 'Vitesse moyenne (40 à 60 km/h)',
          is_answer: false,
        },
        {
          propositionId: 'huj8keh',
          proposition: 'Vitesse élevée (60 à 100 km/h)',
          is_answer: false,
        },
      ],
      feedback: `Plus un véhicule roule à basse vitesse, plus sa consommation est basse. Nous optons pour la réponse 'a'`,
    },
    {
      questionId: 3,
      questionBody: 'Le stationnement est interdit sur:',
      propositionAnswers: [
        {
          propositionId: 'oiiskeh',
          proposition: 'Une voie à sens unique',
          is_answer: false,
        },
        {
          propositionId: 'hur8keh',
          proposition: 'Les routes secondaires',
          is_answer: false,
        },
        {
          propositionId: 'hui9qeh',
          proposition: 'Trottoirs et les feux tricolores',
          is_answer: true,
        },
      ],
      feedback: `Il est interdit de stationner sur des espaces amenagés pour d'autres usagers de la route. Egalement à des endroits où vous pouvez être génant pour les usagers de la route. Nous opton pour la solution 'c'`,
    },
    {
      questionId: 4,
      questionBody:
        'Choisissez les éléments qui ne sont pas obligatoires dans votre véhicule',
      propositionAnswers: [
        {
          propositionId: 'huisk7t',
          proposition: 'La trousse de sécours',
          is_answer: false,
        },
        {
          propositionId: 'ofiskeh',
          proposition: 'Au moins un triangle de signalisation',
          is_answer: false,
        },
        {
          propositionId: 'hui8eoh',
          proposition: 'La torche',
          is_answer: true,
        },
        {
          propositionId: 'ho8skeh',
          proposition: 'Aucune des réponses',
          is_answer: false,
        },
      ],
      feedback: `La réponse est belle et bien 'c'`,
    },
    {
      questionId: 5,
      questionBody:
        "En cas d'accident en campagne, pour alerter la gendarmerie j'appelle",
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'hu8soeh',
          proposition: '17/117',
          is_answer: true,
        },
        {
          propositionId: 'hxlpkeh',
          proposition: '18/118',
          is_answer: false,
        },
        {
          propositionId: 'hu9xoeh',
          proposition: '13/113',
          is_answer: false,
        },
      ],
      feedback: `La réponse est 'a'`,
    },
    {
      questionId: 6,
      questionBody:
        "Est-ce que le véhicule d'urgence peut-il traverser le feux rouge?",
      propositionAnswers: [
        {
          propositionId: 'haorkeh',
          proposition: 'Oui',
          is_answer: true,
        },
        {
          propositionId: 'hui9f5h',
          proposition: 'Non',
          is_answer: false,
        },
      ],
      feedback: `Les véhicules d'urgences, lorsqu'ils sont en activités (sirène et girophare activés), ne respectent plus le code de la route. Ils en est de même pour les véhicules dit prioritaires. La réponse ici est 'a'`,
    },
    {
      questionId: 7,
      questionBody:
        "Si un véhicule transportant de l'essence ou combustible de chauffage ou de l'acide, est impliqué dans un accident, vous devez:",
      propositionAnswers: [
        {
          propositionId: 'huiss8u',
          proposition: "Rester à bonne distance di lieu de l'accident",
          is_answer: false,
        },
        {
          propositionId: 'h9s2keh',
          proposition: 'Avertir les autres usagers de la route du danger',
          is_answer: false,
        },
        {
          propositionId: 'huiss8o',
          proposition:
            "Permettre aux services d'urgence d'apporter de l'assistance",
          is_answer: false,
        },
        {
          propositionId: 'huapqeh',
          proposition: 'Toutes les trois',
          is_answer: true,
        },
      ],
      feedback: `La réponse ici est 'd'`,
    },
    {
      questionId: 8,
      questionBody: 'Les marques sur la chaussé',
      propositionAnswers: [
        {
          propositionId: 'hu9w2eh',
          proposition:
            "Sont des indications que le conducteur n'est pas tenu de respecter",
          is_answer: false,
        },
        {
          propositionId: 'huisk91',
          proposition:
            'Ont force de loi seulement si elles sont accompagnées de panneaux de signalisation',
          is_answer: false,
        },
        {
          propositionId: 'huiszph',
          proposition:
            "Font partie de la signalisation, tout conducteur doit s'y conformer",
          is_answer: true,
        },
      ],
      feedback: `Les marques sur la chaussé entre dans la signalisation verticale. Et en tant que signalisation routière, elle fait partie intégrante des différentes règles du code de la route. Nous optons pour la réponse 'c'`,
    },
    {
      questionId: 9,
      questionBody:
        "En cas d'évacuation après un accident, laquelle des victimes est prioritaire:",
      propositionAnswers: [
        {
          propositionId: 'h31skeh',
          proposition: "Celle qui présente les signes d'hémorragie interne",
          is_answer: true,
        },
        {
          propositionId: 'huis9jh',
          proposition: 'Celle qui a plusieurs fractures',
          is_answer: false,
        },
        {
          propositionId: 'huia59eh',
          proposition: 'Celle qui a une hémorragie externe',
          is_answer: false,
        },
      ],
      feedback: `Les causes de mortalité en cas d'accident de la route sont pour la plus part l'asphyxie et l'hémorragie. Par contre il existe deux types d'hémorragie (externe et interne). Celui interne nécéssite une évacuation sanitaire d'urgence. La réponse 'a' est la bonne`,
    },
    {
      questionId: 10,
      questionBody:
        'Le bouchon du radiateur type à pression selon certains fabricants, doit être enlevé uniquement :',
      propositionAnswers: [
        {
          propositionId: 'huisksl',
          proposition: 'Lorsque le moteur est chaud',
          is_answer: false,
        },
        {
          propositionId: 'hui9skfh',
          proposition: 'Pour refroidir un moteur surchauffé',
          is_answer: false,
        },
        {
          propositionId: 'husstex',
          proposition: 'Lorsque le moteur est froid',
          is_answer: true,
        },
      ],
      feedback: `La réponse est 'c'`,
    },
    {
      questionId: 11,
      questionBody:
        'Signaler votre intention ne vous donne pas le droit de priorité',
      propositionAnswers: [
        {
          propositionId: '9dupseh',
          proposition: 'Vrai',
          is_answer: true,
        },
        {
          propositionId: 'hoid9eh',
          proposition: 'Faux',
          is_answer: false,
        },
      ],
      feedback: `La réponse est 'a'`,
    },
    {
      questionId: 12,
      questionBody: "L'amélioration des véhicules 4 x 4 est pour :",
      propositionAnswers: [
        {
          propositionId: 'hu2p3eh',
          proposition: 'Son confort',
          is_answer: true,
        },
        {
          propositionId: '9h5useh',
          proposition: 'Son adhésion sur la route',
          is_answer: false,
        },
        {
          propositionId: 'huisr36',
          proposition: "Sa capacité d'arrêt",
          is_answer: false,
        },
      ],
      feedback: `L'amélioration du'un véhicule 4 x 4 est d'avantage pour son confort car se sont des véhicules conçus pour des routes extrèmes et très enclavées. Nous optons pour la réponse 'a'`,
    },
    {
      questionId: 13,
      questionBody: 'Que doit-on faire pour combattre la fatigue au volant ?',
      propositionAnswers: [
        {
          propositionId: 'huiskso5',
          proposition: 'Engager une conversation animée',
          is_answer: false,
        },
        {
          propositionId: 'huissljbsdg',
          proposition: "Laisser pénétrer beaucoup d'air dans la voiture",
          is_answer: false,
        },
        {
          propositionId: 'huislyu6',
          proposition: "S'arrêter et se reposer",
          is_answer: true,
        },
        {
          propositionId: 'huisshg5',
          proposition: 'Tout se qui précéde',
          is_answer: false,
        },
      ],
      feedback: `Le seul remède de la fatigue au volant c'est le repos. Donc la réponse est 'c'`,
    },
    {
      questionId: 14,
      questionBody:
        'Peut-on circuler sur une propriété privé pour éviter de se conformer à une signalisation ?',
      propositionAnswers: [
        {
          propositionId: 'apiheg5eh',
          proposition: 'Non, la loi le défend',
          is_answer: true,
        },
        {
          propositionId: 'huisasjfbsd5h',
          proposition: 'Oui, si cela accelère la circulation',
          is_answer: false,
        },
        {
          propositionId: 'huissj6h',
          proposition: "Non, sauf s'il y a embouteillage",
          is_answer: false,
        },
      ],
      feedback: `La réponse est la 'a'`,
    },
    {
      questionId: 15,
      questionBody:
        "Si deux véhicules s'approchent d'une intersection en même temps dans un angle droit, lequel devrait ceder le droit de passage ?",
      propositionAnswers: [
        {
          propositionId: 'hspjbf6h',
          proposition: 'Le véhicule qui va plus vite',
          is_answer: false,
        },
        {
          propositionId: 'h6857zsdgf',
          proposition: 'Le véhicule à droite',
          is_answer: false,
        },
        {
          propositionId: '687sdfkeh',
          proposition: 'Le véhicule à gauche',
          is_answer: true,
        },
      ],
      feedback: `La priorité à droite est une règle du code la route qui dans une intersection d'égale valeur vous demande de laisser passer tous les autres conducteurs venant de votre droite. Nous opton pour la réponse 'c'`,
    },
    {
      questionId: 16,
      questionBody: "Il est illégal qu'un véhicule routier soit muni:",
      propositionAnswers: [
        {
          propositionId: 'h68sdgsgh',
          proposition: "D'un avertisseur sonore",
          is_answer: false,
        },
        {
          propositionId: 'huasgset57eh',
          proposition: 'De feux de recul',
          is_answer: false,
        },
        {
          propositionId: 'hu68sdg68asetgh',
          proposition: "D'un détecteur de radar de vitesse",
          is_answer: true,
        },
      ],
      feedback: `La réponse est 'c'`,
    },
    {
      questionId: 17,
      questionBody:
        "L'arbre qui actionne l'ouverture et la fermeture des soupapes s'appelle:",
      propositionAnswers: [
        {
          propositionId: 'h654aegw678eh',
          proposition: 'Le vilebrequin',
          is_answer: false,
        },
        {
          propositionId: 'hudg3adtaeeh',
          proposition: "L'arbre de transmission",
          is_answer: false,
        },
        {
          propositionId: 'husrg6886687srgh',
          proposition: "L'arbre à cames",
          is_answer: true,
        },
      ],
      feedback: `La réponse est 'c'`,
    },
    {
      questionId: 18,
      questionBody:
        "Quels sont les effets de l'alcool sur l'organisme et le comportement de l'homme ?",
      propositionAnswers: [
        {
          propositionId: 'hu68aeth',
          proposition: "L'alcool affaiblit la vue et ralentit les reflexes",
          is_answer: true,
        },
        {
          propositionId: 'huiadfgasef7h',
          proposition:
            "L'alcool diminue graduellement la maitrise des nerfs et déprime",
          is_answer: false,
        },
        {
          propositionId: 'hu6887aegsdgh',
          proposition: 'Les deux',
          is_answer: false,
        },
      ],
      feedback: `La réponse est 'a'`,
    },
    {
      questionId: 19,
      questionBody:
        "Si la vitesse d'un véhicule est triplée, de combien de force d'impact serait-elle supérieur advenant une collision avec un autre objet ? ",
      propositionAnswers: [
        {
          propositionId: 'hu6886adgeh',
          proposition: '9 fois',
          is_answer: true,
        },
        {
          propositionId: 'huizafaef3eh',
          proposition: '3 fois',
          is_answer: false,
        },
        {
          propositionId: 'huisk6886adf',
          proposition: '2 fois',
          is_answer: false,
        },
      ],
      feedback: `La réponse est 'a'`,
    },
    {
      questionId: 20,
      questionBody: 'Est classé en troisième classe la contrevention suivante',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'hui57aefh',
          proposition: 'Ouverture dangereuses des portières',
          is_answer: false,
        },
        {
          propositionId: 'huiswetey848hjh',
          proposition: "Défaut d'indicateur de vitesse",
          is_answer: true,
        },
        {
          propositionId: 'huifhj68awh',
          proposition: 'Non présentation du permis de conduire',
          is_answer: false,
        },
      ],
      feedback: `La réponse est 'b'`,
    },
    {
      questionId: 21,
      questionBody: 'Que signifie ce panneau ?',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'hui6aet86h',
          proposition: "Panneau de service général d'un hôtel",
          is_answer: false,
        },
        {
          propositionId: 'hu68etaetesrh',
          proposition: "Panneau de service général d'un hôspital",
          is_answer: true,
        },
        {
          propositionId: 'huis68seth',
          proposition: 'Panneau routier Route H',
          is_answer: false,
        },
      ],
      feedback: `La réponse est 'b'`,
    },
    {
      questionId: 22,
      questionBody:
        'Dans cette intersection, le panneau cédez passage concerne les véhicules qui sont à : ',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'hui+897h',
          proposition: 'Droite',
          is_answer: false,
        },
        {
          propositionId: 'husege68-keh',
          proposition: 'Les deux',
          is_answer: true,
        },
        {
          propositionId: 'hu/-eh',
          proposition: 'Gauche',
          is_answer: false,
        },
      ],
      feedback: `Selon l'image, le panneau cédez passage (J2) est mis des deux côté de la route. Nous concluons alors que la réponse 'b' est la bonne`,
    },
    {
      questionId: 23,
      questionBody: 'Que signifie ce panneau ?',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'hu687seg7h',
          proposition: 'Passage à niveau',
          is_answer: false,
        },
        {
          propositionId: 'hutruok6854keh',
          proposition: 'Intersection en T',
          is_answer: false,
        },
        {
          propositionId: 'hukhvwrg68eh',
          proposition: 'Aucune',
          is_answer: true,
        },
      ],
      feedback: `La réponse est 'c'`,
    },
    {
      questionId: 24,
      questionBody: "En cas d'accident la balisage de la route permet de :",
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'hu68768sdgljbrg6h',
          proposition: 'Rendre la route plus libre',
          is_answer: false,
        },
        {
          propositionId: 'huirljnsrbhke6h',
          proposition: 'Tracter les véhicules',
          is_answer: false,
        },
        {
          propositionId: 'hui[oasehf 8xfh',
          proposition: "Eviter qu'un autre accident se produise",
          is_answer: true,
        },
      ],
      feedback: `Lors d'un accident, il faut appliquer la règle PAS(Protéger, Alerter, Sécourir). Protéger consiste à baliser les lieu pour éviter d'autre accident causé par celui où vous intervenez. Nous optons pour la réponse 'c'`,
    },
    {
      questionId: 25,
      questionBody:
        'Image du panneau de danger (circulation dans les doubles sens)',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'hui6868srgsrgh',
          proposition: 'Le trafic que vous approchez est prioritaire',
          is_answer: false,
        },
        {
          propositionId: 'hu68sertgsgh',
          proposition: 'Route a double sens de circulation',
          is_answer: false,
        },
        {
          propositionId: 'huisrg68867sfh',
          proposition: 'Aucune',
          is_answer: true,
        },
      ],
      feedback: `La réponse est 'c'`,
    },
    {
      questionId: 26,
      questionBody:
        "En sécourisme cette victime d'un accident est placé en position de choque",

      propositionId: 'hu68srgsr687h',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'husdg6768h',
          proposition: 'Vrai',
          is_answer: true,
        },
        {
          propositionId: 'hui[ojujr-54h',
          proposition: 'Faux',
          is_answer: false,
        },
      ],
      feedback: `La réponse est 'a'`,
    },
    {
      questionId: 27,
      questionBody: 'Ce marquage sur la route apparaît juste avant',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'hu6868srgsejhkd56-h',
          proposition: "Un panneau d'arrêt",
          is_answer: false,
        },
        {
          propositionId: 'huljsdksd+k.dfh',
          proposition: 'Un panneau cédez passage',
          is_answer: true,
        },
        {
          propositionId: 'huijlnsd=kb kdjh',
          proposition: 'Aucun panneau de signalisation',
          is_answer: false,
        },
      ],
      feedback: `Chaque signalisation verticale est matérialisé au sol par un marquage horizontal.Cette image correspond au marquage horizontal de la balise J2. Nous opton pour la réponse 'b'`,
    },
    {
      questionId: 28,
      questionBody: "Ce panonceau d'indicatoin diverse me rappelle que :",
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'hui687sdjbhked-1h',
          proposition:
            'La vitesse est limitée à 50 km/h avant le panneau uniquement',
          is_answer: false,
        },
        {
          propositionId: 'huikhbsdjh68-h',
          proposition:
            'La vitesse est limitée à 50 km/h après le panneau uniquement',
          is_answer: false,
        },
        {
          propositionId: 'huljdhbd8y3kj sdeh',
          proposition:
            'La vitesse est limitée à 50km/h avant et après le panneau',
          is_answer: true,
        },
      ],
      feedback: `La réponse est 'c'`,
    },
    {
      questionId: 29,
      questionBody: 'Quelle est la signification de ce panneau?',
      questionImg:
        'https://ecomatin.net/wp-content/uploads/2018/10/ecomatin.net-accidents-de-circulation-le-secteur-des-assurances-etonnamment-muet-sur-le-sujet-accident-e1516704138714.jpeg',
      propositionAnswers: [
        {
          propositionId: 'huiskeh9687sd*',
          proposition: 'Fin de zone de routes urbaines',
          is_answer: true,
        },
        {
          propositionId: 'huiskeh;ksefkjd',
          proposition: 'A partir de là, il est interdit de stationner',
          is_answer: true,
        },
        {
          propositionId: 'hui646sdkskeh',
          proposition: 'A partir de là, le stationnement est autorisé',
          is_answer: false,
        },
      ],
      feedback: `La réponse est  'b'`,
    },
    {
      questionId: 30,
      questionBody:
        'Selon ce panneau, une moto est autorisée à doubler un véhicule de passagers privé uniquement que pendant la journée',

      propositionId: 'huiskeh',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'hui57seks9skeh',
          proposition: 'Non',
          is_answer: true,
        },
        {
          propositionId: 'huisk8sdfkn-eh',
          proposition: 'Oui',
          is_answer: false,
        },
      ],
      feedback: `La réponse est 'a'`,
    },
    {
      questionId: 31,
      questionBody:
        "Quel panneau placé sur la route, informe d'un changement du nombre de voies sur la chaussée ?",

      propositionId: 'huis;f687*/keh',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'huis69d+ekeh',
          proposition: 'Tous les quatre panneaux',
          is_answer: false,
        },
        {
          propositionId: 'hui64sdr6skeh',
          proposition: '622',
          is_answer: true,
        },
        {
          propositionId: 'huis;os68keh',
          proposition: '109',
          is_answer: false,
        },
        {
          propositionId: 'huiskeh',
          proposition: '145',
          is_answer: false,
        },
      ],
      feedback: `La réponse est 'b'`,
    },
    {
      questionId: 32,
      questionBody:
        'Quel panneau prévient que la chaussée où vous voulez roulez rejoint une autre chaussée ?',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'huiskdr678d-keh',
          proposition: '620',
          is_answer: false,
        },
        {
          propositionId: 'hu.knsd6+dkiskeh',
          proposition: '145',
          is_answer: false,
        },
        {
          propositionId: 'huiksdk687sdskeh',
          proposition: '307',
          is_answer: false,
        },
        {
          propositionId: 'huiskeh',
          proposition: '124',
          is_answer: true,
        },
      ],
      feedback: `La réponse est 'd'`,
    },
    {
      questionId: 33,
      questionBody:
        'Temoin indicateur de dysfonctionnement du contrôle de la traction',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'ksaj687sessg',
          proposition: '1',
          is_answer: false,
        },
        {
          propositionId: 'ksaj58d-sg',
          proposition: '2',
          is_answer: false,
        },
        {
          propositionId: 'ksa68466x+jsg',
          proposition: '3',
          is_answer: true,
        },
        {
          propositionId: 'ksaj68d+s.ndsg',
          proposition: '4',
          is_answer: false,
        },
        {
          propositionId: 'ksaj689xs-sg',
          proposition: '5',
          is_answer: false,
        },
        {
          propositionId: 'ksa58sdf6sjsg',
          proposition: '6',
          is_answer: false,
        },
        {
          propositionId: 'ksa6879fdkr0jsg',
          proposition: '7',
          is_answer: false,
        },
        {
          propositionId: 'ksa68sdf;seuh7slsjsg',
          proposition: '8',
          is_answer: false,
        },
        {
          propositionId: 'ksa;kndr687sdjsg',
          proposition: '9',
          is_answer: false,
        },
        {
          propositionId: 'ksa68f86gksejsg',
          proposition: '10',
          is_answer: false,
        },
      ],
      feedback: `La réponse est le c`,
    },
    {
      questionId: 34,
      questionBody: 'Indicateur de liquide de lave-glace',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'ksaj6846xdv5a-sg',
          proposition: '1',
          is_answer: false,
        },
        {
          propositionId: 'ksknsd*+s65dajsg',
          proposition: '2',
          is_answer: false,
        },
        {
          propositionId: 'ksaj588sdf-stysg',
          proposition: '3',
          is_answer: false,
        },
        {
          propositionId: 'ks687ty/49eajsg',
          proposition: '4',
          is_answer: true,
        },
        {
          propositionId: 'ks56d+*t0dajsg',
          proposition: '5',
          is_answer: false,
        },
        {
          propositionId: 'ks68df*df5ajsg',
          proposition: '6',
          is_answer: false,
        },
        {
          propositionId: 'ksaj86df8dr*-sg',
          proposition: '7',
          is_answer: false,
        },
        {
          propositionId: 'ksa687fg9sef*+jsg',
          proposition: '8',
          is_answer: false,
        },
        {
          propositionId: 'ksaj;lsr8w3pdvj868sg',
          proposition: '9',
          is_answer: false,
        },
        {
          propositionId: 'ksajlmsd69esg',
          proposition: '10',
          is_answer: false,
        },
      ],
      feedback: `La réponse est le d`,
    },
    {
      questionId: 35,
      questionBody: 'Témoin indicateur du correcteur de site des projecteurs',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'ksa97ere64fjsg',
          proposition: '1',
          is_answer: false,
        },
        {
          propositionId: 'ksa;osfower0w3jsg',
          proposition: '2',
          is_answer: false,
        },
        {
          propositionId: 'ksa[3r9tr8jsg',
          proposition: '3',
          is_answer: false,
        },
        {
          propositionId: 'ksaj6we*e*4sg',
          proposition: '4',
          is_answer: false,
        },
        {
          propositionId: 'ksawe-th7er+wsg',
          proposition: '5',
          is_answer: false,
        },
        {
          propositionId: 'ksa8erer9we-jsg',
          proposition: '6',
          is_answer: true,
        },
        {
          propositionId: 'ksawewe5h*3jsg',
          proposition: '7',
          is_answer: false,
        },
        {
          propositionId: 'ksa9rg9+er+jsg',
          proposition: '8',
          is_answer: false,
        },
        {
          propositionId: 'ksajoerieto9sg',
          proposition: '9',
          is_answer: false,
        },
        {
          propositionId: 'ksajs8er*+e9t4g',
          proposition: '10',
          is_answer: false,
        },
      ],
      feedback: `La réponse est le f`,
    },
    {
      questionId: 36,
      questionBody: 'Indicateur du système de verouillage automatique',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'ksaj8er9ert4sg',
          proposition: '1',
          is_answer: false,
        },
        {
          propositionId: 'ksa9rer94-jsg',
          proposition: '2',
          is_answer: false,
        },
        {
          propositionId: 'ksakwepiwtoer9er*jsg',
          proposition: '3',
          is_answer: false,
        },
        {
          propositionId: 'ksoiwetr8t4983t4hfajsg',
          proposition: '4',
          is_answer: false,
        },
        {
          propositionId: 'ks/9tr/ee*tajsg',
          proposition: '5',
          is_answer: false,
        },
        {
          propositionId: 'ksajoerpiher9r7+rsg',
          proposition: '6',
          is_answer: false,
        },
        {
          propositionId: 'ks;lrg9er7r+ajsg',
          proposition: '7',
          is_answer: false,
        },
        {
          propositionId: 'kslkrouerer+ajsg',
          proposition: '8',
          is_answer: false,
        },
        {
          propositionId: 'ksa98sr9er+wjsg',
          proposition: '9',
          is_answer: true,
        },
        {
          propositionId: 'ksajsljerouw9w7+wg',
          proposition: '10',
          is_answer: false,
        },
      ],
      feedback: `La réponse est le i`,
    },
    {
      questionId: 37,
      questionBody: 'Que signifie ce panneau',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'hasiaylhasrbr67+erag',
          proposition: 'Route inondée',
          is_answer: false,
        },
        {
          propositionId: 'has;kljsdo89/et98iayag',
          proposition: 'Falaise',
          is_answer: false,
        },
        {
          propositionId: 'has8797weljiayag',
          proposition: 'Aucune',
          is_answer: true,
        },
      ],
      feedback: `La réponse est 'c'`,
    },
    {
      questionId: 38,
      questionBody: 'Où aprecevriez-vous ce marquage routier ?',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'hasia897rwet9asyag',
          proposition: "Au niveau d'une intersection",
          is_answer: true,
        },
        {
          propositionId: 'hasialasepiwe9e+93-yag',
          proposition: "Sur une bretelle d'autoroute",
          is_answer: false,
        },
        {
          propositionId: 'hasia;ls+98eyag',
          proposition: 'Sur une partie de la route surélevée',
          is_answer: false,
        },
      ],
      feedback: `La réponse est 'a'`,
    },
    {
      questionId: 39,
      questionBody:
        'Vous êtes à une intersection en T. Un véhicule vient de a gauche avec son clignotant droit activé. Que devrez vous faire?',
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'hasia987er9weyag',
          proposition: 'Sortir rapidement',
          is_answer: false,
        },
        {
          propositionId: 'hasi+wt4+7+wayag',
          proposition: "Attendre jusqu'à ce que le véhicule commence à tourner",
          is_answer: true,
        },
        {
          propositionId: 'hasiay;ksrg97ag',
          proposition: 'Aucune',
          is_answer: false,
        },
      ],
      feedback: `La réponse est 'b'`,
    },
    {
      questionId: 40,
      questionBody:
        "Quelle est la forme du panneau d'arrêt à une intersection?",
      questionImg: panel,
      propositionAnswers: [
        {
          propositionId: 'halkaweow897esiayag',
          proposition: 'Forme A',
          is_answer: true,
        },
        {
          propositionId: 'hasi;kasg697+eayag',
          proposition: 'Forme B',
          is_answer: false,
        },
        {
          propositionId: 'hasiljbiefkwef9ayag',
          proposition: 'Forme C',
          is_answer: false,
        },
        {
          propositionId: 'hasijkbiehw687ayag',
          proposition: 'Forme D',
          is_answer: false,
        },
      ],
      feedback: `La réponse est 'a'`,
    },
  ],
}
