//  Made by Poukam Ngamaleu
import Footer from '../../components/footer'
import Header from '../../components/Header'
import { Outlet } from 'react-router'

function ProspectSkeleton() {
  return (
    <>
      <Header />
      <Outlet></Outlet>
      <Footer />
    </>
  )
}

export default ProspectSkeleton
