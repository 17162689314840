// Made by Poukam Ngamaleu

import {
  Autocomplete,
  Box,
  Grid,
  Skeleton,
  styled,
  TextField,
  TextFieldProps,
  Typography,
  Divider,
} from '@mui/material'
import { dataCategoryOfLicence, department } from '../../utils/dataWorking'
import TestCard from '../CardTest'
import AppPagination from '../Pagination'
import { dateTestInterface } from '../tests'
import { useState, useEffect } from 'react'
import Axios from 'axios'
import { useAuth } from '../../utils/context'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import no_result_img from '../../asset/no-result-document-file-data-not-found-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-etc-vector-removebg-preview.png'

const StyledBoxSearch = styled(Box)({
  opacity: '0.8',
  alignItems: 'center',
  paddingTop: 10,
})

export const categoryArray: string[] = []
// eslint-disable-next-line array-callback-return
dataCategoryOfLicence.map(({ label }) => {
  categoryArray.push(label)
})

function Epreuves() {
  const {
    userInfo: { id },
  } = useAuth()
  const [dataTests, setDataTests] = useState<dateTestInterface[]>([
    {
      id_epreuve: '',
      libele_depart: '',
      date_session: '',
      libele_cat: '',
      armoiry: '',
      code_pays: 'cmr',
    },
  ])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>()
  const [dataSearch, setDataSearch] = useState<{
    session: string | null
    department: string | null
    category: string | null
  }>({
    session: null,
    department: null,
    category: null,
  })

  const postPerPage = 8

  const count = dataTests.length
  // Avoir l'épreuve courante
  const indexOfLastTest = currentPage * postPerPage
  const indexOfFirstTest = indexOfLastTest - postPerPage
  const currentTest = dataTests.slice(indexOfFirstTest, indexOfLastTest)

  // Prendre en charge le chargement de la page
  function handelChangePage(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value)
  }

  useEffect(() => {
    setLoading(false)
    Axios.get(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/student/getSearchData`,
      {
        params: {
          id_: id,
          session: dataSearch.session,
          department: dataSearch.department,
          category: dataSearch.category,
        },
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          setDataTests(res.data)
          setLoading(true)
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          return
        }
      })
  }, [dataSearch])

  return (
    <>
      <Box sx={{ padding: { xs: '4px 16px', sm: 3 } }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ fontSize: '1.5rem', sm: '2.125rem' }}
        >
          Epreuves
        </Typography>
        <Divider />
      </Box>
      <StyledBoxSearch
        sx={{
          display: 'flex',
          justifyContent: { xs: 'inherit', sm: 'space-evenly' },
          flexDirection: { xs: 'column', sm: 'inherit' },
          gridGap: { xs: 5, sm: 0 },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Session"
            value={dataSearch.session}
            onChange={(newValue: any) => {
              setDataSearch({
                ...dataSearch,
                session: new Date(newValue).toISOString(),
              })
            }}
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField {...params} />
            )}
          />
        </LocalizationProvider>
        <Box
          sx={{
            display: { xs: 'flex', sm: 'none' },
            justifyContent: 'space-around',
            width: '100%',
          }}
        >
          <Autocomplete
            options={categoryArray}
            id="combo-box-demo"
            sx={{ width: 150 }}
            renderInput={(params) => (
              <TextField {...params} label="Catégorie" />
            )}
            onChange={(event, val) => {
              setDataSearch({ ...dataSearch, category: val })
            }}
          />
          <Autocomplete
            options={department.map((option) => option.name)}
            id="combo-box-demo"
            sx={{ width: 150 }}
            renderInput={(params) => (
              <TextField {...params} label="Département" />
            )}
            onChange={(event, val) => {
              setDataSearch({ ...dataSearch, department: val })
            }}
          />
        </Box>
        <Autocomplete
          options={categoryArray}
          id="combo-box-demo"
          sx={{ width: 150, display: { xs: 'none', sm: 'inherit' } }}
          renderInput={(params) => <TextField {...params} label="Catégorie" />}
          onChange={(event, val) => {
            setDataSearch({ ...dataSearch, category: val })
          }}
        />
        <Autocomplete
          options={department.map((option) => option.name)}
          id="combo-box-demo"
          sx={{ width: 150, display: { xs: 'none', sm: 'inherit' } }}
          renderInput={(params) => (
            <TextField {...params} label="Département" />
          )}
          onChange={(event, val) => {
            setDataSearch({ ...dataSearch, department: val })
          }}
        />
      </StyledBoxSearch>
      <Box>
        {!currentTest.length ? (
          <Box
            sx={{
              display: 'grid',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              component="img"
              src={no_result_img}
              alt="no-result"
              sx={{ width: { xs: '18rem', sm: 'initial' } }}
            />
            <Typography textAlign="center">
              Aucunes épreuves correspondantes
            </Typography>
          </Box>
        ) : (
          <>
            <Grid
              container
              spacing={{ xs: 0, md: 0 }}
              columns={{ xs: 4, sm: 8, md: 12, lg: 16 }}
            >
              {currentTest.map((testSheet, index) => (
                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  key={`${testSheet.id_epreuve}-${index}`}
                >
                  {loading ? (
                    <TestCard epreuve={testSheet} />
                  ) : (
                    <Box sx={{ padding: { xs: 1, md: 2 } }}>
                      <Skeleton width="95%" />
                      <Skeleton width="40%" />
                      <Skeleton
                        variant="rectangular"
                        width="90%"
                        height={118}
                      />
                      <Skeleton width="95%" />
                      <Skeleton width="40%" />
                    </Box>
                  )}
                </Grid>
              ))}
            </Grid>
            <AppPagination
              paginate={handelChangePage}
              postPerPage={postPerPage}
              count={count}
            />
          </>
        )}
      </Box>
    </>
  )
}

export default Epreuves
