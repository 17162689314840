// Made by Poukam Ngamaleu

import { Logout, Settings } from '@mui/icons-material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import HandshakeIcon from '@mui/icons-material/Handshake'
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../utils/context'

export const StyledLink = styled(Link)({
  color: 'inherit',
  textDecoration: 'none',
})

function MenuSettings({
  setIsDrawerOpen,
  setOpenNotif,
}: {
  setOpenNotif?: (bool: boolean) => void
  setIsDrawerOpen?: (bool: boolean) => void
}) {
  const [openElUser, setOpenElUser] = useState(null)
  const {
    userInfo: { is_partner, nom, profil_img },
    authDispatch,
  } = useAuth()

  const navigate = useNavigate()

  const handleOpenUserMenu = (event: any) => {
    setOpenElUser(event.currentTarget)
    setOpenNotif && setOpenNotif(false)
  }

  const handleCloseUserMenu = () => {
    setOpenElUser(null)
    if (setIsDrawerOpen) setIsDrawerOpen(false)
  }

  const disconnected = () => {
    authDispatch({
      accessToken: '',
      userInfo: {
        id: undefined,
        nom: '',
        prenom: '',
        email: '',
      },
    })
    navigate('/home')
  }

  return (
    <Box>
      <IconButton
        onClick={handleOpenUserMenu}
        sx={{ display: { xs: 'none', md: 'inline-flex' }, gap: '10px' }}
      >
        <Avatar alt={nom} src={profil_img} />
      </IconButton>
      <Button
        variant="text"
        endIcon={<ChevronRightIcon />}
        onClick={handleOpenUserMenu}
        sx={{ display: { xs: 'inline-flex', md: 'none' } }}
      >
        <Typography variant="body2">{nom}</Typography>
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={openElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(openElUser)}
        onClose={() => setOpenElUser(null)}
      >
        <MenuItem sx={{ display: 'flex' }} onClick={handleCloseUserMenu}>
          <GridViewRoundedIcon fontSize="small" />
          <Typography
            textAlign="center"
            component={StyledLink}
            to="/Dashboard"
            paddingLeft="10px"
          >
            Mon tableau de bord
          </Typography>
        </MenuItem>
        <MenuItem sx={{ display: 'flex' }} onClick={handleCloseUserMenu}>
          <Avatar sx={{ width: '1.1em', height: '1.1em' }} />
          <Typography
            textAlign="center"
            component={StyledLink}
            to="/Account"
            paddingLeft="10px"
          >
            Compte
          </Typography>
        </MenuItem>
        <Divider />
        {is_partner ? (
          <MenuItem sx={{ display: 'flex' }} onClick={handleCloseUserMenu}>
            <HandshakeIcon fontSize="small" />
            <Typography
              textAlign="center"
              component={StyledLink}
              to="/partner"
              paddingLeft="10px"
            >
              Partenaire
            </Typography>
          </MenuItem>
        ) : null}
        <MenuItem sx={{ display: 'flex' }} onClick={handleCloseUserMenu}>
          <CurrencyExchangeIcon fontSize="small" />
          <Typography
            textAlign="center"
            component={StyledLink}
            to="/souscription"
            paddingLeft="10px"
          >
            Abonnement
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ display: 'flex' }} onClick={handleCloseUserMenu}>
          <Settings fontSize="small" />
          <Typography
            textAlign="center"
            component={StyledLink}
            to="/Settings"
            paddingLeft="10px"
          >
            Paramètres
          </Typography>
        </MenuItem>
        <MenuItem sx={{ display: 'flex' }} onClick={disconnected}>
          <Logout fontSize="small" />
          <Typography textAlign="center" paddingLeft="15px">
            Déconnexion
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default MenuSettings
