// Made by Poukam Pierre

import { Box, Grid, Skeleton, Typography, Divider } from '@mui/material'
import Axios from 'axios'
import { useEffect, useState } from 'react'
import HistoryCard from '../../components/historyCard'
import AppPagination from '../../components/Pagination'
import { dateTestInterface } from '../../components/tests'
import { useAuth } from '../../utils/context'
import no_answer from '../../asset/no_answers-remove.png'

function History() {
  const {
    userInfo: { id },
  } = useAuth()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [dataTests, setDataTests] = useState<dateTestInterface[]>([
    {
      id_epreuve: '',
      libele_depart: '',
      date_session: '',
      libele_cat: '',
      armoiry: '',
      code_pays: 'cmr',
    },
  ])
  const postPerPage = 8

  const count = dataTests.length
  // Avoir l'épreuve courante
  const indexOfLastTest = currentPage * postPerPage
  const indexOfFirstTest = indexOfLastTest - postPerPage
  const currentTest = dataTests.slice(indexOfFirstTest, indexOfLastTest)

  // Prendre en charge le chargement de la page
  function handelChangePage(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value)
  }

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/student/getHistory/${id}`
    )
      .then((res) => {
        if (res?.status === 200 && res.data) {
          setDataTests(res.data)
          setLoading(true)
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setDataTests(err.response.data)
        }
      })
  }, [])

  return (
    <>
      <Box sx={{ padding: { xs: '4px 16px', sm: 3 } }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ fontSize: '1.5rem', sm: '2.125rem' }}
        >
          Historique
        </Typography>
        <Divider />
      </Box>
      {dataTests.length !== 0 ? (
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 0, md: 0 }}
            columns={{ xs: 4, sm: 8, md: 12, lg: 16 }}
          >
            {currentTest.map((testSheet, index) => (
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                key={`${testSheet.id_epreuve}-${index}`}
              >
                {loading ? (
                  <HistoryCard epreuve={testSheet} />
                ) : (
                  <Box sx={{ padding: { xs: 1, md: 2 } }}>
                    <Skeleton width="95%" />
                    <Skeleton width="40%" />
                    <Skeleton variant="rectangular" width="90%" height={118} />
                    <Skeleton width="95%" />
                    <Skeleton width="40%" />
                  </Box>
                )}
              </Grid>
            ))}
          </Grid>
          <AppPagination
            paginate={handelChangePage}
            postPerPage={postPerPage}
            count={count}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'grid',
            gridAutoFlow: 'row',
            justifyContent: 'center',
            gridGap: '4rem',
          }}
        >
          <Typography
            textAlign="center"
            paddingTop="2rem"
            sx={{ fontSize: { xs: '1rem', sm: '1.5rem' } }}
          >
            Aucune épreuve traitée pour le moment
          </Typography>
          <Box
            component="img"
            src={no_answer}
            alt="no-answers"
            sx={{ width: { xs: '20rem', sm: '50rem', md: '60rem' } }}
          />
        </Box>
      )}
    </>
  )
}

export default History
