// Made by Poukam Ngamaleu

import { Box } from '@mui/system'
import {
  AppBar,
  Button,
  Stack,
  styled,
  Toolbar,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { theme } from '../../../utils/style/theme'
import logo from '../../../asset/logoOnlinePreps.png'
import Drawers from '../../drawers'

export const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const StyledInscriptionButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.secondary.main,
}))

function ProspectHeader() {
  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: theme.common.Bgheader, padding: { xs: 0, sm: 1 } }}
    >
      <StyledToolbar>
        <Box component={Link} to="/">
          <Box
            component="img"
            sx={{ maxWidth: { xs: 150, sm: 200 } }}
            src={logo}
            alt="OnlinePreps"
          />
        </Box>
        <Drawers />
        <Stack
          spacing={1}
          direction="row"
          overflow="auto"
          sx={{ display: { xs: 'none', md: 'initial' } }}
        >
          <Button
            variant="outlined"
            component={Link}
            to="/SignIn"
            sx={{ borderColor: theme.common.actionbtn }}
          >
            <Typography variant="button" color="white">
              CONNEXION
            </Typography>
          </Button>
        </Stack>
      </StyledToolbar>
    </AppBar>
  )
}

export default ProspectHeader
