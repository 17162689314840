// Made by Poukam Ngamaleu

import Epreuves from './components/checkingTest'
import Contact from './components/contact'
import Error from './components/error'
import Compte from './components/user/account'
import Souscription from './components/user/account/Abonnement'
import DashBoard from './components/user/account/dashboard'
import Setting from './components/user/account/setting'
import PrepsAccount from './components/user/partner/account'
import History from './pages/examHistory'
import ResponseExam from './pages/examHistory/historyExam'
import Home from './pages/Home'
import ClientSkeleton from './pages/Home/clientHomeSkeleton'
import PartnerSkeleton from './pages/Home/partnerSkeleton'
import ProspectSkeleton from './pages/Home/prospectHomeSkeleton'
import ResetPassword from './pages/resetPassword'
import LogIn from './pages/signIn'
import SignUp from './pages/signUp'
import SignSkeleton from './pages/signUp/signUpSkeleton'
import TestingExam from './pages/testingPaper'
import PrivateRoutes from './utils/privateRoutes'
import PublicRoutes from './utils/publicRoutes'

export const routes = [
  {
    element: <PrivateRoutes />,
    children: [
      {
        path: '/',
        element: <ClientSkeleton />,
        children: [
          { path: '', element: <Home /> },
          { path: 'account', element: <Compte /> },
          { path: 'settings', element: <Setting /> },
          { path: 'dashboard', element: <DashBoard /> },
          { path: 'epreuves', element: <Epreuves /> },
          { path: 'history', element: <History /> },
          { path: 'contact', element: <Contact /> },
          { path: 'souscription', element: <Souscription /> },
          { path: 'exam/:id_epreuve', element: <TestingExam /> },
          {
            path: 'exam_review/:id_epreuve/:id_composition',
            element: <ResponseExam />,
          },
          { path: '*', element: <Error /> },
        ],
      },
      {
        path: '/partner',
        element: <PartnerSkeleton />,
        children: [
          { path: '', element: <Contact /> },
          { path: 'account', element: <PrepsAccount /> },
          { path: 'settings', element: <Setting /> },
          { path: 'souscription', element: <Souscription /> },
          { path: '*', element: <Error /> },
        ],
      },
    ],
  },
  {
    element: <PublicRoutes />,
    children: [
      {
        element: <ProspectSkeleton />,
        children: [
          { path: '/home', element: <Home /> },
          { path: 'contact', element: <Contact /> },
          { path: '*', element: <Error /> },
        ],
      },
      {
        element: <SignSkeleton />,
        children: [
          { path: '/signUp', element: <SignUp /> },
          { path: '/signIn', element: <LogIn /> },
          { path: '/resetPassword/:resetToken', element: <ResetPassword /> },
        ],
      },
    ],
  },
]

// Navigate
