// Made by Poukam Ngamaleu

import { Box, Paper, styled, Typography } from '@mui/material'
import { Composition } from '.'
import { useAuth } from '../../../../utils/context'

const StyledWelcome = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  lineHeight: '48px',
}))

function WelcomToDash({ examInfo }: { examInfo: Composition[] | undefined }) {
  const {
    userInfo: { nom, prenom },
  } = useAuth()

  let uniqueExamInfo: Composition[] = []

  examInfo?.map((infos, index) => {
    if (!uniqueExamInfo.length) {
      uniqueExamInfo.push(infos)
    } else {
      for (let i = 0; i < uniqueExamInfo.length; i++) {
        if (
          infos.libele_cat === uniqueExamInfo[i].libele_cat &&
          infos.libele_depart === uniqueExamInfo[i].libele_depart &&
          infos.date_session === uniqueExamInfo[i].date_session
        ) {
          break
        } else {
          if (
            i === uniqueExamInfo.length - 1 &&
            (infos.libele_cat !== uniqueExamInfo[i].libele_cat ||
              infos.libele_depart !== uniqueExamInfo[i].libele_depart ||
              infos.date_session !== uniqueExamInfo[i].date_session)
          ) {
            uniqueExamInfo.push(infos)
          }
        }
      }
    }
  })

  return (
    <Box
      sx={{ display: { xs: 'initial', md: 'flex' } }}
      justifyContent="space-evenly"
      p={3}
    >
      <Paper
        sx={{
          padding: { xs: '0', sm: 6 },
          textAlign: 'center',
          backgroundColor: 'transparent',
          marginBottom: { xs: '15px' },
        }}
        elevation={3}
      >
        <StyledWelcome
          variant="h4"
          sx={{
            fontSize: { xs: '0.8rem', sm: '1.5rem' },
            fontWeight: 400,
            marginBottom: { xs: 0, sm: '10px' },
          }}
        >
          BON RETOUR PARMI NOUS
        </StyledWelcome>
        <Typography
          variant="h4"
          sx={{ fontSize: { xs: '1rem', sm: '2rem' }, fontWeight: 'bold' }}
        >
          {nom.toUpperCase()} {prenom.toUpperCase()}
        </Typography>
      </Paper>
      <Paper
        sx={{
          padding: { xs: '0', sm: 6 },
          textAlign: 'center',
          backgroundColor: 'transparent',
        }}
        elevation={3}
      >
        <StyledWelcome
          variant="h4"
          sx={{
            fontSize: { xs: '0.8rem', sm: '1.5rem' },
            fontWeight: 400,
            marginBottom: { xs: 0, sm: '15px' },
          }}
        >
          EPREUVES TRAITEES
        </StyledWelcome>
        <Typography
          variant="h4"
          sx={{ fontSize: { xs: '1rem', sm: '2rem' }, fontWeight: 'bold' }}
        >
          {uniqueExamInfo.length}
        </Typography>
      </Paper>
    </Box>
  )
}

export default WelcomToDash
