// Made by Poukam Ngamaleu

import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Alert,
  Box,
  Checkbox,
  Fab,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Slide,
  SlideProps,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Axios from 'axios'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { alertMsgInterface } from '../../components/contact/SellerInformations'
import LinkedBtn from '../../components/linkedButton'
import { authInterface, useAuth } from '../../utils/context'
import { theme } from '../../utils/style/theme'
import { StyledLink } from '../signUp'
import ForgetPassWord from './forgetPassWord'
import { LoginSchema } from './schemaYup'

interface SignInformations {
  email: string
  password: string
  stayConnected: boolean
}
type TransitionProps = Omit<SlideProps, 'direction'>

function LogIn() {
  // Initialisation of states
  // const { params } = useParams()
  const [resetPassword, setResetPassword] = useState<boolean>(false)
  const [createdMsg, setCreatedMsg] = useState<alertMsgInterface>()
  const [open, setOpen] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)
  const { authDispatch } = useAuth()
  const navigate = useNavigate()
  const [value, setValue] = useState<boolean>(false)
  const [loading, setLoading] = useState<Boolean>(false)
  const { values, handleChange, handleSubmit, errors, touched, resetForm } =
    useFormik<SignInformations>({
      initialValues: {
        email: '',
        password: '',
        stayConnected: false,
      },
      onSubmit: (values) => {
        setLoading(true)
        Axios.post(
          `${process.env.REACT_APP_URL_REMOTE_LINK}/auth/login/client`,
          {
            email: values.email,
            password: values.password,
            stayConnected: values.stayConnected,
          }
        )
          .then((res) => {
            if (res?.status === 200 && res.data) {
              const data: authInterface = res.data as authInterface
              authDispatch(data)
              setLoading(false)
              navigate('/')
            }
          })
          .catch((err) => {
            if (err.response.status === 400) {
              setCreatedMsg({
                message: err.response.data.message,
                severity: 'error',
              })
              setOpen(true)
              setLoading(false)
            } else if (err.response.status === 401) {
              setCreatedMsg({
                message: err.response.data.message,
                severity: 'warning',
              })
              setOpen(true)
              setLoading(false)
              resetForm()
            } else {
              setCreatedMsg({
                message: 'Erreur serveur. Rééssayez plutard.',
                severity: 'error',
              })
              setOpen(true)
              setLoading(false)
              resetForm()
            }
          })
      },
      validationSchema: LoginSchema,
    })

  // Functions
  function handleClickShowPassword() {
    setValue(!value)
  }

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />
  }
  return (
    <Box
      sx={{
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Paper
        sx={{
          width: { xs: '100%', sm: '35em' },
          borderRadius: { xs: '0', sm: '40px' },
          backgroundColor: { xs: 'initial', sm: '#D7CBEC' },
          display: processing ? 'contents' : 'initial',
          elevation: { xs: 0, sm: 3 },
        }}
      >
        <Box
          display={!resetPassword ? 'flex' : 'none'}
          justifyContent="center"
          p={2}
          flexDirection="column"
          alignItems="center"
          gap="20px"
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ fontSize: { xs: '1.1rem', sm: '1.5rem' } }}
          >
            BON RETOUR PARMI NOUS
          </Typography>
          <Typography>
            Êtes vous déjà inscrit?{' '}
            <StyledLink to="/SignUp">Inscription</StyledLink>
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <Box sx={{ padding: { xs: 0, sm: '0 40px' } }}>
              <TextField
                id="outlined-basic"
                label="Email"
                type="text"
                name="email"
                value={values.email}
                onChange={handleChange}
                variant="outlined"
                sx={{ width: '100%', marginBottom: '15px' }}
                {...(errors.email && touched.email
                  ? { error: true, helperText: errors.email }
                  : '')}
              />
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Mot de passe
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={value ? 'text' : 'password'}
                  onChange={handleChange}
                  value={values.password}
                  name="password"
                  {...(errors.password && touched.password
                    ? { error: true }
                    : '')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {value ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {errors.password && touched.password ? (
                  <FormHelperText error>{errors.password}</FormHelperText>
                ) : (
                  ''
                )}
              </FormControl>
              <FormControlLabel
                control={<Checkbox />}
                label="Se rappeler de moi"
                name="stayConnected"
                value={values.stayConnected}
                onChange={handleChange}
                sx={{ marginTop: '15px' }}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              margin="25px 0 25px 0"
              sx={{ position: 'relative' }}
            >
              <Fab
                sx={{ backgroundColor: theme.palette.secondary.main }}
                variant="extended"
                aria-label="add"
                type="submit"
                disabled={loading ? true : false}
              >
                <Typography variant="button" paddingLeft="5px">
                  CONNEXION
                </Typography>
              </Fab>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography
                sx={{
                  cursor: 'pointer',
                  color: '#555',
                  textDecoration: 'underline',

                  '&:hover': {
                    color: theme.palette.secondary.main,
                  },
                }}
                onClick={() => setResetPassword(true)}
              >
                Mot de passe oublié?
              </Typography>
            </Box>
          </Box>
          <LinkedBtn />
        </Box>
        <ForgetPassWord
          display={resetPassword}
          setDisplay={setResetPassword}
          processing={processing}
          setProcessing={setProcessing}
        />
      </Paper>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={TransitionUp}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={createdMsg?.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {createdMsg?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default LogIn
