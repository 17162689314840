// Made by Poukam Ngamaleu

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import { Box, Divider, Paper, Typography } from '@mui/material'
import { Composition } from '.'

function StatisticBoard({ examInfo }: { examInfo: Composition[] | undefined }) {
  const data: { date: string; note: number }[] | undefined = examInfo?.map(
    ({ note, date_composition }) => ({
      date: date_composition,
      note: note,
    })
  )
  return (
    <Box sx={{ padding: { xs: 0, sm: 3 } }}>
      <Paper
        sx={{
          padding: 2,
          backgroundColor: 'transparent',
          height: data ? '20rem' : 'initial',
        }}
        elevation={3}
      >
        <Typography variant="h6">Progression de capacités</Typography>
        <Divider />
        <Box display={data ? 'initial' : 'none'}>
          <ResponsiveContainer width="100%" height="90%">
            <AreaChart
              width={730}
              height={250}
              data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="note" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="date" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="note"
                stroke="#8884d8"
                fillOpacity={1}
                fill="url(#note)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </Paper>
    </Box>
  )
}

export default StatisticBoard
