import { Box } from '@mui/material'
import { NavLink } from 'react-router-dom'

function NavItem({
  label,
  end,
  to,
}: {
  label: string
  end?: boolean
  to?: string
}) {
  return (
    <Box
      to={to ?? '/'}
      end={end ?? true}
      component={NavLink}
      sx={{
        position: 'relative',
        color: 'white',
        textDecoration: 'none',
        fontWeight: '500',
        display: 'grid',
        alignItems: 'center',
        fontSize: '0.876rem',
        justifyItems: 'center',
        opacity: 0.6,
        '&::after': {
          content: '""',
          width: '100%',
          transform: 'scaleX(120%)',
          backgroundColor: 'white',
          height: '0',
          position: 'absolute',
          bottom: '-10px',
          left: 0,
        },
        '&.active': {
          opacity: 1,
          '&::after': {
            height: '2px',
          },
        },
      }}
    >
      {label}
    </Box>
  )
}

export default NavItem
