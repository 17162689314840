// Made by Poukam Ngamaleu

import {
  Box,
  Button,
  Divider,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { theme } from '../../../../utils/style/theme'
import { ChangePasswordSchema } from './changePassworSchema'
import Axios from 'axios'
import { alertMsgInterface } from '../../../contact/SellerInformations'
import { useAuth } from '../../../../utils/context'

function ChangePassWord({
  id_,
  createMsg,
  setOpen,
}: {
  id_: number
  createMsg: ({ message, severity }: alertMsgInterface) => void
  setOpen: (bool: boolean) => void
}) {
  const { accessToken } = useAuth()
  const authAxios = Axios.create({
    baseURL: `${process.env.REACT_APP_URL_REMOTE_LINK}/common`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  const { values, handleSubmit, handleChange, errors, touched, resetForm } =
    useFormik({
      initialValues: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      onSubmit: (values) => {
        //TODO Ici entre l'appelle API REST
        authAxios
          .put(`/resetPassword/${id_}`, values)
          .then((res) => {
            if (res?.status === 200) {
              createMsg({
                message: res.data.message,
                severity: 'success',
              })
              resetForm()
              setOpen(true)
            }
          })
          .catch((err) => {
            if (err.response.status === 400) {
              createMsg({
                message: err.response.data.message,
                severity: 'warning',
              })
              setOpen(true)
            } else {
              createMsg({
                message: err.response.data.message,
                severity: 'error',
              })
              setOpen(true)
            }
          })
      },
      validationSchema: ChangePasswordSchema,
    })

  return (
    <Box sx={{ padding: { xs: '8px 0', sm: 2 } }}>
      <Paper
        sx={{
          padding: 2,
          backgroundColor: 'transparent',
        }}
        elevation={3}
      >
        <Typography
          textAlign="center"
          variant="h5"
          fontWeight="bold"
          sx={{ fontSize: { xs: '1.1rem', sm: '2rem' } }}
        >
          CHANGER DE MOT DE PASSE
        </Typography>
        <Divider />
        <Box
          sx={{
            display: { xs: 'grid', sm: 'flex' },
            alignItems: 'center',
            justifyContent: 'space-around',
            gap: { xs: 3, sm: 'initial' },
          }}
          p={2}
          onSubmit={handleSubmit}
          component="form"
        >
          <Box sx={{ display: 'grid', gap: '15px' }}>
            <TextField
              name="oldPassword"
              label="Ancien mot de passe"
              type="password"
              autoComplete="current-password"
              sx={{ width: { xs: 250, sm: 300 } }}
              value={values.oldPassword}
              onChange={handleChange}
              {...(errors.oldPassword && touched.oldPassword
                ? { error: true, helperText: errors.oldPassword }
                : '')}
            />
            <TextField
              name="newPassword"
              label="Nouveau mot de passe"
              type="password"
              autoComplete="new-password"
              sx={{ width: { xs: 250, sm: 300 } }}
              value={values.newPassword}
              onChange={handleChange}
              {...(errors.newPassword && touched.newPassword
                ? { error: true, helperText: errors.newPassword }
                : '')}
            />
            <TextField
              name="confirmPassword"
              label="Reécrire nouveau mot de passe"
              type="password"
              autoComplete="retyping-new-password"
              sx={{ width: { xs: 250, sm: 300 } }}
              value={values.confirmPassword}
              onChange={handleChange}
              {...(errors.confirmPassword && touched.confirmPassword
                ? { error: true, helperText: errors.confirmPassword }
                : '')}
            />
          </Box>
          <Button
            variant="outlined"
            type="submit"
            sx={{ borderColor: theme.palette.secondary.main }}
          >
            <Typography variant="button" color="black">
              ENREGISTRER
            </Typography>
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}

export default ChangePassWord
