// Made by Poukam Ngamaleu

import { Box, Button, Divider, Paper, Typography } from '@mui/material'
import Axios from 'axios'
import { useEffect, useState } from 'react'
import { useAuth } from '../../../utils/context'
import { theme } from '../../../utils/style/theme'
import { alertMsgInterface } from '../../contact/SellerInformations'
import ModalPayment from './account/modalPayment'
import TransactionTable from './account/tableTransation'

interface commissionInterface {
  commission: number
  montant: number
}

function AmountTable({
  setCreatedMsg,
  setOpenS,
}: {
  setCreatedMsg: ({ message, severity }: alertMsgInterface) => void
  setOpenS: (bool: boolean) => void
}) {
  const [open, setOpen] = useState<boolean>(false)
  const {
    userInfo: { id },
  } = useAuth()
  const [commission, setCommission] = useState<commissionInterface>()

  useEffect(() => {
    // TODO change link request
    Axios.get(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/partner/getCommission/${id}`
    )
      .then((res) => {
        setCommission({
          commission: res.data.commission,
          montant: res.data.montant,
        })
      })
      .catch((err) => {
        if (err) {
          setCreatedMsg({
            message: err.response.data.message,
            severity: 'error',
          })
          setOpenS(true)
        }
      })
  }, [open])

  return (
    <Box
      component={Paper}
      sx={{
        padding: { xs: 1, md: 2 },
        textAlign: 'center',
        backgroundColor: 'transparent',
        marginBottom: { xs: '15px' },
      }}
      elevation={3}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        paddingBottom="5px"
      >
        <Box sx={{ display: { xs: 'initial', sm: 'inline-flex' } }}>
          <Typography
            variant="h6"
            sx={{ fontSize: { xs: '1rem', md: '1.5rem' } }}
          >
            GAIN({commission?.commission}%) :
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontSize: { xs: '1rem', md: '1.5rem' }, paddingLeft: '10px' }}
          >
            {commission?.montant} frs CFA
          </Typography>
        </Box>
        <Button
          variant="outlined"
          sx={{
            borderColor: theme.palette.secondary.main,
          }}
          onClick={() => setOpen(true)}
        >
          <Typography color="black" variant="button">
            RETRAIT
          </Typography>
        </Button>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="center" height={420} padding="15px 0">
        <TransactionTable
          setCreatedMsg={setCreatedMsg}
          setOpen={setOpenS}
          open={open}
        />
      </Box>
      <ModalPayment
        open={open}
        setOpen={setOpen}
        createMsg={setCreatedMsg}
        openS={setOpenS}
      />
    </Box>
  )
}

export default AmountTable
