// Made by Poukam Ngamaleu

import * as yup from 'yup'

export const SearchTestSchema = yup.object().shape({
  categoryPermis: yup
    .string()
    .required('Choix de permis obligatoire')
    .nullable(),
  pays: yup.string().required('Choix obligatoire'),
  departement: yup.string().required('Choix obligatoire'),
  session: yup.string().required("Entrez la session d'examen"),
})
