//  Made by Poukam ngamaleu

import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import MailRoundedIcon from '@mui/icons-material/MailRounded'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { theme } from '../../../utils/style/theme'

interface contactInformations {
  icon: JSX.Element
  contactInf: string
  displayOnExtraSmall?: boolean
}

const contacts: contactInformations[] = [
  {
    icon: <PhoneRoundedIcon sx={{ color: '#F5FA05' }} />,
    contactInf: '+237 680 090 489',
  },
  {
    icon: <FacebookRoundedIcon sx={{ color: '#F5FA05' }} />,
    contactInf: 'Poukam Tech SARL',
  },
  {
    icon: <WhatsAppIcon sx={{ color: '#F5FA05' }} />,
    contactInf: '+237 696 841 451',
  },
  {
    icon: <MailRoundedIcon sx={{ color: '#F5FA05' }} />,
    contactInf: 'onlinepreps22@gmail.com',
    displayOnExtraSmall: true,
  },
]

function ContactUs() {
  return (
    <List
      sx={{
        paddingTop: 0,
        paddingBottom: 0,
        justifySelf: 'center',
      }}
    >
      {contacts.map(({ icon, contactInf, displayOnExtraSmall }, index) => (
        <ListItem
          key={`${index}`}
          sx={{
            padding: 0,
            display: {
              sm: 'flex',
              xs: displayOnExtraSmall ? 'none' : 'flex',
            },
          }}
        >
          <ListItemIcon sx={{ color: theme.palette.secondary.contrastText }}>
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={contactInf}
            primaryTypographyProps={{ fontSize: '18px' }}
          />
        </ListItem>
      ))}
    </List>
  )
}

export default ContactUs
