// Made by Poukam Ngamaleu

import { Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import StatisticBoard from './statisticBoard'
import TableTestDone from './tableTestDone'
import WelcomToDash from './WelcomToDashboard'
import { useEffect, useState } from 'react'
import Axios from 'axios'
import { useAuth } from '../../../../utils/context'

export interface Composition {
  id_composition: number
  date_composition: string
  id_epreuve: string
  note: number
  libele_cat: string
  libele_depart: string
  date_session: string
}

function DashBoard() {
  const {
    userInfo: { id },
  } = useAuth()
  const [examInfos, setExamInfo] = useState<Composition[]>()

  useEffect(() => {
    // TODO change local link to remote link
    Axios.get(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/student/getExamInfos/${id}`
    )
      .then((res) => {
        if (res?.status === 200 && res.data) {
          setExamInfo(res.data)
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          return
        }
      })
  }, [])

  return (
    <Box sx={{ padding: { xs: 0.5, sm: 3 } }}>
      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{ fontSize: '1.5rem', sm: '2.125rem' }}
      >
        TABLEAU DE BORD
      </Typography>
      <Divider />
      <WelcomToDash examInfo={examInfos} />
      <StatisticBoard examInfo={examInfos} />
      <TableTestDone examInfo={examInfos} />
    </Box>
  )
}

export default DashBoard
