import App from './App'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import AuthContextProvider from './utils/context'

const GlobalStyled = createGlobalStyle`
*{
  box-sizing: border-box;
}
body{
  margin:0;
  background-color: #FAF4F4;
  margin:0;
  padding:0;
  outline:none
}
`

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi) 
  .init({
    supportedLngs: ['fr', 'en'],
    fallbackLng: 'fr',
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 's ubdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    react: { useSuspense: false },
  })

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <AuthContextProvider>
    <BrowserRouter>
      <GlobalStyled />
      <App />
    </BrowserRouter>
  </AuthContextProvider>
)
