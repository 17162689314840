// Made by Poukam Ngamaleu

import { Outlet, useNavigate, useLocation } from 'react-router'
import { useAuth } from '../../utils/context'
import { useEffect } from 'react'

function PrivateRoutes() {
  const {
    userInfo: { is_student, is_partner },
  } = useAuth()

  const navigate = useNavigate()
  const activeLocation = useLocation()

  useEffect(() => {
    if (is_student) {
      if (activeLocation.pathname === '/') {
        navigate('/')
      }
      navigate(activeLocation.pathname)
    } else if (is_partner) {
      if (activeLocation.pathname === '/') {
        navigate('/partner')
      } else if (activeLocation.pathname === '/partner') {
        navigate(activeLocation.pathname)
      } else return
    } else navigate('/home')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <Outlet />
}

export default PrivateRoutes
