// Made by poukam Pierre

import { Avatar, Divider, Paper, Typography } from '@mui/material'
import { fileImage } from '.'
import { theme } from '../../../utils/style/theme'
import { useAuth } from '../../../utils/context'
import { useState } from 'react'

function ProfilInf({
  files,
  setFile,
}: {
  files: fileImage
  setFile: (file: fileImage) => void
}) {
  const {
    userInfo: { nom, prenom, profil_img },
  } = useAuth()

  const [visualizeImg, setVisualizeImg] = useState<string>(
    profil_img ? profil_img : files.previewImage
  )

  function photoUpload(e: any, files: fileImage) {
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onloadend = () => {
      var fileImg = { ...files }
      fileImg.file = file
      setVisualizeImg(reader.result as string)
      setFile(fileImg)
    }
    reader.readAsDataURL(file)
  }

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
        backgroundColor: 'transparent',
        gap: '15px',
        height: 'fit-content',
        marginBottom: { xs: '20px' },
      }}
      elevation={3}
    >
      <label htmlFor="file">
        <Avatar
          sx={{ width: 100, height: 100 }}
          src={visualizeImg}
          alt="image de profil"
        />
      </label>
      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}
        textAlign="center"
      >
        {nom.toUpperCase()} {prenom.toUpperCase()}
      </Typography>
      <Divider
        sx={{
          alignSelf: 'normal',
        }}
      />
      {files.file === '' ? (
        <>
          <label htmlFor="file">
            <Typography
              variant="button"
              sx={{ cursor: 'pointer', color: theme.palette.secondary.main }}
            >
              Changer de photo
            </Typography>
          </label>
        </>
      ) : (
        <Typography
          sx={{ cursor: 'pointer', color: theme.palette.secondary.main }}
        >
          Validez sur ENREGISTRER
        </Typography>
      )}
      <input
        type="file"
        id="file"
        style={{ display: 'none' }}
        onChange={(e) => photoUpload(e, files)}
      />
    </Paper>
  )
}

export default ProfilInf
