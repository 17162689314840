// Made by Poukam Ngamaleu

import { Box, Fab, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import student from '../../../asset/learner.svg'
import logo from '../../../asset/onlinepreps_logo_mono.png'
import { useAuth } from '../../../utils/context'
import { theme } from '../../../utils/style/theme'

function FooterActionCall() {
  const {
    userInfo: { is_student },
  } = useAuth()
  return (
    <Box
      sx={{
        backgroundColor: theme.common.titleActive,
        display: { xs: 'block', md: 'grid' },
        gridTemplateColumns: '2fr 2fr',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        borderTop: '1px solid #dad9dc',
      }}
      p={3}
    >
      <Box
        component="img"
        src={student}
        alt="car_image"
        sx={{
          justifySelf: 'center',
          width: { xs: '100%', sm: '60%' },
          alignSelf: 'center',
          paddingTop: { xs: 3, sm: 0 },
        }}
      />

      <Box
        sx={{
          display: 'grid',
          gridGap: '10px',
          width: { xs: 'initial', sm: '40rem' },
        }}
      >
        <Box display="flex" gap="3%" padding="10px 0 ">
          <Box
            component="img"
            src={logo}
            alt="car_image"
            sx={{
              justifySelf: 'center',
              width: { xs: '20%', sm: '20%' },
              alignSelf: 'center',
            }}
          />

          <Typography
            alignSelf="center"
            fontWeight="bold"
            sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}
          >
            Nos étudiants témoignent
          </Typography>
        </Box>
        <Typography fontWeight="bold" fontSize="1.2rem">
          Auto-école LA COLOMBE
        </Typography>
        <Typography fontWeight="bold" fontSize="1rem" paddingBottom="5px">
          Candidats au permis de conduire de la session du 11 juin 2023
        </Typography>
        <Typography textAlign="justify" paddingBottom="10px">
          <strong>``</strong>La formation m'a beaucoup plu parce qu'elle était
          très adaptée à mes contraintes de temps et d'organisation! Tout est
          fait à distance mais je me sens quand même très accompagnée, parce que
          chaque semaine j'avais rendez-vous à l'auto-école, on est suivi tout
          le temps.<strong>``</strong>
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          component={Link}
          sx={{
            textDecoration: 'none',
            color: theme.palette.primary.contrastText,
            width: 'fit-content',
          }}
          to={is_student ? '/epreuves' : '/signIn'}
        >
          <Fab
            sx={{
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '10px',
              width: 'fit-content',
            }}
            variant="extended"
          >
            <Typography variant="button" paddingLeft="5px" fontWeight="600">
              DEMARRER MA FORMATION
            </Typography>
          </Fab>
        </Box>
      </Box>
    </Box>
  )
}

export default FooterActionCall
