//  Made by Poukam Ngamaleu
import Footer from '../../components/footer'
import Header from '../../components/Header'
import { Outlet } from 'react-router'
import { Box } from '@mui/material'
import { useAuth } from '../../utils/context'
import Scrollbars, { positionValues } from 'react-custom-scrollbars'
import { useRef, useState } from 'react'

function ClientSkeleton() {
  const {
    userInfo: { is_student },
  } = useAuth()
  const [showButton, setShowButton] = useState<boolean>(false)
  const scrollbarRef = useRef<Scrollbars>(null)
  const handleScrollButtonVisibility = (values: positionValues) => {
    if (values.top > 0.1) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }

  const handScrollToTop = () => {
    scrollbarRef.current?.scrollToTop()
  }
  const handScrollToButtom = () => {
    scrollbarRef.current?.scrollToBottom()
  }
  return (
    is_student && (
      <Box
        sx={{
          height: '100vh',
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
        }}
      >
        <Header />
        <Scrollbars
          universal
          autoHide
          onScrollFrame={(values) => handleScrollButtonVisibility(values)}
          ref={scrollbarRef}
        >
          <Outlet context={[showButton, handScrollToTop, handScrollToButtom]} />
        </Scrollbars>
        <Footer />
      </Box>
    )
  )
}

export default ClientSkeleton
