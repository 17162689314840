// Made by Poukam Ngamaleu

import {
  Autocomplete,
  Box,
  Fab,
  CircularProgress,
  TextField,
  Typography,
  AlertColor,
} from '@mui/material'
import Axios from 'axios'
import { useFormik } from 'formik'
import { useState } from 'react'
import { testSellInterface } from '.'
import { countries, department } from '../../utils/dataWorking'
import { theme } from '../../utils/style/theme'
import { SearchTestSchema } from './SearchTestSchema'

function SearchTest({
  setInfo,
  infos,
  setTestInfo,
  setOpen,
  setCreatedMsg,
}: {
  setInfo: (info: boolean) => void
  infos: boolean
  setTestInfo: ({
    pays,
    categoryPermis,
    department,
    session,
  }: testSellInterface) => void
  setOpen: (bool: boolean) => void
  setCreatedMsg: ({
    message,
    severity,
  }: {
    message: string
    severity: AlertColor | undefined
  }) => void
}) {
  const {
    handleChange,
    values,
    errors,
    setFieldValue,
    touched,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      pays: '',
      departement: '',
      categoryPermis: 'B',
      session: '',
    },
    onSubmit: (values) => {
      // TODO change local link to remote link
      setLoading(true)
      Axios.post(`${process.env.REACT_APP_URL_REMOTE_LINK}/info/epreuve`, {
        pays: values.pays,
        department: values.departement,
        category: values.categoryPermis,
        session: values.session,
      })
        .then((res) => {
          if (res?.status === 200) {
            setInfo(true)
            setCreatedMsg({
              message: res.data.message,
              severity: 'warning',
            })
            setOpen(true)
            setLoading(false)
            setTestInfo({
              pays: values.pays,
              department: values.departement,
              categoryPermis: values.categoryPermis,
              session: values.session,
            })
            resetForm()
          }
        })
        .catch((err) => {
          if (err.response.status === 302) {
            setCreatedMsg({
              message: err.response.data.message,
              severity: 'warning',
            })
            setOpen(true)
            setLoading(false)
          } else if (err.response.status === 404) {
            setCreatedMsg({
              message: err.response.data.message,
              severity: 'warning',
            })
            setOpen(true)
            setLoading(false)
          } else {
            return
          }
        })
    },
    validationSchema: SearchTestSchema,
  })

  const [loading, setLoading] = useState<boolean>(false)

  return (
    <fieldset>
      <legend>
        <Typography fontSize="small">INFORMATIONS DE L'EPREUVE</Typography>
      </legend>
      <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: 'initial', sm: '1fr 1fr' },
            gridGap: '10px',
          }}
        >
          <Autocomplete
            id="pays"
            sx={{ width: '100%' }}
            options={countries}
            onChange={(event, val) => setFieldValue('pays', val?.label)}
            autoHighlight
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.label} ({option.code})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pays"
                inputProps={{
                  ...params.inputProps,
                }}
                {...(errors.pays && touched.pays
                  ? { error: true, helperText: errors.pays }
                  : '')}
              />
            )}
          />

          <Autocomplete
            id="departement"
            options={
              values.pays === 'Cameroun'
                ? department.map((option) => option.name)
                : []
            }
            onChange={(event, val) => setFieldValue('departement', val)}
            value={values.departement}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Département"
                {...(errors.departement && touched.departement
                  ? { error: true, helperText: errors.departement }
                  : '')}
              />
            )}
          />
          <Autocomplete
            disablePortal
            options={['A', 'B', 'C', 'D', 'E', 'G', 'T']}
            id="categoryPermis"
            onChange={(event, val) => setFieldValue('categoryPermis', val)}
            value={values.categoryPermis}
            isOptionEqualToValue={(option, value) => option === value}
            sx={{ width: '100%' }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Catégorie"
                name="categoryPermis"
                {...(errors.categoryPermis && touched.categoryPermis
                  ? { error: true, helperText: errors.categoryPermis }
                  : '')}
              />
            )}
          />

          <TextField
            id="outlined-basic"
            type="date"
            label="Session"
            name="session"
            onChange={handleChange}
            value={values.session}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            sx={{ width: '100%' }}
            {...(errors.session && touched.session
              ? { error: true, helperText: errors.session }
              : '')}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          paddingTop="25px"
          sx={{ position: 'relative' }}
        >
          <Fab
            sx={{ backgroundColor: theme.palette.secondary.main }}
            variant="extended"
            aria-label="add"
            type="submit"
            disabled={infos || loading ? true : false}
          >
            <Typography variant="button" paddingLeft="5px">
              RECHERCHER
            </Typography>
          </Fab>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '65%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </Box>
    </fieldset>
  )
}

export default SearchTest
