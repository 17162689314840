// Made By Poukam Ngamaleu

import {
  Alert,
  Box,
  Grid,
  Skeleton,
  Slide,
  SlideProps,
  Snackbar,
} from '@mui/material'
import Axios from 'axios'
import { useEffect, useState } from 'react'
import { useAuth } from '../../utils/context'
import TestCard from '../CardTest'
import { alertMsgInterface } from '../contact/SellerInformations'
import AppPagination from '../Pagination'

export interface dateTestInterface {
  id_epreuve: string
  libele_depart: string
  date_session: string
  libele_cat: string
  armoiry: string
  code_pays: string
  eligibleTest?: boolean
  examinate?: boolean
  id_composition?: number
  date_composition?: string
}

type TransitionProps = Omit<SlideProps, 'direction'>

function Tests() {
  const {
    userInfo: { id },
  } = useAuth()
  const [dataTests, setDataTests] = useState<dateTestInterface[]>([
    {
      id_epreuve: '',
      libele_depart: '',
      date_session: '',
      libele_cat: '',
      armoiry: '',
      code_pays: 'cmr',
    },
  ])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [eligibleSouscription, setEligibleSouscription] =
    useState<boolean>(false)
  const [createdMsg, setCreatedMsg] = useState<alertMsgInterface>()
  const postPerPage = 8

  useEffect(() => {
    // TODO changing local link to remote link
    if (id) {
      Axios.get(
        `${process.env.REACT_APP_URL_REMOTE_LINK}/student/epreuves/${id}`
      )
        .then((res) => {
          if (res?.status === 200 && res.data) {
            setDataTests(res.data)
            setLoading(true)
            setEligibleSouscription(true)
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            Axios.get(
              `${process.env.REACT_APP_URL_REMOTE_LINK}/student/testExam/${id}`
            )
              .then((res) => {
                if (res?.status === 200 && res.data) {
                  setDataTests(res.data)
                  setLoading(true)
                }
              })
              .catch((err) => {
                if (err.response.status === 404) {
                  setCreatedMsg({
                    message: err.response.data.message,
                    severity: 'info',
                  })
                }
                setOpen(true)
              })
          }
        })
    } else {
      Axios.get(`${process.env.REACT_APP_URL_REMOTE_LINK}/student/epreuves`)
        .then((res) => {
          if (res?.status === 200 && res.data) {
            setDataTests(res.data)
            setLoading(true)
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setCreatedMsg({
              message: err.response.data.message,
              severity: 'info',
            })
          }
          setOpen(true)
        })
    }
  }, [])

  const count = dataTests.length
  // Avoir l'épreuve courante
  const indexOfLastTest = currentPage * postPerPage
  const indexOfFirstTest = indexOfLastTest - postPerPage
  const currentTest = dataTests.slice(indexOfFirstTest, indexOfLastTest)

  // Prendre en charge le chargement de la page
  function handelChangePage(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value)
  }

  const [open, setOpen] = useState(false)

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />
  }

  return (
    <Box sx={{ flexGrow: 1, padding: { xs: 0, sm: 7 } }}>
      <Grid
        container
        spacing={{ xs: 0, md: 0 }}
        columns={{ xs: 4, sm: 8, md: 12, lg: 16 }}
      >
        {currentTest.map((testSheet, index) => (
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            key={`${testSheet.id_epreuve}-${index}`}
          >
            {loading ? (
              <TestCard
                epreuve={testSheet}
                souscription={eligibleSouscription}
              />
            ) : (
              <Box sx={{ padding: { xs: 1, md: 2 } }}>
                <Skeleton width="95%" />
                <Skeleton width="40%" />
                <Skeleton variant="rectangular" width="90%" height={118} />
                <Skeleton width="95%" />
                <Skeleton width="40%" />
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
      <AppPagination
        paginate={handelChangePage}
        postPerPage={postPerPage}
        count={count}
      />
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={TransitionUp}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={createdMsg?.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {createdMsg?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Tests
