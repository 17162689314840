// Made by Poukam Ngamaleu

import {
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { dateTestInterface } from '../tests'
import ModalTest from './modalTest'

function TestCard({
  epreuve: {
    id_epreuve,
    date_session,
    libele_depart,
    libele_cat,
    armoiry,
    code_pays,
    eligibleTest,
    examinate,
  },
  souscription,
}: {
  epreuve: dateTestInterface
  souscription?: boolean
}) {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <Box p={2}>
        <Badge
          badgeContent={examinate ? 'composé' : 0}
          color="secondary"
          sx={{
            '& .MuiBadge-badge': {
              transform: 'scale(1) translate(0%, 8%)',
            },
            display: 'inherit',
          }}
        >
          <Card
            elevation={2}
            sx={{ cursor: 'pointer' }}
            onClick={() => setOpen(true)}
          >
            <CardHeader
              title="Epreuve permis de conduire"
              subheader={`Catégorie ${libele_cat}`}
            />
            <CardMedia
              component="img"
              height="194"
              image={armoiry}
              alt="armoirie du cmr"
              sx={{ objectFit: 'contain' }}
            />
            <CardContent sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Typography variant="body2" color="text.secondary">
                Epreuve d’examen du permis de conduire dans la catégorie{' '}
                {libele_cat} réalisé dans le centre de {libele_depart}
              </Typography>
            </CardContent>
          </Card>
        </Badge>
      </Box>
      <ModalTest
        open={open}
        setOpen={setOpen}
        session={date_session}
        departmentName={libele_depart}
        category={libele_cat}
        departmentCountry={code_pays}
        eligibleSous={souscription}
        epreuve_ref={`/Exam/${id_epreuve}`}
        eligibleTest={eligibleTest}
      />
    </>
  )
}

export default TestCard
