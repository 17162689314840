// Made by Poukam Ngamaleu

import * as yup from 'yup'

export const PartnerSchema = yup.object().shape({
  nom: yup.string().required('Nom obligatoire'),
  prenom: yup.string(),
  email: yup
    .string()
    .email('Entrer une email valide')
    .required('Email obligatoire'),
  password: yup
    .string()
    .min(5, 'minimum 5 caractères')
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/, {message: ' 1 magiscule, 1 minuscule et 1 chiffre'})
    .required('Mot de passe obligatoire'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Mot de passe non identique')
    .required('Entrer votre mot de passe'),
  training_center: yup.string(),
})
