//  Made by Ngamaleu Poukam Pierre Irénée
import { createTheme } from '@mui/material'
import React from 'react'

declare module '@mui/material/styles' {
  interface Theme {
    common: {
      // line: React.CSSProperties['color']
      // inputBackground: React.CSSProperties['color']
      // adminElement: React.CSSProperties['color']
      Bgheader: React.CSSProperties['color']
      actionbtn: React.CSSProperties['color']
      notification: React.CSSProperties['color']
      // adminBackground: React.CSSProperties['color']
      // offWhite: React.CSSProperties['color']
      // placeholder: React.CSSProperties['color']
      // label: React.CSSProperties['color']
      // body: React.CSSProperties['color']
      titleActive: React.CSSProperties['color']
      // dialogBackground: React.CSSProperties['color']
    }
  }
  interface ThemeOptions {
    common: {
      // line: React.CSSProperties["color"];
      // inputBackground: React.CSSProperties["color"];
      // adminElement: React.CSSProperties["color"];
      Bgheader: React.CSSProperties['color']
      actionbtn: React.CSSProperties['color']
      notification: React.CSSProperties['color']
      // adminBackground: React.CSSProperties["color"];
      // offWhite: React.CSSProperties["color"];
      // placeholder: React.CSSProperties["color"];
      // label: React.CSSProperties["color"];
      // body: React.CSSProperties["color"];
      titleActive: React.CSSProperties['color']
      // dialogBackground: React.CSSProperties["color"]
    }
  }
  interface TypographyVariants {
    h1: React.CSSProperties
    h2: React.CSSProperties
    h3: React.CSSProperties
    body1: React.CSSProperties
    body2: React.CSSProperties
    caption: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1: React.CSSProperties
    h2: React.CSSProperties
    h3: React.CSSProperties
    body1: React.CSSProperties
    body2: React.CSSProperties
    caption: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1: true
    h2: true
    h3: true
    body1: true
    body2: true
    caption: true
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#673AB7',
      light: '#8561c5',
      dark: '#482880',
      contrastText: '#061D25',
    },
    secondary: {
      main: '#00E5FF',
      light: '#33eaff',
      dark: '#00a0b2',
      contrastText: '#fff',
    },
  },
  common: {
    Bgheader: '#0D4051',
    actionbtn: '#00E5FF',
    notification: '#1a7ea0',
    titleActive: 'white',
  },
})

// thin: 100
// extraLight: 200
// light: 300
// regular: 400
// medium: 500
// semiBold: 600
// bold: 700
// extraBold: 800
// black: 900
// 16px => 1rem

// const PRIMARY = '#FF577D'
// const LINE = '#D9D9D9'

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: PRIMARY,
//     },
//     secondary: {
//       main: "#FFD93B",
//     },
//     error: {
//       main: "#CB0707",
//     },
//     success: {
//       main: "#1D83BC",
//     },
//   },
//   common: {
//     line: LINE,
//     inputBackground: "#F4F5F7",
//     background: "#E8EFF2",
//     adminBackground: "#131313",
//     adminElement: "#1D1D1D",
//     offWhite: "#A0A0A0",
//     placeholder: LINE,
//     label: LINE,
//     body: "#000000",
//     titleActive: PRIMARY,
//     dialogBackground: "#626262"
//   },
//   typography: {
//     fontFamily: [
//       "Poppins",
//       "Roboto",
//       "serif",
//     ].join(","),
//     h1: {
//       fontSize: "2.8125rem",
//       fontWeight: 700,
//     },
//     h2: {
//       fontSize: "1.875rem",
//       fontWeight: 500,
//     },
//     h3: {
//       fontSize: "1.5625rem",
//       fontWeight: 500,
//     },
//     body1: {
//       fontSize: "1.25rem",
//       fontWeight: 500,
//     },
//     body2: {
//       fontSize: "1rem",
//       fontWeight: 500,
//     },
//     caption: {
//       fontSize: "0.625rem",
//       fontWeight: 300,
//     },
//   },
// });

export default theme
