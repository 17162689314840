// Made by Poukam Ngamaleu

import {
  Box,
  Divider,
  Fab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  styled,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material'
import { useFormik } from 'formik'
import { theme } from '../../../../utils/style/theme'
import { modalPaymentShema } from './modalPaymentSchema'
import { useEffect, useState } from 'react'
import { useAuth } from '../../../../utils/context'
import Axios from 'axios'
import { alertMsgInterface } from '../../../contact/SellerInformations'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

function ModalPayment({
  open,
  setOpen,
  createMsg,
  openS,
}: {
  open: boolean
  setOpen: (bool: boolean) => void
  openS: (bool: boolean) => void
  createMsg: ({ message, severity }: alertMsgInterface) => void
}) {
  const {
    userInfo: { id },
    accessToken,
  } = useAuth()

  const [amount, setAmount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [numError, setNumError] = useState<boolean>()

  const authAxios = Axios.create({
    baseURL: `${process.env.REACT_APP_URL_REMOTE_LINK}/partner`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  useEffect(() => {
    // TODO change local link to remote link

    Axios.get(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/partner/getAmount/${id}`
    )
      .then((res) => {
        if (res.status === 200 && res.data) {
          setAmount(res.data.montant)
        }
      })
      .catch((err) => {
        if (err?.response.status === 400) {
          return
        }
      })
  }, [open])

  const { values, handleSubmit, handleChange, errors, setFieldValue } =
    useFormik({
      initialValues: {
        balance: amount,
        limitWithdraw: amount - (amount * 2.5) / 100,
        withdrawalPrice: '',
        numTransfert: '',
      },
      onSubmit: (values) => {
        //TODO change local link to remote link
        setLoading(true)
        authAxios
          .post(`/withdrawal`, values)
          .then((res) => {
            if (res.status === 201) {
              createMsg({
                message: res.data.message,
                severity: 'success',
              })
              openS(true)
              setOpen(false)
              setLoading(false)
            }
          })
          .catch((err) => {
            if (err.response.status === 400 || err.response.status === 500) {
              createMsg({
                message: err.response.data.message,
                severity: 'error',
              })
              openS(true)
              setLoading(false)
              setNumError(true)
            }
          })
      },
      validationSchema: modalPaymentShema,
      enableReinitialize: true,
    })

  return (
    <StyledModal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          backgroundColor: 'transparent',
          width: { xs: '80%', sm: '80%', md: '70%' },
          display: { xs: 'grid', sm: 'grid', md: 'flex' },
          justifyContent: 'center',
          gridAutoFlow: 'row',
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '90%' },
            bgcolor: '#EAEAEA',
            borderRadius: 5,
            marginRight: { sm: '0px', md: '15px' },
            marginTop: { xs: '10px', sm: '10px', md: '0px' },
            padding: { xs: 1, sm: 4 },
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
          >
            Informations de retrait
          </Typography>
          <Divider />
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            onSubmit={handleSubmit}
          >
            <List>
              <ListItem
                sx={{
                  '.MuiListItem-root': {
                    paddingLeft: 0,
                    paddingRight: 0,
                  },
                }}
              >
                <ListItemIcon sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Typography paddingRight="2rem">Solde du compte :</Typography>
                </ListItemIcon>
                <ListItemText>
                  <TextField
                    name="balance"
                    disabled
                    label="Solde du compte"
                    value={`${values.balance} frs`}
                    sx={{
                      width: '100%',
                    }}
                  />
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Typography paddingRight="2rem">
                    Plafond du retrait :
                  </Typography>
                </ListItemIcon>
                <ListItemText>
                  <TextField
                    name="limitWithdraw"
                    disabled
                    label="Plafond du retrait"
                    value={`${values.limitWithdraw} frs`}
                    sx={{
                      width: '100%',
                    }}
                  />
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Typography paddingRight="2rem">
                    Montant du retrait :
                  </Typography>
                </ListItemIcon>
                <ListItemText>
                  <TextField
                    name="withdrawalPrice"
                    label="Montant à retirer"
                    type="number"
                    value={values.withdrawalPrice}
                    onChange={handleChange}
                    sx={{
                      width: '100%',
                    }}
                    {...(errors.withdrawalPrice
                      ? { error: true, helperText: errors.withdrawalPrice }
                      : '')}
                  />
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Typography paddingRight="2rem">Numéro d'envoi :</Typography>
                </ListItemIcon>
                <ListItemText>
                  <PhoneInput
                    value={values.numTransfert}
                    onChange={(phone) => setFieldValue('numTransfert', phone)}
                    country={'cm'}
                    onlyCountries={['cm']}
                    inputProps={{
                      required: true,
                    }}
                    inputStyle={{
                      width: 'inherit',
                      background: 'transparent',
                    }}
                    dropdownStyle={{
                      display: 'none',
                    }}
                    disableDropdown
                    isValid={numError}
                  />
                </ListItemText>
              </ListItem>
            </List>
            <Box sx={{ position: 'relative' }}>
              <Fab
                sx={{
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: '15px',
                }}
                aria-label="add"
                variant="extended"
                type="submit"
                disabled={loading ? true : false}
              >
                <Typography variant="button" paddingLeft="5px">
                  RETRAIT
                </Typography>
              </Fab>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </StyledModal>
  )
}

export default ModalPayment
