// Made by Poukam Ngamaleu

import {
  Box,
  Button,
  Divider,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import Axios from 'axios'
import { useFormik } from 'formik'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { fileImage } from '.'
import { useAuth } from '../../../utils/context'
import { theme } from '../../../utils/style/theme'
import { alertMsgInterface } from '../../contact/SellerInformations'

const StylePhoneNumber = styled(PhoneInput)({
  '& .PhoneInputCountrySelectArrow': {
    height: '0.5em',
  },

  '& .PhoneInputCountry': {
    display: 'none',
  },
  '& .PhoneInputCountryIcon ': {
    height: '2rem',
    width: 'calc(2rem * 1.5 )',
  },
})

function PersonalChanges({
  files: { name, secondName, email, phoneNumber, file },
  setAlertMsg,
  setOpen,
}: {
  files: fileImage
  setAlertMsg: ({ message, severity }: alertMsgInterface) => void
  setOpen: (bool: boolean) => void
}) {
  const {
    userInfo: { id },
    accessToken,
  } = useAuth()
  const authAxios = Axios.create({
    baseURL: `${process.env.REACT_APP_URL_REMOTE_LINK}/student`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      file: file,
      nom: name,
      prenom: secondName,
      mail: email,
      phone: phoneNumber,
    },
    onSubmit: ({ file, nom, prenom, mail, phone }) => {
      const body = new FormData()
      body.append('file', file)
      body.append('nom', nom)
      body.append('prenom', prenom)
      body.append('email', mail)
      body.append('phoneNumber', phone)
      // TODO change local link to remote link
      authAxios
        .put(`/updateInfos/${id}`, body)
        .then((res) => {
          if (res?.status === 200) {
            setAlertMsg({
              message: res.data.message,
              severity: 'success',
            })
            setOpen(true)
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            setAlertMsg({
              message: err.response.data.message,
              severity: 'error',
            })
            setOpen(true)
          } else {
            setAlertMsg({
              message: err.response.data.message,
              severity: 'warning',
            })
            setOpen(true)
          }
        })
    },
    enableReinitialize: true,
  })

  return (
    <Paper sx={{ backgroundColor: 'transparent' }} elevation={3}>
      <Box p={2}>
        <Typography variant="h5">PROFILE</Typography>
        <Typography variant="subtitle2">
          Certaines informations peuvent être modifiées
        </Typography>
      </Box>
      <Divider />
      <Box component="form" onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: 'auto', sm: '1fr 1fr' },
            gap: { xs: 2, sm: 4 },
            padding: { xs: 1, sm: 4 },
          }}
        >
          <TextField
            name="nom"
            type="text"
            label="Nom"
            onChange={handleChange}
            value={values.nom}
            required
          />
          <TextField
            name="prenom"
            type="text"
            label="Prénom"
            onChange={handleChange}
            value={values.prenom}
            required
          />
          <TextField
            name="mail"
            type="email"
            label="Email"
            onChange={handleChange}
            value={values.mail}
            required
          />
          <StylePhoneNumber
            value={values.phone}
            onChange={(number) => setFieldValue(`phone`, number?.toString())}
            placeholder="Numéro de téléphone"
            sx={{
              '& .PhoneInputInput': {
                height: { xs: '50px', sm: '100%' },
                backgroundColor: 'transparent',
                borderColor: '#000',
                borderTop: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderBottom: 'solid #999999',
                borderWidth: '1px',
                overflow: 'hidden',
                fontSize: '1rem',
                width: '100%',
              },
            }}
          />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="end" p={1}>
          <Button
            variant="outlined"
            sx={{ borderColor: theme.palette.secondary.main, color: 'black' }}
            type="submit"
          >
            Enregistrer
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

export default PersonalChanges
