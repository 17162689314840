// Made by Poukam Ngamaleu

import { Box, Divider, Fab, Modal, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useAuth } from '../../utils/context'
import { theme } from '../../utils/style/theme'
import TestInformations from './informationsTest'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
}

const style_modal_test = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textDecoration: 'none',
  color: theme.palette.primary.contrastText,
  paddingTop: 2,
}

const style_fab = {
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '10px',
  width: 'fit-content',
  justifySelf: 'center',
}

function ModalTest({
  open,
  setOpen,
  session,
  departmentName,
  category,
  departmentCountry,
  epreuve_ref,
  eligibleSous,
  eligibleTest,
}: {
  open: boolean
  session: string
  departmentName: string
  category: string
  departmentCountry: string
  epreuve_ref: string
  eligibleSous: boolean | undefined
  eligibleTest: boolean | undefined
  setOpen: (bool: boolean) => void
}) {
  const {
    userInfo: { is_student },
  } = useAuth()
  return (
    <div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            ...style,
            bgcolor: '#EAEAEA',
            borderRadius: 5,
            width: { xs: '100%', sm: 400 },
          }}
        >
          <TestInformations
            departmentName={departmentName}
            category={category}
            session={session}
            countryName={departmentCountry}
            setOpen={setOpen}
          />
          <Divider />
          {is_student ? (
            eligibleSous || eligibleTest ? (
              <Box sx={style_modal_test} component={Link} to={epreuve_ref}>
                <Fab sx={style_fab} variant="extended">
                  <Typography variant="button" fontWeight="600">
                    Composer
                  </Typography>
                </Fab>
              </Box>
            ) : (
              <Box sx={style_modal_test} component={Link} to="/souscription">
                <Fab sx={style_fab} variant="extended">
                  <Typography variant="button" fontWeight="600">
                    S'abonner
                  </Typography>
                </Fab>
              </Box>
            )
          ) : (
            <Box sx={style_modal_test} component={Link} to="/SignUp">
              <Fab sx={style_fab} variant="extended">
                <Typography variant="button" fontWeight="600">
                  S'inscrire
                </Typography>
              </Fab>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  )
}

export default ModalTest
