// made by Poukam Ngamaleu

import {
  Alert,
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Fab,
  Slide,
  SlideProps,
  Snackbar,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import Axios from 'axios'
import { useFormik } from 'formik'
import { useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { SignUpSchema } from '../../../pages/signUp/schemaYup'
import { theme } from '../../../utils/style/theme'
import { alertMsgInterface } from '../../contact/SellerInformations'

type ExamDate = Record<string, Date>
export const categoryArray: string[] = ['A', 'B', 'C', 'D', 'E', 'G']
const years: string[] = [
  'Janvier',
  'Fevrier',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Decembre']
export const recordExamDate: ExamDate = {
  'Janvier 2024': new Date('2024-01-27'),
  'Mars 2024': new Date('2024-03-09'),
  'Avril 2024': new Date('2024-04-27'),
  'Juin 2024': new Date('2024-06-08'),
  'Juillet 2024': new Date('2024-07-27'),
  'Septembre 2024': new Date('2024-09-07'),
  'Octobre 2024': new Date('2024-10-26'),
  'Decembre 2024': new Date('2024-12-07')
}
const examDate: Date[] = [
  new Date('2024-01-27'),
  new Date('2024-03-09'),
  new Date('2024-04-27'),
  new Date('2024-06-08'),
  new Date('2024-07-27'),
  new Date('2024-09-07'),
  new Date('2024-10-26'),
  new Date('2024-12-07'),
  new Date('2025-01-25'),
  new Date('2025-03-15'),
]

type TransitionProps = Omit<SlideProps, 'direction'>

export const StylePhoneNumber = styled(PhoneInput)({
  height: '60px',
  '& .PhoneInputCountrySelectArrow': {
    height: '0.5em',
  },
  '& .PhoneInputInput': {
    height: '85%',
    backgroundColor: 'transparent',
    borderColor: '#000',
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: '1px',
    overflow: 'hidden',
    opacity: '0.5',
    fontSize: '1rem',
  },
  '& .PhoneInputCountryIcon ': {
    height: '2rem',
    width: 'calc(2rem * 1.5 )',
  },
})

export const generateValideSession = () => {
  const expiredDate = examDate.find((date) => new Date() < date)
  const indexExpiredDate = examDate.findIndex((date) => expiredDate === date)
  if (expiredDate) {
    const actualYear = expiredDate.getFullYear()
    return [`${years[expiredDate.getMonth()]} ${actualYear}`,
    `${years[examDate[indexExpiredDate + 1].getMonth()]} ${actualYear + 1}`
    ]

  }
}

function StudentInf() {
  const { handleChange, values, errors, setFieldValue, touched, handleSubmit } =
    useFormik({
      initialValues: {
        nom: '',
        prenom: '',
        email: '',
        categoryPermis: 'B',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        code_preps: '',
        session: null,
      },
      onSubmit: (values) => {
        setLoading(true)
        // TODO change local url to final url
        Axios.post(
          `${process.env.REACT_APP_URL_REMOTE_LINK}/signup/student`,
          values
        )
          .then((res) => {
            if (res.status === 200) {
              window.location.href = res.data.payment_url
            }
          })
          .catch(
            (err: {
              response: { status: number; data: { message: string } }
            }) => {
              if (err.response.status === 409) {
                setCreatedMsg({
                  message: err.response.data.message,
                  severity: 'error',
                })
                setOpen(true)
                setLoading(false)
              } else if (err.response.status === 404) {
                setCreatedMsg({
                  message: err.response.data.message,
                  severity: 'warning',
                })
                setOpen(true)
                setLoading(false)
              } else {
                setCreatedMsg({
                  message: 'Erreur serveur. Veuillez réessayer plutard!',
                  severity: 'error',
                })
                setOpen(true)
                setLoading(false)
              }
            }
          )
      },
      validationSchema: SignUpSchema,
    })

  const [open, setOpen] = useState<boolean>(false)
  const [createdMsg, setCreatedMsg] = useState<alertMsgInterface>()
  const [loading, setLoading] = useState<boolean>(false)

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />
  }
  return (
    <Box
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit}
      width="100%"
    >
      <Box sx={{ padding: { xs: '0', sm: '16px 16px 0 16px' } }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
        >
          INFORMATIONS PERSONNELLES
        </Typography>
        <Divider sx={{ alignSelf: 'normal', marginBottom: '10px' }} />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: 'initial', sm: '1fr 1fr' },
            gridGap: '10px',
          }}
        >
          <TextField
            id="nom"
            label="nom"
            type="text"
            name="nom"
            onChange={handleChange}
            value={values.nom}
            variant="outlined"
            sx={{ width: '100%' }}
            {...(errors.nom && touched.nom
              ? { error: true, helperText: errors.nom }
              : '')}
          />
          <TextField
            id="prenom"
            label="prenom"
            type="text"
            name="prenom"
            value={values.prenom}
            onChange={handleChange}
            variant="outlined"
            sx={{ width: '100%' }}
            {...(errors.prenom && touched.prenom
              ? { error: true, helperText: errors.prenom }
              : '')}
          />
          <TextField
            id="email"
            label="email"
            type="text"
            name="email"
            value={values.email}
            onChange={handleChange}
            variant="outlined"
            sx={{ width: '100%' }}
            {...(errors.email && touched.email
              ? { error: true, helperText: errors.email }
              : '')}
          />
          <TextField
            id="password"
            label="Mot de passe"
            type="password"
            name="password"
            onChange={handleChange}
            value={values.password}
            autoComplete="current-password"
            {...(errors.password && touched.password
              ? { error: true, helperText: errors.password }
              : '')}
          />
          <TextField
            id="confirmPassword"
            label="Confirmer mot de passe"
            type="password"
            name="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChange}
            {...(errors.confirmPassword && touched.confirmPassword
              ? { error: true, helperText: errors.confirmPassword }
              : '')}
          />
        </Box>
        <Box width="100%" sx={{ padding: '16px 0' }}>
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
          >
            PAYEMENT
          </Typography>
          <Divider sx={{ alignSelf: 'normal', marginBottom: '10px' }} />
          <Box
            lineHeight="4"
            sx={{
              display: 'grid',
              gridTemplateRows: { xs: 'initial', sm: '1fr 1fr' },
              gridGap: '5px',
            }}
          >
            <Box sx={{
              display: 'grid',
              gridTemplateColumns: { xs: 'initial', sm: '2fr 1fr' },
              gridGap: '5px',
            }}>
              <Autocomplete
                disablePortal
                options={generateValideSession() as string[]}
                id="session"
                onChange={(_, val) => {
                  setFieldValue('session', recordExamDate[val as string])
                }}
                isOptionEqualToValue={(option, value) => option === value}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Session d'examen"
                    {...(errors.session && touched.session
                      ? { error: true, helperText: errors.session }
                      : '')}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                options={categoryArray}
                id="categoryPermis"
                onChange={(_, val) => setFieldValue('categoryPermis', val)}
                value={values.categoryPermis}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Catégorie"
                    name="categoryPermis"
                    {...(errors.categoryPermis && touched.categoryPermis
                      ? { error: true, helperText: errors.categoryPermis }
                      : '')}
                  />
                )}
              />
            </Box>

            <TextField
              id="code_preps"
              label="Code Preps"
              type="text"
              name="code_preps"
              onChange={handleChange}
              value={values.code_preps}
              variant="outlined"
              sx={{ width: '100%' }}
              {...(errors.code_preps && touched.code_preps
                ? { error: true, helperText: errors.code_preps }
                : '')}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          sx={{ position: 'relative' }}
        >
          <Fab
            sx={{ backgroundColor: theme.palette.secondary.main }}
            variant="extended"
            aria-label="add"
            type="submit"
            disabled={loading ? true : false}
          >
            <Typography variant="button" paddingLeft="5px">
              INSCRIPTION
            </Typography>
          </Fab>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </Box>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={TransitionUp}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={createdMsg?.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {createdMsg?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default StudentInf
