// Made by Poukam Ngamaleu

import {
  Alert,
  Button,
  Divider,
  Slide,
  SlideProps,
  Snackbar,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import Axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useAuth } from '../../../utils/context'
import { theme } from '../../../utils/style/theme'
import { alertMsgInterface } from '../../contact/SellerInformations'
import AccountInf from './accountInf'
import PersonalChanges from './personalChanges'
import ProfilInf from './profilInf'
import CircularProgress from '@mui/material/CircularProgress'

export interface fileImage {
  file: string
  previewImage: string
  name: string
  secondName: string
  email: string
  phoneNumber: string
}

type TransitionProps = Omit<SlideProps, 'direction'>

function Compte() {
  const {
    userInfo: { id, is_partner, nom, prenom, email, phoneNumber },
    authDispatch,
    accessToken,
    userInfo,
  } = useAuth()
  const navigate = useNavigate()
  const [createdMsg, setCreatedMsg] = useState<alertMsgInterface>()
  const [loading, setLoading] = useState<Boolean>(false)
  const [open, setOpen] = useState(false)
  const [file, setFile] = useState<fileImage>({
    file: '',
    previewImage:
      'https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg',
    name: nom,
    secondName: prenom,
    email: email,
    phoneNumber: phoneNumber ? phoneNumber : '',
  })
  useEffect(() => {
    // TODO change local link to remote link
    Axios.get(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/common/getProfilImg/${id}`
    )
      .then((res) => {
        if (res?.status === 200 && res.data) {
          authDispatch({
            accessToken: accessToken,
            userInfo: {
              ...userInfo,
              profil_img: res.data.profil_img,
              nom: res.data.nom,
              email: res.data.email,
              prenom: res.data.prenom,
            },
          })
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setCreatedMsg({
            message: err.response.data.message,
            severity: 'error',
          })
          setOpen(true)
        }
      })
  }, [open])

  const becomePartner = () => {
    setLoading(true)
    //TODO change local link to remote link
    Axios.post(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/student/becomePartner/${id}`
    )
      .then((res) => {
        if (res?.status === 201) {
          setCreatedMsg({
            message: res.data.message,
            severity: 'success',
          })
          setOpen(true)
          authDispatch({
            accessToken: accessToken,
            userInfo: { ...userInfo, is_partner: true },
          })
          setLoading(false)
          setTimeout(() => {
            navigate('/partner')
          }, 5000)
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setCreatedMsg({
            message: err.response.data.message,
            severity: 'error',
          })
          setOpen(true)
          setLoading(false)
        }
      })
  }

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />
  }

  return (
    <Box sx={{ padding: { xs: 1, sm: 3 } }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        sx={{ flexDirection: { xs: 'column', sm: 'inherit' } }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}
        >
          COMPTE
        </Typography>
        {!is_partner ? (
          <Box sx={{ position: 'relative' }}>
            <Button
              variant="outlined"
              sx={{
                borderColor: theme.palette.secondary.main,
                color: loading ? '#5555' : theme.palette.primary.contrastText,
              }}
              onClick={becomePartner}
            >
              devenir partenaire
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        ) : null}
      </Box>
      <Divider />
      <Box
        sx={{ display: { xs: 'initial', md: 'flex' } }}
        justifyContent="space-evenly"
        p={3}
      >
        <ProfilInf files={file} setFile={setFile} />
        <PersonalChanges
          files={file}
          setAlertMsg={setCreatedMsg}
          setOpen={setOpen}
        />
      </Box>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={TransitionUp}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={createdMsg?.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {createdMsg?.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default Compte
