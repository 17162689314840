// Made by Poukam Ngamaleu

import { Box, Fab, Typography } from '@mui/material'
import car_image from '../../asset/car_image.svg'
import { Link } from 'react-router-dom'
import theme from '../../utils/style/theme'
import { useAuth } from '../../utils/context'

function WelcomMsg() {
  const {
    userInfo: { id },
  } = useAuth()

  return (
    <>
      <Box
        sx={{
          display: { xs: 'block', md: 'grid' },
          backgroundColor: theme.common.titleActive,
          borderBottom: '1px solid #dad9dc',
          padding: { xs: 2, sm: '40px 80px' },
        }}
        gridTemplateColumns="1.5fr 2fr"
      >
        <Box
          display="grid"
          sx={{
            padding: { xs: '10px', sm: 'initial' },
            gap: { xs: '18px', sm: '20px' },
          }}
        >
          <Box>
            <Typography
              variant="h2"
              fontFamily="Inter"
              fontWeight="400"
              sx={{ fontSize: { xs: '25px', sm: '45px' } }}
            >
              Préparez vos examens en toute sérénité.
            </Typography>
          </Box>
          <Typography
            variant="body1"
            sx={{ fontSize: { xs: '18px', sm: '20px' } }}
          >
            Ayez accès à toutes nos épreuves afin de mieux affronter vos
            examens. La préparation et l'auto-évaluation sur des capacités sont
            nécessaires. Le permis de conduire ne s'écarte pas de cette règle.
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            component={Link}
            sx={{
              textDecoration: 'none',
              color: theme.palette.primary.contrastText,
              width: 'fit-content',
            }}
            to={id ? '/epreuves' : '/signIn'}
          >
            <Fab
              sx={{
                backgroundColor: theme.palette.secondary.main,
                borderRadius: '10px',
              }}
              variant="extended"
            >
              <Typography variant="button" paddingLeft="5px" fontWeight="600">
                DEMARRER MA FORMATION
              </Typography>
            </Fab>
          </Box>
        </Box>
        <Box
          component="img"
          src={car_image}
          alt="car_image"
          sx={{
            justifySelf: 'center',
            width: { xs: '100%', sm: '60%' },
            alignSelf: 'center',
            paddingTop: { xs: 3, sm: 0 },
          }}
        />
      </Box>
    </>
  )
}

export default WelcomMsg
