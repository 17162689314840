// Made by Poukam Ngamaleu

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { theme } from '../../../../utils/style/theme'
import Axios from 'axios'
import { alertMsgInterface } from '../../../contact/SellerInformations'
import { useNavigate } from 'react-router'
import { useAuth } from '../../../../utils/context'
import { useState } from 'react'

function DeletAccount({
  id_,
  createMsg,
  setOpen,
}: {
  id_: number
  createMsg: ({ message, severity }: alertMsgInterface) => void
  setOpen: (bool: boolean) => void
}) {
  const { authDispatch, accessToken } = useAuth()
  const navigate = useNavigate()
  const authAxios = Axios.create({
    baseURL: `${process.env.REACT_APP_URL_REMOTE_LINK}/common`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  function deleteAccount() {
    // TODO Change status of account du to fetch API
    authAxios
      .delete(`/deledeAccount/${id_}`)
      .then((res) => {
        if (res?.status === 200) {
          createMsg({
            message: res.data.message,
            severity: 'success',
          })
          setOpen(true)
          handleClose()
          setTimeout(() => {
            authDispatch({
              accessToken: '',
              userInfo: {
                id: undefined,
                nom: '',
                prenom: '',
                email: '',
              },
            })
            navigate('/home')
          }, 3000)
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          createMsg({
            message: err.response.data.message,
            severity: 'error',
          })
          setOpen(true)
        } else {
          createMsg({
            message: err.response.data.message,
            severity: 'error',
          })
          setOpen(true)
        }
      })
  }

  const [openD, setOpenD] = useState(false)
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClickOpen = () => {
    setOpenD(true)
  }

  const handleClose = () => {
    setOpenD(false)
  }
  return (
    <Box sx={{ padding: { xs: 0, sm: 2 } }}>
      <Paper
        sx={{
          padding: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          backgroundColor: 'transparent',
          flexDirection: { xs: 'column', sm: 'initial' },
          gap: { xs: 3, sm: 'initial' },
        }}
        elevation={3}
      >
        <Typography
          variant="h5"
          fontWeight="bold"
          color="red"
          sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }}
        >
          SUPPRIMER COMPTE
        </Typography>
        <Button
          variant="outlined"
          sx={{ borderColor: theme.palette.secondary.main }}
          onClick={handleClickOpen}
        >
          <Typography variant="button" color="red">
            SUPPRIMER
          </Typography>
        </Button>
      </Paper>
      <Dialog
        fullScreen={fullScreen}
        open={openD}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'SUPPRESSION DEFINITIVE DE COMPTE?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes vous certain de vouloir supprimer définitivement votre compte?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={deleteAccount}>
            Je suis sûr
          </Button>
          <Button onClick={handleClose} autoFocus>
            Non
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default DeletAccount
