// Made by Poukam Ngamaleu

import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { useAuth } from '../../../utils/context'
import { useState, useEffect } from 'react'
import Axios from 'axios'
import { alertMsgInterface } from '../../contact/SellerInformations'

interface souscriptionDataInterface {
  libele_typeAbon: string
  date_debut: string
  date_fin: string
  libele_cat: string
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 1,
  },
}))
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#512DAB',
    color: theme.palette.common.white,
    fontSize: 18,
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}))

function AccountInf({
  setAlertMsg,
  setOpen,
}: {
  setOpen: (bool: boolean) => void
  setAlertMsg: ({ message, severity }: alertMsgInterface) => void
}) {
  const {
    userInfo: { id },
  } = useAuth()
  const [souscriptionData, setSouscriptionData] =
    useState<souscriptionDataInterface[]>()
  useEffect(() => {
    // TODO change local link to remote link
    Axios.get(
      `${process.env.REACT_APP_URL_REMOTE_LINK}/student/getAbonnement/${id}`
    )
      .then((res) => {
        if (res?.status === 200 && res.data) {
          setSouscriptionData(res.data)
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setAlertMsg({
            message: err.response.data.message,
            severity: 'error',
          })
          setOpen(true)
        }
      })
  }, [])

  const remainTime = (date: string) => {
    const currentDate: Date = new Date()
    const [day, month, year] = date.split('/')
    const souscriptionDateEnd = new Date(+year, +month - 1, +day)
    const remainTime =
      (souscriptionDateEnd.getTime() - currentDate.getTime()) /
      (1000 * 3600 * 24)
    if (remainTime < 0) {
      return 0
    } else {
      return Math.trunc(remainTime)
    }
  }
  return (
    <Box sx={{ padding: { xs: 0, lg: 5 } }}>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'transparent',
        }}
        elevation={3}
      >
        <TableContainer sx={{ padding: 1 }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">
                  Type d'abonnement
                </StyledTableCell>
                <StyledTableCell align="center">Cat. permis</StyledTableCell>
                <StyledTableCell align="center">
                  Date d'abonnement
                </StyledTableCell>
                <StyledTableCell align="center">Validité</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {souscriptionData?.map(
                (
                  { date_debut, date_fin, libele_cat, libele_typeAbon },
                  index
                ) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="center" component="th" scope="row">
                      {libele_typeAbon}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {libele_typeAbon === 'gold' ? 'toutes' : libele_cat}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {date_debut}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {remainTime(date_fin)} jrs
                    </StyledTableCell>
                  </StyledTableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  )
}

export default AccountInf
