// Made by Poukam Ngamaleu

import { IconButton, Stack, Typography } from '@mui/material'
import { theme } from '../../../utils/style/theme'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import { Box } from '@mui/system'

function ClientFooter() {
  return (
    <Stack
      sx={{
        bgcolor: theme.palette.primary.contrastText,
        color: '#D2E9F1',
        alignItems: 'center',
        justifyContent: { xs: 'space-between', sm: 'initial' },
        padding: '0 4px',
      }}
      spacing={2}
      direction="row"
    >
      <Typography>{`OnLinePreps © ${new Date().getFullYear()}`}</Typography>
      <Box>
        <IconButton
          sx={{ color: theme.palette.secondary.dark, padding: 1 / 2 }}
        >
          <WhatsAppIcon cursor="pointer" />
        </IconButton>
        <IconButton
          sx={{ color: theme.palette.secondary.dark, padding: 1 / 2 }}
        >
          <FacebookRoundedIcon cursor="pointer" />
        </IconButton>
      </Box>
    </Stack>
  )
}

export default ClientFooter
