// Made by Poukam Ngamaleu

import * as yup from 'yup'

export const SignUpSchema = yup.object().shape({
  nom: yup.string().required('Nom obligatoire'),
  email: yup
    .string()
    .email('Entrer une email valide')
    .required('Email obligatoire'),
  categoryPermis: yup
    .string()
    .required('Choix de permis obligatoire')
    .nullable(),
  password: yup
    .string()
    .min(5, 'minimum 5 caractères')
    .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/, { message: ' 1 magiscule, 1 minuscule et 1 chiffre' })
    .required('Mot de passe obligatoire'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Mot de passe non identique')
    .required('Resaisissez votre mot de passe'),
  code_preps: yup
    .string()
    .min(7, '7 caractères au moins')
    .max(7, '7 caractères au plus'),
  session: yup.date().required('Choix obligatoire').nullable(),
})
