// Made by Poukam Ngamaleu

import {
  Alert,
  AppBar,
  Slide,
  SlideProps,
  Snackbar,
  Stack,
} from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { io } from 'socket.io-client'
import logo from '../../../asset/logo.PNG'
import extraSmallLogo from '../../../asset/logoOnlinePreps.png'
import { useAuth } from '../../../utils/context'
import { theme } from '../../../utils/style/theme'
import { alertMsgInterface } from '../../contact/SellerInformations'
import Drawers from '../../drawers'
import MenuSettings from '../../menuSetting'
import NavItem from '../NavItem'
import Notification from '../notification'
import { StyledToolbar } from '../prospectHeader'

export interface notificationInterface {
  category: string
  session: string
  department: string
}

type TransitionProps = Omit<SlideProps, 'direction'>

const NAV_ITEMS: { label: string; to?: string; end?: boolean }[] = [
  { label: 'ACCEUIL', end: true },
  { label: 'EPREUVES', to: '/epreuves' },
  { label: 'REPONSES', to: '/history' },
  { label: 'CONTACT', to: '/contact' },
]
const socket = io(process.env.REACT_APP_URL_SOCKET_LINK as string)

function ClientHeader() {
  const {
    userInfo: { is_partner, is_student, nom, id, status_connected },
    authDispatch,
    accessToken,
    userInfo,
  } = useAuth()

  const [notifications, setNotifications] = useState<notificationInterface[]>(
    []
  )
  const [openNotif, setOpenNotif] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [createdMsg, setCreatedMsg] = useState<alertMsgInterface>()

  useEffect(() => {
    // TODO make greeting connection
    if ((is_partner || is_student) && !status_connected) {
      // private message greeting
      setCreatedMsg({
        message: `Content de vous revoir ${
          nom.charAt(0).toUpperCase() + nom.slice(1)
        } !`,
        severity: 'success',
      })
      setOpen(true)
      authDispatch({
        accessToken: accessToken,
        userInfo: { ...userInfo, status_connected: 'connected' },
      })
      // Adding infomation to server io
      socket.emit('newUser', id)
    }
  }, [])

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />
  }

  // socket communication front-end

  socket.on('disposalExam', (data: notificationInterface) => {
    setNotifications((prev) => [...prev, data])
  })

  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: theme.common.Bgheader, padding: { xs: 0, sm: 1 } }}
    >
      <StyledToolbar>
        <Stack
          spacing={2}
          direction="row"
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          <Box component={Link} to="/">
            <Box
              component="img"
              sx={{ maxWidth: 80 }}
              src={logo}
              alt="OnlinePreps"
            />
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'grid' },
              gridAutoFlow: 'column',
              columnGap: '20px',
              alignSelf: 'center',
            }}
          >
            {NAV_ITEMS.map(({ label, to, end }, index) => (
              <NavItem key={index} to={to} label={label} end={end} />
            ))}
          </Box>
        </Stack>
        <Box
          component="img"
          sx={{
            maxWidth: { xs: 180, sm: 200 },
            display: { xs: 'block', md: 'none' },
          }}
          src={extraSmallLogo}
          alt="OnlinePreps"
        />
        <Box display="flex" sx={{ position: 'relative' }}>
          <Drawers />
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <MenuSettings setOpenNotif={setOpenNotif} />
          </Box>
          {openNotif && <Notification notifications={notifications} />}
        </Box>
      </StyledToolbar>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={TransitionUp}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={createdMsg?.severity}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {createdMsg?.message}
        </Alert>
      </Snackbar>
    </AppBar>
  )
}

export default ClientHeader
