//  Made by Poukam Ngamaleu
import Footer from '../../components/footer'
import { Outlet } from 'react-router'
import { Box } from '@mui/material'
import PartnerHeader from '../../components/Header/partnerHeader'
import { useAuth } from '../../utils/context'

function PartnerSkeleton() {
  const {
    userInfo: { is_partner },
  } = useAuth()
  return (
    is_partner && (
      <Box
        sx={{
          height: '100vh',
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
        }}
      >
        <PartnerHeader />
        <Box
          sx={{
            maxHeight: '100%',
            overflowY: 'scroll',
          }}
        >
          <Outlet></Outlet>
        </Box>
        <Footer />
      </Box>
    )
  )
}

export default PartnerSkeleton
